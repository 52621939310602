<template>
  <Wrapper>
    <v-row justify="center">
      <v-col align="center">
        <v-row style="margin-bottom: 25px; max-width: 850px;">
          <v-col>
            <div class="ibarra txt-med justify">
               We're looking forward to celebrating with you on our big day. Having you attend our wedding is the greatest gift of all. However, if you're looking for gift ideas we've put together a registry of things we need to complete our home and for our new life together.
            </div>
          </v-col>
        </v-row>
        <v-row style="margin-bottom: 25px;  max-width: 850px;">
          <v-col>
            <div class="ibarra txt-med justify">
                We've lived together for a while now, so our wedding registry includes a honeymoon fund where you can choose personalized experiences — such as excursions, meals, and spa days — that can be given as wedding gifts. We are excited to share our special day with you, and we also look forward to providing glimpses into our honeymoon when we return. Thank you so much!
            </div>
          </v-col>
        </v-row>
        <v-row style="margin-bottom: 25px;  max-width: 850px;">
          <v-col>
            <div class="ibarra txt-med justify">
              Because Hannah will be unable to take enough time off until the summer of 2024 (the teacher grind is real), we will be taking a mini-moon (weekend getaway) after the wedding to Mackinac Island, Michigan, and then taking a longer trip next summer when we can both take more time off! The cash funds contain specific events for us to do on Mackinac Island, as well as a fund for our future honeymoon next summer!
            </div>
          </v-col>
        </v-row>
        <v-row style="max-width: 708px;">
          <v-col>
            <v-btn height="330" width="330" @click="goToURL('https://www.target.com/gift-registry/gift/hannahandmitchell')">
                <v-img src="@/assets/target.png">
                  <template v-slot:placeholder>
                      <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                      >
                        <v-progress-circular
                          indeterminate
                          color="grey lighten-5"
                        ></v-progress-circular>
                      </v-row>
                    </template>
                </v-img>
            </v-btn>
          </v-col>
          <v-col>
            <v-btn height="330" width="330" @click="goToURL('https://www.crateandbarrel.com/gift-registry/hannah-flanders-and-mitchell-miles/r6637243')">
                <v-img max-width="330" src="@/assets/crateandbarrel.svg">
                  <template v-slot:placeholder>
                      <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                      >
                        <v-progress-circular
                          indeterminate
                          color="grey lighten-5"
                        ></v-progress-circular>
                      </v-row>
                    </template>
                </v-img>
            </v-btn>
          </v-col>
        </v-row>
        <v-row style="max-width: 708px; margin-top:10px;">
          <v-col>
            <v-btn height="200" style="width: 100%" @click="goToURL('https://registry.theknot.com/hannah-flanders-mitchell-miles-october-2023-pa/54089434')">
                <v-img style="max-width: 300px" src="@/assets/theknot.png">
                  <template v-slot:placeholder>
                      <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                      >
                        <v-progress-circular
                          indeterminate
                          color="grey lighten-5"
                        ></v-progress-circular>
                      </v-row>
                    </template>
                </v-img>
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </Wrapper>
</template>

<script>
import Wrapper from "@/components/Wrapper";
export default {
  name: "RegistryView",
  components: {
    Wrapper
  },
  methods: {
    goToURL(url) {
      window.open(url, "_blank")
      //window.location.href = url
    }
  }
}
</script>

<style scoped>
.ibarra {
  font-family: 'Ibarra Real Nova', serif;
  color: dimgray;
}
.home-img {
  width: 90%;
  height: auto;
  object-fit: cover;
  object-position: 43% 57%
}
.location-cards {
  width: 80%;
  margin-bottom: 40px;
}
.txt-large {
  font-size: 40px;
}
.txt-med {
  font-size: 25px;
}
.txt-small {
  font-size: 20px;
}
.txt-x-small {
  font-size: 18px;
}
.bold {
  font-weight: bold;
}
.justify {
  text-align: justify;
  text-justify: inter-word;
}
</style>