<template>
  <v-row style="margin-top: 30px;">
    <v-col align="center">
<!--      <div v-if="width > 1000" style="position: absolute;">-->
<!--        <v-img :style="'margin-left: ' + (width - 1010)/2 + 'px; top: 35px;'" width="1000" :src="require('@/assets/openenvelope.png')"></v-img>-->
<!--      </div>-->
      <form style="margin-top: 50px;" v-if="!submit">
<!--        <v-row>-->
<!--          <v-col>-->
<!--            <div class="ibarra txt-large">-->
<!--              RSVP-->
<!--            </div>-->
<!--          </v-col>-->
<!--        </v-row>-->
        <v-card max-width="800" style="border: 2px solid #9CAF88; margin-bottom: 20px; margin-left: 20px; margin-right: 20px" elevation="10">
          <v-row justify="center">
            <v-col align="center">
              <v-tabs
                  color="#9CAF88"
                  show-arrows
                  v-model="tab"
                  fixed-tabs
              >
                <v-tab v-for="guest in guests" v-bind:key="guest.id">
                  {{ guest.name }}
                </v-tab>
              </v-tabs>
              <v-tabs-items v-model="tab">
                <v-tab-item
                    v-for="guest in guests" v-bind:key="guest.id"
                    style="margin-top: 30px;"
                >
                  <v-row style="max-width: 700px; margin-right: 30px; margin-left: 30px;">
                    <v-col align="left">
                      <v-row v-if="guests[guest.id-1].plus_one">
                        <v-col>
                          <div class="ibarra txt-small bold">
                            Name (please change this to your guest's name) <i style="color: red">{{ name_error[guest.id-1] }}</i>
                          </div>
                        </v-col>
                      </v-row>
                      <v-row v-if="guests[guest.id-1].plus_one">
                        <v-col>
                          <v-text-field
                              v-model="guests[guest.id-1].name"
                              style="max-width: 700px;"
                              class="ibarra"
                          >
                          </v-text-field>
                        </v-col>
                      </v-row>
                      <v-row :style="guests[guest.id-1].plus_one ? 'margin-top: 40px;' : ''">
                        <v-col>
                          <div class="ibarra txt-small bold">
                            Attending Wedding? <i style="color: red">{{ attending_error[guest.id-1] }}</i>
                          </div>
                        </v-col>
                      </v-row>
                      <v-row style="margin-top: -20px;">
                        <v-col>
                          <v-radio-group class="ibarra bold" v-model="form.attending[guest.id-1]">
                            <v-radio color="#9CAF88" label="Yes" value="yes"></v-radio>
                            <v-spacer style="height: 15px;"></v-spacer>
                            <v-radio color="#9CAF88" label="No" value="no"></v-radio>
                          </v-radio-group>
                        </v-col>
                      </v-row>
                      <v-row style="margin-top: 30px;" v-if="form.attending[guest.id-1]==='yes' && guests[guest.id-1].rehearsal_dinner_invite">
                        <v-col>
                          <div class="ibarra txt-small bold">
                            Attending Rehearsal Dinner? <i style="color: red">{{ rehearsal_dinner_error[guest.id-1] }}</i>
                          </div>
                        </v-col>
                      </v-row>
                      <v-row style="margin-top: 5px;" v-if="form.attending[guest.id-1]==='yes' && guests[guest.id-1].rehearsal_dinner_invite">
                        <v-col>
                          <div class="ibarra bold">
                            The rehearsal dinner will be located at the
                            <a
                                href="https://www.google.com/maps/place/623+Ravencrest+Rd,+Pittsburgh,+PA+15215/@40.5151949,-79.9176254,17z/data=!3m1!4b1!4m6!3m5!1s0x8834ed548b23206f:0x4c84ba4456214d73!8m2!3d40.5151909!4d-79.9150505!16s%2Fg%2F11c1j3clxx?entry=ttu"
                                target="_blank">
                              groom's parents' house</a> at 6:30pm on Friday, October 6th, 2023.
                            This event is strongly recommended for anyone that is in the wedding.
                          </div>
                          <div class="ibarra bold" style="margin-top: 10px;">
                            The dress code is casual, but the bride has requested that you wear blue to be her "something blue".
                          </div>
                        </v-col>
                      </v-row>
                      <v-row style="margin-top: -20px;" v-if="form.attending[guest.id-1]==='yes' && guests[guest.id-1].rehearsal_dinner_invite">
                        <v-col>
                          <v-radio-group class="ibarra bold" v-model="form.rehearsal_dinner[guest.id-1]">
                            <v-radio color="#9CAF88" label="Yes (and I will be wearing blue)" value="yes"></v-radio>
                            <v-spacer style="height: 15px;"></v-spacer>
                            <v-radio color="#9CAF88" label="No" value="no"></v-radio>
                          </v-radio-group>
                        </v-col>
                      </v-row>
                      <v-row style="margin-top: 30px;" v-if="form.attending[guest.id-1]==='yes'">
                        <v-col>
                          <div class="ibarra txt-small bold">
                            Attending Post-Wedding Brunch? (See timeline on home page for details) <i style="color: red">{{ brunch_error[guest.id-1] }}</i>
                          </div>
                        </v-col>
                      </v-row>
                      <v-row style="margin-top: -20px;" v-if="form.attending[guest.id-1]==='yes'">
                        <v-col>
                          <v-radio-group class="ibarra bold" v-model="form.brunch[guest.id-1]">
                            <v-radio color="#9CAF88" label="Yes" value="yes"></v-radio>
                            <v-spacer style="height: 15px;"></v-spacer>
                            <v-radio color="#9CAF88" label="No" value="no"></v-radio>
                          </v-radio-group>
                        </v-col>
                      </v-row>
                      <v-row style="margin-top: 30px;" v-if="form.attending[guest.id-1]==='yes'">
                        <v-col>
                          <div class="ibarra txt-small bold">
                            A Note About Dinner
                          </div>
                        </v-col>
                      </v-row>
                      <v-row style="margin-top: 5px;" v-if="form.attending[guest.id-1]==='yes'">
                        <v-col>
                          <div class="ibarra bold">
                            Dinner will be served buffet style which means that you don't need to pick ahead of time.
                            <div>There are three main dish options from which to choose:</div>
                            <ul>
                              <li>
                                Traditional Stuffed Chicken
                              </li>
                              <li>
                                Rustic Beef Pot Roast
                              </li>
                              <li>
                                Butternut Squash Ravioli (Vegetarian)
                              </li>
                            </ul>
                            You can choose to have one or all of the options.
                            <div v-if="guests[guest.id-1].is_child" style="margin-top: 10px; font-style: italic">
                              Additionally, there will be a dinner option for your child.
                              We will be reaching out within the next month to get your opinion on what best fits your child's taste!
                            </div>
                          </div>
                        </v-col>
                      </v-row>
                      <v-row style="margin-top: 50px;" v-if="form.attending[guest.id-1]==='yes'">
                        <v-col>
                          <div class="ibarra txt-small bold">
                            Dietary Restrictions? (optional)
                          </div>
                        </v-col>
                      </v-row>
                      <v-row v-if="form.attending[guest.id-1]==='yes'">
                        <v-col>
                          <v-textarea
                              v-model="form.dietary_restrictions[guest.id-1]"
                              counter
                              outlined
                              maxlength="200"
                              style="max-width: 700px;"
                              class="ibarra"
                              color="#9CAF88"
                          >
                          </v-textarea>
                        </v-col>
                      </v-row>
                      <v-row style="margin-top: 30px;" v-if="form.attending[guest.id-1]==='yes'">
                        <v-col>
                          <div class="ibarra txt-small bold">
                            Songs That Get You on the Dance Floor? (optional)
                          </div>
                        </v-col>
                      </v-row>
                      <v-row v-if="form.attending[guest.id-1]==='yes'">
                        <v-col>
                          <v-textarea
                              v-model="form.song_requests[guest.id-1]"
                              counter
                              outlined
                              maxlength="200"
                              style="max-width: 700px;"
                              class="ibarra"
                              color="#9CAF88"
                          >
                          </v-textarea>
                        </v-col>
                      </v-row>
                      <v-row style="margin-top: 30px;">
                        <v-col>
                          <div class="ibarra txt-small bold">
                            Other Comments for the Couple? (optional)
                          </div>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col>
                          <v-textarea
                              v-model="form.comments[guest.id-1]"
                              counter
                              outlined
                              maxlength="200"
                              style="max-width: 700px;"
                              class="ibarra"
                              color="#9CAF88"
                          >
                          </v-textarea>
                        </v-col>
                      </v-row>
                      <v-row v-if="error != ''" justify="center">
                        <v-col align="center">
                          <div class="ibarra txt-small bold">
                            <i style="color: red">{{ error }}</i>
                          </div>
                        </v-col>
                      </v-row>
                      <v-row style="margin-top: 30px; margin-bottom: 5px">
                        <v-col align="left" v-if="tab !== 0">
                          <v-btn
                              @click="tab -= 1"
                              color="grey"
                              class="submit"
                          >
                            <div class="ibarra bold" style="color: white">
                              Previous
                            </div>
                          </v-btn>
                        </v-col>
                        <v-col align="right" v-if="tab !== guests.length-1">
                          <v-btn
                              @click="tab += 1"
                              color="grey"
                              class="submit"
                          >
                            <div class="ibarra bold" style="color: white">
                              Next
                            </div>
                          </v-btn>
                        </v-col>
                        <v-col align="right" v-if="tab === guests.length-1">
                          <v-btn class="submit ibarra txt-white bold" color="#9CAF88" @click="showEmail">Submit</v-btn>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-tab-item>
              </v-tabs-items>
            </v-col>
          </v-row>
        </v-card>
      </form>
      <v-row v-else style="margin-top: 100px;">
        <v-col>
          <v-progress-circular
              :value="submit_progress"
              :size="200"
              :width="20"
              color="#9CAF88"
          >
            <v-icon
              color="#9CAF88"
              :size="200"
              v-if="submit_progress === 100"
            >
              mdi-check-circle</v-icon>
          </v-progress-circular>
        </v-col>
      </v-row>
      <div class="text-center">
        <v-dialog
          v-model="show_email"
          width="500"
        >

          <v-card>
            <v-card-title class="ibarra grey lighten-4">
              Enter email for reminders!
            </v-card-title>

            <v-text-field
                style="padding-left: 20px; padding-right: 20px"
                label="Email"
                v-model="email"
                @keydown.enter="submitForm"
                color="#9CAF88"
              ></v-text-field>
            <div class="ibarra txt-small" style="padding-left: 20px"><i style="color: red">{{ email_error }}</i></div>

            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn class="submit ibarra txt-white bold" color="#9CAF88" @click="submitForm">Submit</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import { initializeApp } from 'firebase/app'
import { ref, getDatabase, onValue, set } from 'firebase/database'

// Import the functions you need from the SDKs you need
//import firebase from "firebase";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAxIlavv4dePmQzgrib5NgrZu0mYCOrWo4",
  authDomain: "wedding-website-4a01e.firebaseapp.com",
  projectId: "wedding-website-4a01e",
  storageBucket: "wedding-website-4a01e.appspot.com",
  messagingSenderId: "879310956944",
  appId: "1:879310956944:web:7e6e22a02a934483b76b15"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getDatabase(app)

export default {
  name: "RSVPInfo",

  data() {
    return {
      guests: [],
      step: 1,
      tab: null,
      form: {
        attending: [],
        rehearsal_dinner: [],
        brunch: [],
        dietary_restrictions: [],
        song_requests: [],
        comments: [],
      },
      submit: false,
      submit_progress: 0,
      error: '',
      attending_error: [],
      rehearsal_dinner_error: [],
      brunch_error: [],
      name_error: [],
      show_email: false,
      email: '',
      email_error: '',
      completed: false,
      width: 0
    }
  },

  props: {
    guest_id: Number
  },

  mounted() {
    this.getGuests()
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
    })
    this.onResize()
  },

  methods: {
    onResize() {
      this.width = window.innerWidth
    },
    getGuests() {
      const count = ref(db, 'guests/' + this.guest_id + '/count')
      onValue(count, (snapshot) => {
        const data = snapshot.val()
        for (let x = 1; x <= data; x++) {
          const num = x
          const name = ref(db, 'guests/' + this.guest_id + '/extras/' + x)
          onValue(name, (snapshot) => {
            const data2 = snapshot.val()
            this.guests.push({
              id: num,
              name: data2.name,
              plus_one: data2.plus_one == true ? true : false,
              is_child: data2.is_child == true ? true : false,
              rehearsal_dinner_invite: data2.rehearsal_dinner_invite
            })
            if (data2.attending != undefined && data2.attending != "N/A") {
              this.form.attending[num - 1] = data2.attending
            }
            if (data2.rehearsal_dinner != undefined && data2.rehearsal_dinner != "N/A") {
              this.form.rehearsal_dinner[num - 1] = data2.rehearsal_dinner
            }
            if (data2.brunch != undefined && data2.brunch != "N/A") {
              this.form.brunch[num - 1] = data2.brunch
            }
            if (data2.dietary_restrictions != undefined && data2.dietary_restrictions != "N/A") {
              this.form.dietary_restrictions[num - 1] = data2.dietary_restrictions
            }
            if (data2.song_requests != undefined && data2.song_requests != "N/A") {
              this.form.song_requests[num - 1] = data2.song_requests
            }
            if (data2.comments != undefined && data2.comments != "N/A") {
              this.form.comments[num - 1] = data2.comments
            }
          })
        }
      })
    },
    showEmail() {
      let err = false
      for (let y = 0; y < this.guests.length; y++) {
        this.attending_error[y] = ''
        this.rehearsal_dinner_error[y] = ''
        this.brunch_error[y] = ''
        this.name_error[y] = ''
        if (!this.guests[y].rehearsal_dinner_invite) {
          this.form.rehearsal_dinner[y] = "N/A"
        }
        if (this.form.attending[y] === undefined) {
          this.attending_error[y] = '*Required'
          err = true
        } else if (this.form.attending[y] === 'no') {
          this.form.brunch[y] = 'N/A'
          this.form.rehearsal_dinner[y] = 'N/A'
        }
        if (this.form.rehearsal_dinner[y] === undefined) {
          this.rehearsal_dinner_error[y] = '*Required'
          err = true
        }
        if (this.form.brunch[y] === undefined) {
          this.brunch_error[y] = '*Required'
          err = true
        }
        if (this.guests[y].name === '') {
          this.name_error[y] = '*Required'
          err = true
        }
      }
      if (err) {
        this.error = 'There are errors that need to be corrected.'
        let temp = this.attending_error
        this.attending_error = []
        this.attending_error = temp
        temp = this.rehearsal_dinner_error
        this.rehearsal_dinner_error = []
        this.rehearsal_dinner_error = temp
        temp = this.brunch_error
        this.brunch_error = []
        this.brunch_error = temp
        temp = this.name_error
        this.name_error = []
        this.name_error = temp
      } else {
        this.error = ''
        this.show_email = true
      }
    },
    submitForm() {
      this.$parent.submit = true
      this.completed = true
      if (this.email === '') {
        this.email_error = 'Email required.'
        return
      }
      let re = /\S+@\S+\.\S+/
      if (!re.test(this.email)) {
        this.email_error = 'Must be an email.'
        return
      }
      this.submit = true
      this.show_email = false
      const len = this.guests.length
      for (let x = 0; x < len; x++) {
        if (this.form.dietary_restrictions[x] === undefined) {
          this.form.dietary_restrictions[x] = 'N/A'
        }
        if (this.form.comments[x] === undefined) {
          this.form.comments[x] = 'N/A'
        }
        if (this.form.song_requests[x] === undefined) {
          this.form.song_requests[x] = 'N/A'
        }
        set(ref(db, 'guests/' + this.guest_id + '/extras/' + (x + 1)), {
          name: this.guests[x].name,
          plus_one: this.guests[x].plus_one,
          attending: this.form.attending[x],
          rehearsal_dinner: this.form.rehearsal_dinner[x],
          brunch: this.form.brunch[x],
          dietary_restrictions: this.form.dietary_restrictions[x],
          song_requests: this.form.song_requests[x],
          comments: this.form.comments[x]
        })
        setTimeout(() => {
          this.submit_progress = ((x + 1) / len) * 100
          if (this.submit_progress === 100) {
            setTimeout(() => {
              this.$router.push({ name: 'home' })
            }, 2000)
          }
        }, 500)
      }
      set(ref(db, 'guests/' + this.guest_id + '/email'), this.email)
      set(ref(db, 'guests/' + this.guest_id + '/completed'), true)
      let date = new Date()
      set(ref(db, 'guests/' + this.guest_id + '/time'), date.toString())
    }
  }
}
</script>

<style scoped>
.radio {
  transform: scale(1.5)
}
.float-bottom {
  position: absolute;
  bottom: 0px;
}
.ibarra {
  font-family: 'Ibarra Real Nova', serif;
  color: dimgray;
}
.home-img {
  width: 90%;
  height: auto;
  object-fit: cover;
  object-position: 43% 57%
}
.location-cards {
  width: 80%;
  margin-bottom: 40px;
}
.txt-large {
  font-size: 40px;
}
.txt-med {
  font-size: 25px;
}
.txt-small {
  font-size: 20px;
}
.txt-x-small {
  font-size: 18px;
}
.bold {
  font-weight: bold;
}
.justify {
  text-align: justify;
  text-justify: inter-word;
}
.txt-white {
  color: white;
}
</style>