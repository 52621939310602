<template>
  <Wrapper>
    <v-row justify="center">
      <v-col align="center" style="max-width: 700px">
        <div class="ibarra txt-med justify">
          Mitchell and Hannah met at their high school in 2016.
          Hannah sat in front of Mitchell in their sophomore year history class,
          but didn't start talking until she pied him in the face with whip cream at a post-Turkey Bowl celebration.
        </div>
      </v-col>
    </v-row>

    <v-row justify="center">
      <v-col align="center">
        <v-img
            lazy-src="https://picsum.photos/10/6?image=30"
            src="@/assets/first2.jpg"
            aspect-ratio="1.4"
            max-width="650"
        >
          <template v-slot:placeholder>
            <v-row
              class="fill-height ma-0"
              align="center"
              justify="center"
            >
              <v-progress-circular
                indeterminate
                color="grey lighten-5"
              ></v-progress-circular>
            </v-row>
          </template>
        </v-img>
      </v-col>
    </v-row>

    <v-row justify="center">
      <v-col align="center" style="max-width: 700px">
        <div class="ibarra txt-med justify">
          After graduating high school, they both attended The Pennsylvania State University and experienced college together.
        </div>
      </v-col>
    </v-row>

    <v-row justify="center">
      <v-col align="center">
        <v-img
            lazy-src="https://picsum.photos/10/6?image=20"
            src="@/assets/beaver2.jpg"
            aspect-ratio="1.4"
            max-width="650"
        >
          <template v-slot:placeholder>
            <v-row
              class="fill-height ma-0"
              align="center"
              justify="center"
            >
              <v-progress-circular
                indeterminate
                color="grey lighten-5"
              ></v-progress-circular>
            </v-row>
          </template>
        </v-img>
      </v-col>
    </v-row>

    <v-row justify="center">
      <v-col align="center" style="max-width: 700px">
        <div class="ibarra txt-med justify">
          After Mitchell graduated from Penn State in 2022, he accepted a job at Epic Systems in Madison, Wisconsin.
        </div>
      </v-col>
    </v-row>

    <v-row justify="center">
      <v-col align="center">
        <v-img
            lazy-src="https://picsum.photos/10/6?image=15"
            src="@/assets/mama.jpg"
            aspect-ratio="1.4"
            max-width="650"
        >
          <template v-slot:placeholder>
            <v-row
              class="fill-height ma-0"
              align="center"
              justify="center"
            >
              <v-progress-circular
                indeterminate
                color="grey lighten-5"
              ></v-progress-circular>
            </v-row>
          </template>
        </v-img>
      </v-col>
    </v-row>

    <v-row justify="center">
      <v-col align="center" style="max-width: 700px">
        <div class="ibarra txt-med justify">
          Before he left, he took Hannah to dinner downtown to celebrate one of their last nights in Pittsburgh together.
          After dinner, they took a walk at the Point. There, Mitchell got on one knee and proposed. Of course Hannah said yes!
        </div>
      </v-col>
    </v-row>

    <v-row justify="center">
      <v-col align="center">
        <v-img
            lazy-src="https://picsum.photos/10/6?image=25"
            src="@/assets/focus.jpg"
            aspect-ratio="1.4"
            max-width="650"
        >
          <template v-slot:placeholder>
            <v-row
              class="fill-height ma-0"
              align="center"
              justify="center"
            >
              <v-progress-circular
                indeterminate
                color="grey lighten-5"
              ></v-progress-circular>
            </v-row>
          </template>
        </v-img>
      </v-col>
    </v-row>

  </Wrapper>
</template>

<script>
// import Header from '@/components/Header.vue'
// import Footer from '@/components/Footer.vue'
import Wrapper from "@/components/Wrapper";
export default {
  name: "OurStoryView",
  components: {
    Wrapper
  }
}
</script>

<style scoped>
.ibarra {
  font-family: 'Ibarra Real Nova', serif;
  color: dimgray;
}
.home-img {
  width: 90%;
  height: auto;
  object-fit: cover;
  object-position: 43% 57%
}
.location-cards {
  width: 80%;
  margin-bottom: 40px;
}
.txt-large {
  font-size: 40px;
}
.txt-med {
  font-size: 25px;
}
.txt-small {
  font-size: 20px;
}
.txt-x-small {
  font-size: 18px;
}
.bold {
  font-weight: bold;
}
.justify {
  text-align: justify;
  text-justify: inter-word;
}
</style>