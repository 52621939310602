<template>
  <div>
    <v-row style="margin: 50px;">
      <v-col>
        <v-row style="margin-bottom: 0px;">
          <v-col cols="12" sm="1" style="margin-top: 20px;">
            <v-icon x-large @click="goToLocation('budget-data')">
              mdi-arrow-left
            </v-icon>
          </v-col>
          <v-col>
            <div style="font-size: 40pt">
              {{ capitalizeFirstLetter(category) }}
            </div>
          </v-col>
        </v-row>
        <v-row style="margin-bottom: 20px;">
          <v-col cols="12" sm="9">
            <v-progress-linear
                :value="(spent_data / budget) * 100"
                :color="color"
            ></v-progress-linear>
          </v-col>
          <v-col style="margin-top: -22px" cols="12" sm="3" align="right">
            <div style="font-size: 25pt">
              <b v-if="spent_data <= budget" style="color: green">${{ Number(spent_data).toFixed(2) }}</b><b v-else style="color: red">${{ Number(spent_data).toFixed(2) }}</b> / ${{ budget }} spent
            </div>
          </v-col>
        </v-row>
        <v-card>
          <v-card-title>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>
          <v-data-table
            :headers="headers"
            :items="transactions_data"
            :search="search"
          >
            <template v-slot:[`item.name`]="{ item }">
              <div v-if="item.returned">{{ item.name }} (returned)</div>
              <div v-else>{{ item.name }}</div>
            </template>
            <template v-slot:[`item.price`]="{ item }">
              <div v-if="item.returned">$0 (${{ Number(item.price).toFixed(2) }})</div>
              <div v-else>${{ Number(item.price).toFixed(2) }}</div>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-icon
                medium
                class="mr-3"
                color="blue"
                @click="editItem(item)"
                >
                mdi-pencil
              </v-icon>
              <v-icon
                medium
                color="red"
                @click="deleteItem(item)"
                >
                mdi-delete
              </v-icon>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <div class="text-center">
      <v-dialog
        v-model="edit"
        width="600"
      >

        <v-card style="overflow: hidden">
          <v-row>
            <v-col align="center">
              <v-card-title class="text-h5 grey lighten-2">
                Edit item
              </v-card-title>
              <v-row style="max-width: 500px; padding-left: 50px; padding-right: 50px; padding-top: 20px;">
                <v-col>
                  <v-row>
                    <v-col align="left">
                      <div class="ibarra txt-med">
                        <b v-if="name_error" style="color: red">*</b>Name:
                      </div>
                    </v-col>
                    <v-col align="right">
                      <v-text-field
                          outlined
                          v-model="name"
                      ></v-text-field>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col align="left">
                      <div class="ibarra txt-med">
                        <b v-if="category_error" style="color: red">*</b>Category:
                      </div>
                    </v-col>
                    <v-col align="right">
                      <v-select
                          :items="category_items"
                          v-model="category_selection"
                          outlined
                      >
                      </v-select>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col align="left">
                      <div class="ibarra txt-med">
                        <b v-if="location_error" style="color: red">*</b>Location:
                      </div>
                    </v-col>
                    <v-col align="right">
                      <v-text-field
                          outlined
                          v-model="location"
                      ></v-text-field>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col align="left">
                      <div class="ibarra txt-med">
                        <b v-if="price_error" style="color: red">*</b>Price:
                      </div>
                    </v-col>
                    <v-col align="right">
                      <v-text-field
                          outlined
                          prefix="$"
                          v-model="price"
                      ></v-text-field>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col align="left">
                      <div class="ibarra txt-med">
                        Yearly:
                      </div>
                    </v-col>
                    <v-col align="right" style="margin-top: -7px;">
                      <v-switch v-model="vacation"></v-switch>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-col>
          </v-row>


          <v-divider></v-divider>

          <v-card-actions>
            <v-btn
                v-if="this.current_returned"
                style="height: 70px; width: 220px;"
                class="submit ibarra txt-white bold"
                color="red"
                @click="returnItem"
            >
              Unmark as returned
            </v-btn>
            <v-btn
                v-else
                style="height: 70px; width: 220px;"
                class="submit ibarra txt-white bold"
                color="blue"
                @click="returnItem"
            >
              Mark as returned
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
              style="height: 70px; width: 100px;"
              class="submit ibarra txt-white bold"
              color="green"
              @click="submitData"
            >
              <v-icon x-large>
                mdi-check-outline
              </v-icon>
            </v-btn>

          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
    <div class="text-center">
      <v-dialog
        v-model="remove"
        width="600"
      >

        <v-card style="overflow: hidden">
          <v-card-title class="text-h5 grey lighten-2">
            Confirm delete
          </v-card-title>


          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              style="height: 70px; width: 100px;"
              class="submit txt-white bold"
              color="red"
              @click="confirmDeleteItem"
            >
              Confirm
            </v-btn>

          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import { initializeApp } from 'firebase/app'
import {ref, getDatabase, set, get, child} from 'firebase/database'
import * as api from "@/views/Budget/LoadingAndSavingAPIs";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAxIlavv4dePmQzgrib5NgrZu0mYCOrWo4",
  authDomain: "wedding-website-4a01e.firebaseapp.com",
  projectId: "wedding-website-4a01e",
  storageBucket: "wedding-website-4a01e.appspot.com",
  messagingSenderId: "879310956944",
  appId: "1:879310956944:web:7e6e22a02a934483b76b15"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getDatabase(app)
const dbRef = ref(getDatabase());

export default {
  name: "BudgetGraph",

  props: {
    category: String,
    color: String,
    budget: Number,
    spent: Number,
    transactions: Array,
    month: String,
    year: String
  },

  mounted: function() {
    if (this.locked) {
      this.$router.push({ name: 'budget' })
    }
    this.category_items = api.getAllCategories(this.month == 13)
    this.transactions_data = this.transactions
    this.spent_data = this.spent
  },

  data() {
    return {
      transactions_data: [],
      spent_data: 0,
      search: '',
      headers: [
        {
          text: 'Date',
          value: 'date'
        },
        {
          text: 'Name',
          value: 'name'
        },
        {
          text: 'Location',
          value: 'location'
        },
        {
          text: 'Price',
          value: 'price'
        },
        {
         text: 'Payment',
         value: 'payment'
        },
        {
          text: 'Actions',
          value: 'actions',
          sortable: false,
          width: '100px'
        }
      ],
      edit: false,
      remove: false,
      name: '',
      original_category_selection: '',
      category_selection: '',
      location: '',
      price: 0,
      payment: "",
      vacation: false,
      original_price: 0,
      number: -1,
      date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      menu: false,
      category_items: [],
      name_error: false,
      date_error: false,
      category_error: false,
      location_error: false,
      price_error: false,
      item: null,
      current_returned: false
    }
  },

  watch: {
    vacation(val) {
      if (this.edit) {
        if (val) {
          this.category_items = api.getAllCategories(true)
          if (!this.category_items.includes(this.category_selection)) {
            this.category_selection = ''
          }
        } else {
          this.category_items = api.getAllCategories(false)
          if (!this.category_items.includes(this.category_selection)) {
            this.category_selection = ''
          }
        }
      }
    }
  },

  methods: {
    capitalizeFirstLetter(string) {
      const rep = string.replace("-", " ")
      const words = rep.split(" ");

      for (let i = 0; i < words.length; i++) {
          words[i] = words[i][0].toUpperCase() + words[i].substr(1);
      }

      return words.join(" ");
    },
    goToLocation(location) {
      this.$router.push({ name: location, params: { locked: false, month_prop: this.month, year_prop: this.year } })
    },
    deleteItem(item) {
      this.name = item.name
      this.category_selection = item.category
      this.location = item.location
      this.price = item.price
      this.number = item.number
      this.date = item.date
      if (item.payment === undefined) {
        this.payment = ""
      } else {
        this.payment = item.payment
      }
      this.item = item
      this.remove = true
    },
    confirmDeleteItem() {
      set(ref(db, `budget/transactions/${this.month == 13 ? 'vacation/' : ''}` + this.getDateString() + "/" + this.number), {
        name: this.name,
        date: this.date,
        category: this.category_selection,
        location: this.location,
        price: this.price,
        payment: this.payment,
        deleted: true
      })
      let index = this.transactions_data.indexOf(this.item)
      this.transactions_data.splice(index,1)
      this.spent_data -= this.price
      this.remove = false
    },
    editItem(item) {
      this.name = item.name
      this.category_selection = item.category
      this.original_category_selection = item.category
      this.location = item.location
      this.price = item.price
      this.original_price = item.price
      this.number = item.number
      this.date = item.date
      if (item.payment === undefined) {
        this.payment = ""
      } else {
        this.payment = item.payment
      }
      this.item = item
      this.vacation = this.month == 13
      const dbRef = ref(getDatabase())
      get(child(dbRef, `budget/transactions${this.month == 13 ? '/vacation' : ''}/${this.getDateString()}/${this.number}/returned`)).then((snapshot) => {
        const returned = snapshot.val()
        if (returned) {
          this.current_returned = true
        } else {
          this.current_returned = false
        }
      })
      this.edit = true
    },
    submitData() {
      if (this.validateData()) {
        if (this.month == 13 != this.vacation) {
          this.submitDataVacationChange()
        } else {
          set(ref(db, `budget/transactions/${this.month == 13 ? 'vacation/' : ''}` + this.getDateString() + "/" + this.number), {
            name: this.name,
            date: this.date,
            category: this.category_selection,
            location: this.location,
            price: this.price,
            payment: this.payment
          })
          this.spent_data += (this.price - this.original_price)
          let index = this.transactions_data.indexOf(this.item)
          this.transactions_data[index].price = this.price
          this.transactions_data[index].location = this.location
          this.transactions_data[index].name = this.name
          if (this.original_category_selection !== this.category_selection) {
            this.transactions_data.splice(index, 1)
            this.spent_data -= this.price
          }
          this.edit = false
        }
      }
    },
    submitDataVacationChange() {
      set(ref(db, `budget/transactions${this.vacation ? '' : '/vacation'}/${this.getDateString()}/${this.number}`), {
        name: this.name,
        date: this.date,
        category: this.category_selection,
        location: this.location,
        price: this.price,
        deleted: true
      })

      get(child(dbRef, `budget/transactions/${this.vacation ? 'vacation/' : ''}` + this.getDateString() + "/count")).then((snapshot) => {
        if (snapshot.exists()) {
          let newVal = parseInt(snapshot.val()) + 1
          set(ref(db, `budget/transactions/${this.vacation ? 'vacation/' : ''}` + this.getDateString() + "/" + newVal), {
            name: this.name,
            date: this.date,
            category: this.category_selection,
            location: this.location,
            price: this.price,
            returned: this.current_returned
          })
          set(ref(db, `budget/transactions/${this.vacation ? 'vacation/' : ''}` + this.getDateString() + "/count"), newVal)
          let index = this.transactions_data.indexOf(this.item)
          this.transactions_data.splice(index, 1)
          if (!this.current_returned) {
            this.spent_data -= this.original_price
          }
          this.edit = false
        } else {
          set(ref(db, `budget/transactions/${this.vacation ? 'vacation/' : ''}` + this.getDateString() + "/1"), {
            name: this.name,
            date: this.date,
            category: this.category_selection,
            location: this.location,
            price: this.price,
            returned: this.current_returned
          })
          set(ref(db, `budget/transactions/${this.vacation ? 'vacation/' : ''}` + this.getDateString() + "/count"), "1")
          let index = this.transactions_data.indexOf(this.item)
          this.transactions_data.splice(index, 1)
          if (!this.current_returned) {
            this.spent_data -= this.original_price
          }
          this.edit = false
        }
      })
    },
    returnItem() {
      set(ref(db, "budget/transactions/" + this.getDateString() + "/" + this.number + "/returned"), !this.current_returned)
      if (this.current_returned) {
        this.spent_data += this.price
      } else {
        this.spent_data -= this.price
      }
      let index = this.transactions_data.indexOf(this.item)
      this.transactions_data[index].returned = !this.current_returned
      this.edit = false
    },
    getDateString() {
      const pieces = this.date.split('-')
      const newPieces = []
      for (let element of pieces) {
        newPieces.push(parseInt(element))
      }
      return newPieces.join('/')
    },
    validateData() {
      let clean = true
      if (this.name === '') {
        clean = false
        this.name_error = true
      } else { this.name_error = false }
      if (this.date === '') {
        clean = false
        this.date_error = true
      } else { this.date_error = false }
      if (this.category_selection === '') {
        clean = false
        this.category_error = true
      } else { this.category_error = false }
      if (this.location === '') {
        clean = false
        this.location_error = true
      } else { this.location_error = false }
      if (this.price <= 0) {
        clean = false
        this.price_error = true
      } else { this.price_error = false }

      return clean
    }
  }
}
</script>

<style scoped>
.txt-white {
  color: white;
}
</style>