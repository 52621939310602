<template>
  <div style="margin: 50px;">
    <Header></Header>
    <v-row style="margin-top: 80px; margin-bottom: 80px;">
      <v-col>
        <slot></slot>
      </v-col>
    </v-row>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
export default {
  name: "WrapperView.vue",
  components: {
    Header,
    Footer
  }
}
</script>

<style scoped>

</style>