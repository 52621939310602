<template>
    <v-row>
      <v-col>
        <SplashView
          :show="show_splash"
          :logo="require('@/assets/ring.png')"
          title="H & M"
          color="#9CAF88"
          :size="300"
          :fixed="true"
        />
      </v-col>
    </v-row>
</template>

<script>
import SplashView from "@/components/SplashView";


  export default {
    name: 'HomeView',

    props: {
      show: {
        type: Boolean,
        default: true,
      }
    },

    data() {
      return {
        show_splash: true,
      }
    },

    components: {
      SplashView
    },

    mounted: function() {
      this.showSplash()
    },

    methods: {
      showSplash() {
        this.show_splash = this.show
        if (this.show_splash) {
          setTimeout(() => {
            this.$router.push({ name: 'home' })
          }, 3000)
        }
      },
    }
  }
</script>

<style>

</style>