<template>
  <v-row>
    <v-col>

      <v-row v-if="locked" style="padding: 30px">
        <v-col align="center">
          <v-row>
            <v-col>
              <v-text-field
                  style="max-width: 500px;"
                  v-model="password"
                  label="Password"
                  outlined
                  type="password"
                  @keydown.enter="validatePassword"
                  autofocus
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-btn class="submit ibarra txt-white bold" color="#9CAF88" @click="validatePassword">Submit</v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <v-row v-else style="padding-left: 30px; padding-right: 30px;">
        <v-col align="center">
          <v-row>
            <v-col align="left" style="padding-top: 25px;">
              <v-btn class="submit ibarra txt-white bold" color="#9CAF88" @click="goHome">Home</v-btn>
            </v-col>
            <v-col align="center">
              <div class="ibarra txt-large bold">
                RSVP Summary
              </div>
            </v-col>
            <v-col align="right" style="padding-top: 25px;">
              <v-btn class="submit ibarra txt-white bold" color="#9CAF88" @click="exportEmails">Export Emails</v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-card elevation="15">
                <v-row>
                  <v-col>
                    <div class="ibarra txt-med bold">
                      Attending Wedding
                    </div>
                    <v-spacer style="height: 25px;"></v-spacer>
                    <div class="ibarra bold">
                      <v-chip
                          color="#9CAF88"
                          style="color: white; font-size: 20px;"
                      >
                        {{ attendance_total }}
                      </v-chip>
                    </div>
                  </v-col>
                  <v-col>
                    <div class="ibarra txt-med bold">
                      Attending Rehearsal Dinner
                    </div>
                    <v-spacer style="height: 25px;"></v-spacer>
                    <div class="ibarra bold">
                      <v-chip
                          color="#9CAF88"
                          style="color: white; font-size: 20px;"
                      >
                        {{ attending_rehearsal }}
                      </v-chip>
                    </div>
                  </v-col>
                  <v-col>
                    <div class="ibarra txt-med bold">
                      Attending Brunch
                    </div>
                    <v-spacer style="height: 25px;"></v-spacer>
                    <div class="ibarra bold">
                      <v-chip
                          color="#9CAF88"
                          style="color: white; font-size: 20px;"
                      >
                        {{ attending_brunch }}
                      </v-chip>
                    </div>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-card elevation="15">
                <v-card-title>
                  Guests
                </v-card-title>
                <v-card-title>
                  <v-text-field
                    v-model="guests_search"
                    append-icon="mdi-magnify"
                    label="Search"
                    single-line
                    hide-details
                  ></v-text-field>
                </v-card-title>
                <v-data-table
                  :headers="guests_headers"
                  :items="guests"
                  :search="guests_search"
                >
                  <template v-slot:[`item.attending`]="{ item }">
                    <v-chip
                      :color="getGuestColor(item.attending)"
                      dark
                    >
                      {{ getGuestAttending(item.attending) }}
                    </v-chip>
                  </template>
                  <template v-slot:[`item.rehearsal_dinner`]="{ item }">
                    <v-chip
                      :color="getGuestColor(item.rehearsal_dinner)"
                      dark
                    >
                      {{ getGuestAttending(item.rehearsal_dinner, true, item.attending, item.rehearsal_dinner_invite) }}
                    </v-chip>
                  </template>
                  <template v-slot:[`item.brunch`]="{ item }">
                    <v-chip
                      :color="getGuestColor(item.brunch)"
                      dark
                    >
                      {{ getGuestAttending(item.brunch, true, item.attending) }}
                    </v-chip>
                  </template>
                </v-data-table>
              </v-card>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-card elevation="15">
                <v-card-title>
                  Parties
                </v-card-title>
                <v-card-title>
                  <v-text-field
                    v-model="parties_search"
                    append-icon="mdi-magnify"
                    label="Search"
                    single-line
                    hide-details
                  ></v-text-field>
                </v-card-title>
                <v-data-table
                  :headers="parties_headers"
                  :items="parties"
                  :search="parties_search"
                  :custom-sort="customSort"
                >
                  <template v-slot:[`item.attending`]="{ item }">
                    <v-chip
                      :color="getPartyColor(item.email)"
                      dark
                    >
                      {{ getPartyAttending(item.attending, item.email) }}
                    </v-chip>
                  </template>
                  <template v-slot:[`item.time`]="{ item }">
                    {{ getTime(item.time) }}
                  </template>
                </v-data-table>
              </v-card>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-card elevation="15">
                <v-card-title>
                  Questions
                </v-card-title>
                <v-card-title>
                  <v-text-field
                    v-model="questions_search"
                    append-icon="mdi-magnify"
                    label="Search"
                    single-line
                    hide-details
                  ></v-text-field>
                </v-card-title>
                <v-data-table
                  :headers="questions_headers"
                  :items="questions"
                  :search="questions_search"
                  :custom-sort="customSort"
                >
                  <template v-slot:[`item.email`]="{ item }">
                    <a :href="getEmailLink(item.email)">{{ item.email }}</a>
                  </template>
                  <template v-slot:[`item.time`]="{ item }">
                    {{ getTime(item.time) }}
                  </template>
                </v-data-table>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { initializeApp } from 'firebase/app'
import { ref, getDatabase, onValue } from 'firebase/database'

// Import the functions you need from the SDKs you need
//import firebase from "firebase";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAxIlavv4dePmQzgrib5NgrZu0mYCOrWo4",
  authDomain: "wedding-website-4a01e.firebaseapp.com",
  projectId: "wedding-website-4a01e",
  storageBucket: "wedding-website-4a01e.appspot.com",
  messagingSenderId: "879310956944",
  appId: "1:879310956944:web:7e6e22a02a934483b76b15"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getDatabase(app)
export default {
  name: "RSVPSummary",

  data() {
    return {
      csvdata: [],
      nonemails: [],
      locked: true,
      password: '',
      guests_headers: [
        {
          text: 'Name',
          value: 'name'
        },
        {
          text: 'Attending',
          value: 'attending'
        },
        {
          text: 'Rehearsal Dinner',
          value: 'rehearsal_dinner'
        },
        {
          text: 'Brunch',
          value: 'brunch'
        },
        {
          text: 'Dietary Restrictions',
          value: 'dietary_restrictions'
        },
        {
          text: 'Song Requests',
          value: 'song_requests'
        },
        {
          text: 'Comments',
          value: 'comments'
        }
      ],
      parties_headers: [
        {
          text: 'Name',
          value: 'name'
        },
        {
          text: 'Attending',
          value: 'attending'
        },
        {
          text: 'Party Email',
          value: 'email'
        },
        {
          text: 'Time Submitted',
          value: 'time'
        }
      ],
      questions_headers: [
        {
          text: 'Name',
          value: 'name'
        },
        {
          text: 'Email',
          value: 'email'
        },
        {
          text: 'Question',
          value: 'question'
        },
        {
          text: 'Time Submitted',
          value: 'time'
        }
      ],
      guests: [],
      parties: [],
      questions: [],
      guests_search: '',
      parties_search: '',
      questions_search: '',
      attendance_total: 0,
      attending_brunch: 0,
      attending_rehearsal: 0
    }
  },

  mounted() {
    this.getGuests()
    this.getParties()
    this.getQuestions()
  },

  methods: {
    exportEmails() {
      let csv = 'Email\n';
      this.csvdata.forEach((row) => {
              csv += row;
              csv += "\n";
      });

      const anchor = document.createElement('a');
      anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv);
      anchor.target = '_blank';
      anchor.download = 'weddingEmails.csv';
      anchor.click();
    },
    /**
     * Custom sort function for entire search table. Includes sorting function specific to dancer_number header
     * and a general sorting function for all other header fields.
     */
    customSort(items, index, isDesc) {
      items.sort((a, b) => {
        if (index[0] === 'time') {
          let date_a = ""
          if (a.time) {
            date_a = new Date(a.time)
          }
          let date_b = ""
          if (b.time) {
            date_b = new Date(b.time)
          }
          if (!isDesc[0]) {
            if (date_a !== "" && date_b === "") {
              return -1
            } else if (date_a === "" && date_b !== "") {
              return 1
            }
            return date_a > date_b ? -1 : 1
          } else {
            if (date_a !== "" && date_b === "") {
              return -1
            } else if (date_a === "" && date_b !== "") {
              return 1
            }
            return date_b > date_a ? -1 : 1
          }
        } else {
          if (!isDesc[0]) {
            return a[index] < b[index] ? -1 : 1
          } else {
            return b[index] < a[index] ? -1 : 1
          }
        }
      })
      return items
    },
    validatePassword() {
      if (this.hash(this.password) === '270674a') {
        this.locked = false
      } else {
        this.password = ''
      }
    },

    /**
     * Creates a hashed string from plaintext password input
     */
    hash(b) {
      for (var a = 0, c = b.length; c--;) {
        a += b.charCodeAt(c)
        a += a << 10
        a ^= a >> 6
        a += a << 3
        a ^= a >> 11
      }
      return ((a + (a << 15) & 4294967295) >>> 0).toString(16)
    },

    goHome() {
      this.$router.push({ name: 'home' })
    },

    getGuests() {
      const count = ref(db, 'guests/count')
      onValue(count, (snapshot) => {
        const data = snapshot.val()
        for (let x = 1; x <= data; x++) {
          const voided = ref(db, 'guests/' + x + '/void')
          onValue(voided, (snapshot) => {
            const isVoided = snapshot.val()
            if (!isVoided) {
              const count2 = ref(db, 'guests/' + x)
              onValue(count2, (snapshot) => {
                const data2 = snapshot.val()
                for (let y = 1; y <= data2.count; y++) {
                  const guest = ref(db, 'guests/' + x + '/extras/' + y)
                  onValue(guest, (snapshot) => {
                    const data3 = snapshot.val()
                    if (!data2.completed) {
                      data3.attending = 'N/A'
                    }
                    this.guests.push(data3)
                  })
                }
              })
            }
          })
        }
      })
    },
    getParties() {
      const count = ref(db, 'guests/count')
      onValue(count, (snapshot) => {
        const data = snapshot.val()
        for (let x = 1; x <= data; x++) {
          const voided = ref(db, 'guests/' + x + '/void')
          onValue(voided, (snapshot) => {
            const isVoided = snapshot.val()
            if (!isVoided) {
              const count2 = ref(db, 'guests/' + x)
              onValue(count2, (snapshot) => {
                const data2 = snapshot.val()
                let att = 0
                let fin = 0
                let isAttending = false
                for (let y = 1; y <= data2.count; y++) {
                  const guest = ref(db, 'guests/' + x + '/extras/' + y)
                  onValue(guest, (snapshot) => {
                    const data3 = snapshot.val()
                    if (data2.completed) {
                      if (data3.attending === 'yes') {
                        att += 1
                        isAttending = true
                      }
                      if (data3.brunch === 'yes') {
                        this.attending_brunch += 1
                      }
                      if (data3.rehearsal_dinner === 'yes') {
                        this.attending_rehearsal += 1
                      }
                    }
                    fin += 1
                    if (fin === data2.count) {
                      this.parties.push({
                        name: data2.name,
                        attending: att + '/' + fin,
                        email: data2.email,
                        time: data2.time
                      })
                      if (isAttending) {
                        if (!this.csvdata.includes(data2.email)) {
                          this.csvdata.push(data2.email)
                        }
                      }
                      else {
                        this.nonemails.push(data2.email)
                      }
                      this.attendance_total += att
                    }
                  })
                }
              })
            }
          })
        }
      })
    },
    getQuestions() {
      const count = ref(db, 'questions/count')
      onValue(count, (snapshot) => {
        const data = snapshot.val()
        for (let x = 1; x <= data; x++) {
          const question = ref(db, 'questions/' + x)
          onValue(question, (snapshot) => {
            const data2 = snapshot.val()
            this.questions.push(data2)
          })
        }
      })
    },
    getGuestColor(attending) {
      if (attending === 'yes') {
        return 'green'
      } else if (attending === 'no') {
        return 'red'
      }
      return 'gray'
    },
    getGuestAttending(attending, brunch = false, extra = 'yes', invited = false) {
      if (attending === 'yes') {
        return 'Yes'
      } else if (attending == 'no') {
        return 'No'
      } else if (brunch && extra == 'no' || (brunch && extra == 'yes' && !invited)) { // This is the case for if a person is not attending the wedding or if the person was not invited to the rehearsal dinner
        return 'N/A'
      }
      return 'Pending'
    },
    getPartyColor(email) {
      if (email === undefined) {
        return 'gray'
      }
      return 'blue'
    },
    getPartyAttending(attending, email) {
      if (email === undefined) {
        return 'Pending'
      }
      return attending
    },
    getTime(time) {
      if (time === "" || time === undefined) {
        return ""
      }
      let date = new Date(time)
      return date.toLocaleTimeString('en-us', { weekday:"long", year:"numeric", month:"short", day:"numeric"})
    },
    getEmailLink(email) {
      return "mailto:" + email
    }
  }
}
</script>

<style scoped>
.radio {
  transform: scale(1.5)
}
.ibarra {
  font-family: 'Ibarra Real Nova', serif;
  color: dimgray;
}
.home-img {
  width: 90%;
  height: auto;
  object-fit: cover;
  object-position: 43% 57%
}
.location-cards {
  width: 80%;
  margin-bottom: 40px;
}
.txt-large {
  font-size: 40px;
}
.txt-med {
  font-size: 25px;
}
.txt-small {
  font-size: 20px;
}
.txt-x-small {
  font-size: 18px;
}
.bold {
  font-weight: bold;
}
.justify {
  text-align: justify;
  text-justify: inter-word;
}
.txt-white {
  color: white;
}
</style>