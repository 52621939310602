<template>
  <v-row justify="center">
    <v-col align="center" style="max-width: 350px" cols="12" sm="9">
      <v-img lazy-src="https://picsum.photos/10/6?image=20" :src="require('@/assets/' + image)" aspect-ratio=".9" max-width="250">
        <template v-slot:placeholder>
          <v-row
            class="fill-height ma-0"
            align="center"
            justify="center"
          >
            <v-progress-circular
              indeterminate
              color="grey lighten-5"
            ></v-progress-circular>
          </v-row>
          </template>
      </v-img>
    </v-col>
    <v-col align="center" style="max-width: 350px" cols="12" sm="3">
      <v-row>
        <v-col>
          <div class="ibarra txt-med bold">
            {{name }}
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <div class="ibarra txt-med">
            {{ bio }}
          </div>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "WeddingPartyImage",

  props: {
    image: String,
    name: String,
    bio: String
  }
}
</script>

<style scoped>
.ibarra {
  font-family: 'Ibarra Real Nova', serif;
  color: dimgray;
}
.home-img {
  width: 90%;
  height: auto;
  object-fit: cover;
  object-position: 43% 57%
}
.location-cards {
  width: 80%;
  margin-bottom: 40px;
}
.txt-large {
  font-size: 40px;
}
.txt-med {
  font-size: 25px;
}
.txt-small {
  font-size: 20px;
}
.txt-x-small {
  font-size: 18px;
}
.bold {
  font-weight: bold;
}
.justify {
  text-align: justify;
  text-justify: inter-word;
}
</style>