<template>
  <Wrapper>
    <div v-if="!isMobile">
      <v-row>
        <v-col>
          <div class="ibarra txt-large" style="margin-left: 12px;">
            High School
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          v-for="n in 9"
          :key="n"
          class="d-flex child-flex"
          cols="4"
        >
          <v-hover style="margin-left: 10px; margin-right: 10px;">
            <template v-slot:default="{ hover }">
              <v-card>
                <v-img
                  :src="require(`@/assets/${high_school_images[n-1]}`)"
                  :lazy-src="`https://picsum.photos/10/6?image=${n * 5 + 10}`"
                  aspect-ratio="1"
                  class="grey lighten-2 image"
                >
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height ma-0"
                      align="center"
                      justify="center"
                    >
                      <v-progress-circular
                        indeterminate
                        color="grey lighten-5"
                      ></v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
                <v-fade-transition>
                  <v-overlay
                    v-if="hover"
                    absolute
                    color="#9CAF88"
                    opacity="0.75"
                  >
                    <div class="text ibarra" style="width: 200px;">{{ high_school_dates[n-1] }}</div>
                  </v-overlay>
                </v-fade-transition>
                </v-card>
              </template>
          </v-hover>
        </v-col>
      </v-row>
      <v-row style="padding-top: 50px;">
        <v-col>
          <div class="ibarra txt-large" style="margin-left: 12px;">
            College
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          v-for="n in 15"
          :key="n"
          class="d-flex child-flex"
          cols="4"
        >
          <v-hover style="margin-left: 10px; margin-right: 10px;">
            <template v-slot:default="{ hover }">
              <v-card>
                <v-img
                  :src="require(`@/assets/${college_images[n-1]}`)"
                  :lazy-src="`https://picsum.photos/10/6?image=${n * 5 + 10}`"
                  aspect-ratio="1"
                  class="grey lighten-2 image"
                >
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height ma-0"
                      align="center"
                      justify="center"
                    >
                      <v-progress-circular
                        indeterminate
                        color="grey lighten-5"
                      ></v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
                <v-fade-transition>
                  <v-overlay
                    v-if="hover"
                    absolute
                    color="#9CAF88"
                    opacity="0.75"
                  >
                    <div class="text ibarra" style="width: 200px;">{{ college_dates[n-1] }}</div>
                  </v-overlay>
                </v-fade-transition>
                </v-card>
              </template>
          </v-hover>
        </v-col>
      </v-row>
      <v-row style="padding-top: 50px;">
        <v-col>
          <div class="ibarra txt-large" style="margin-left: 12px;">
            Engagement
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          v-for="n in 9"
          :key="n"
          class="d-flex child-flex"
          cols="4"
        >
          <v-hover style="margin-left: 10px; margin-right: 10px;">
            <template v-slot:default="{ hover }">
              <v-card>
                <v-img
                  :src="require(`@/assets/${engagement_images[n-1]}`)"
                  :lazy-src="`https://picsum.photos/10/6?image=${n * 5 + 10}`"
                  aspect-ratio="1"
                  class="grey lighten-2 image"
                >
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height ma-0"
                      align="center"
                      justify="center"
                    >
                      <v-progress-circular
                        indeterminate
                        color="grey lighten-5"
                      ></v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
                <v-fade-transition>
                  <v-overlay
                    v-if="hover"
                    absolute
                    color="#9CAF88"
                    opacity="0.75"
                  >
                    <div class="text ibarra" style="width: 200px;">{{ engagement_dates[n-1] }}</div>
                  </v-overlay>
                </v-fade-transition>
                </v-card>
              </template>
          </v-hover>
        </v-col>
      </v-row>
    </div>
    <div v-else>
      <v-row>
        <v-col>
          <v-select
              :items="mobile_items"
              class="ibarra"
              v-model="mobile_selection"
              solo
              item-color="black"
              color="darkgrey"
          >
          </v-select>
        </v-col>
      </v-row>
      <v-row v-if="mobile_selection === 'High School'">
        <v-col
          v-for="n in 9"
          :key="n"
          class="d-flex child-flex"
          cols="4"
        >
          <v-img
            :src="require(`@/assets/${high_school_images[n-1]}`)"
            class="grey lighten-2 mobile-image"
            aspect-ratio="1"
            max-width="125"
          ></v-img>
        </v-col>
      </v-row>
      <v-row v-if="mobile_selection === 'College'">
        <v-col
          v-for="n in 15"
          :key="n"
          class="d-flex child-flex"
          cols="4"
        >
          <v-img
            :src="require(`@/assets/${college_images[n-1]}`)"
            class="grey lighten-2 mobile-image"
            aspect-ratio="1"
          ></v-img>
        </v-col>
      </v-row>
      <v-row v-if="mobile_selection === 'Engagement'">
        <v-col
          v-for="n in 9"
          :key="n"
          class="d-flex child-flex"
          cols="4"
        >
          <v-img
            :src="require(`@/assets/${engagement_images[n-1]}`)"
            class="grey lighten-2 mobile-image"
            aspect-ratio="1"
          ></v-img>
        </v-col>
      </v-row>
    </div>
  </Wrapper>
</template>

<script>
import Wrapper from "@/components/Wrapper";
export default {
  name: "PhotosView",
  data() {
    return {
      mobile_selection: "High School",
      mobile_items: [
          "High School",
          "College",
          "Engagement"
      ],
      high_school_images: [
          "first.jpg",
          "baseball.jpg",
          "kennywood.jpg",
          "look.jpg",
          "halloween.jpg",
          "prom.jpg",
          "hand.jpg",
          "car.jpg",
          "grad.jpg"
      ],
      high_school_dates: [
          "December 30, 2016",
          "April 8, 2017",
          "June 27, 2017",
          "October 7, 2017",
          "October 31, 2017",
          "May 7, 2018",
          "September 29, 2018",
          "May 4, 2019",
          "June 9, 2019"
      ],
      college_images: [
          "beaver.jpg",
          "stack.jpg",
          "doge.jpg",
          "tapestry.jpg",
          "pumpkin.jpg",
          "tech.jpg",
          "lakehouse.jpg",
          "lake.png",
          "osprey.jpg",
          "dentist.jpg",
          "bean.jpg",
          "phyrst.jpg",
          "thon.jpg",
          "dc.jpg",
          "grad2.jpg"
      ],
      college_dates: [
          "September 7, 2019",
          "June 21, 2020",
          "July 14, 2020",
          "August 28, 2020",
          "October 17, 2020",
          "February 19, 2021",
          "April 30, 2021",
          "May 1, 2021",
          "July 15, 2021",
          "October 29, 2021",
          "January 6, 2022",
          "January 28, 2022",
          "February 20, 2022",
          "March 26, 2022",
          "April 28, 2022"
      ],
      engagement_images: [
          "kneel.jpg",
          "gasp.jpg",
          "hug.jpg",
          "finger.jpg",
          "hug2.jpg",
          "finger2.jpg",
          "mitchandhannah1.jpg",
          "close.jpg",
          "snap.jpg"
      ],
      engagement_dates: [
          "June 17, 2022",
          "June 17, 2022",
          "June 17, 2022",
          "June 17, 2022",
          "June 17, 2022",
          "June 17, 2022",
          "June 17, 2022",
          "June 17, 2022",
          "June 17, 2022",
      ],
      popup: false,
      width: 0
    }
  },
  components: {
    Wrapper
  },
  mounted: function() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
    })
    this.onResize()
  },

  computed: {
    /**
     * Returns whether a device is mobile
     */
    isMobile() {
      return this.width < 715
    }
  },

  methods: {
    onResize() {
      this.width = window.innerWidth
    }
  }
}
</script>

<style scoped>
.ibarra {
  font-family: 'Ibarra Real Nova', serif;
  color: dimgray;
}
.home-img {
  width: 90%;
  height: auto;
  object-fit: cover;
  object-position: 43% 57%
}
.location-cards {
  width: 80%;
  margin-bottom: 40px;
}
.txt-large {
  font-size: 40px;
}
.txt-med {
  font-size: 25px;
}
.txt-small {
  font-size: 20px;
}
.txt-x-small {
  font-size: 18px;
}
.bold {
  font-weight: bold;
}
.justify {
  text-align: justify;
  text-justify: inter-word;
}
.img-hover:hover {
  cursor: pointer;
}
.container {
  position: relative;
  width: 50%;
}

.image {
  display: block;
  width: 100%;
  height: auto;
}

.overlay {
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: .5s ease;
  background-color: #9CAF88;
}

.container:hover .overlay {
  opacity: 0.8;
}

.text {
  color: white;
  font-size: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
}
.mobile-image {
  width: 100px;
  height: 100px;
  object-fit: cover;
}
</style>