<template>
  <v-row>
    <v-col align="center">
      <v-row style="max-width: 800px; padding-right: 30px; padding-left: 30px;">
        <v-col align="center">
          <div class="ibarra txt-x-large bold">
            oops...
          </div>
        </v-col>
      </v-row>
      <v-spacer style="height: 50px;"></v-spacer>
      <v-row style="max-width: 800px; padding-right: 30px; padding-left: 30px;">
        <v-col align="center">
          <div class="ibarra txt-large">
            I'm sorry, but your RSVP has already been completed.
            If there is something that you would like to change, please contact the bride or the groom and we will unlock your RSVP for editing.
          </div>
        </v-col>
      </v-row>
      <v-spacer style="height: 70px;"></v-spacer>
      <v-row>
        <v-col align="center">
          <v-btn class="submit ibarra txt-white bold" color="#9CAF88" @click="returnHome">Return Home</v-btn>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "RSVPCompleted",

  methods: {
    returnHome() {
      this.$router.push({ name: 'home' })
    }
  }
}
</script>

<style scoped>
.radio {
  transform: scale(1.5)
}
.float-bottom {
  position: absolute;
  bottom: 0px;
}
.ibarra {
  font-family: 'Ibarra Real Nova', serif;
  color: dimgray;
}
.home-img {
  width: 90%;
  height: auto;
  object-fit: cover;
  object-position: 43% 57%
}
.location-cards {
  width: 80%;
  margin-bottom: 40px;
}
.txt-large {
  font-size: 40px;
}
.txt-x-large {
  font-size: 50px;
}
.txt-med {
  font-size: 25px;
}
.txt-small {
  font-size: 20px;
}
.txt-x-small {
  font-size: 18px;
}
.bold {
  font-weight: bold;
}
.justify {
  text-align: justify;
  text-justify: inter-word;
}
.txt-white {
  color: white;
}
.justify {
  text-align: justify;
  text-justify: inter-word;
}
</style>