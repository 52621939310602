<template>
  <v-row align="center" justify="center">
    <v-col align="center" justify="center">
      <div v-if="open && !submit">
        <div v-if="width > 1000" style="position: absolute;">
          <v-img :style="'margin-left: ' + (width - 1010)/2 + 'px; top: 35px;'" width="1000" :src="require('@/assets/openenvelope.png')"></v-img>
        </div>
      </div>
      <RSVPInfo v-if="invite" :guest_id="guest_id"></RSVPInfo>
      <VueFlip v-if="!open" v-model="flip" :width="cardWidth + 'px'" :height="cardHeight + 'px'" style="margin-top: 50px;">
        <template v-slot:front>
          <v-card
              elevation="8"
              :width="cardWidth"
              :height="cardHeight"
              color="#EFEFEC"
              class="rounded-card"
          >
            <v-row>
              <v-col>
                <v-row style="margin-right: 0px; margin-top: 0px;">
                  <v-col align="right">
                    <v-img :src="require('@/assets/postage.png')" :height="cardHeight * .252" :width="cardHeight * .4" aspect-ratio="1"></v-img>
                  </v-col>
                </v-row>
                <v-row :style="'margin-top: ' + cardHeight * .06 + 'px; width: ' + cardWidth * .8 + 'px;'" >
                  <v-col>
                    <div v-if="width > 1000" class="ibarra txt-large" style="margin-top: 10px;">
                      {{ name }}
                    </div>
                    <div v-else>
                      <div v-if="name.length <= 35" class="ibarra txt-small">
                        {{ name }}
                      </div>
                      <div v-else class="ibarra txt-x-small">
                        {{ name }}
                      </div>
                    </div>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card>
        </template>
        <template v-slot:back>
          <v-img
              class="elevation-8 rounded-card"
              :width="cardWidth"
              :src="require('@/assets/closedenvelope.png')"
          ></v-img>
        </template>
      </VueFlip>
    </v-col>
  </v-row>
</template>

<script>
import VueFlip from 'vue-flip'
import RSVPInfo from "@/views/Wedding/RSVPInfo";

export default {
  name: "RSVPCardTransition",

  data() {
    return {
      width: 0,
      flip: false,
      open: false,
      invite: false,
      submit: false
    }
  },

  components: {
    VueFlip,
    RSVPInfo
  },

  computed: {
    cardWidth() {
      if (this.width >= 800) {
        return 800
      }
      return this.width - 30
    },
    cardHeight() {
      return this.cardWidth * 0.65045
    }
  },

  mounted: function() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
    })
    this.onResize()
    setTimeout(() => {
      this.flip = true
      setTimeout(() => {
        this.open = true
        setTimeout(() => {
          this.invite = true
        }, 400)
      }, 800)
    }, 800)
  },

  methods: {
    onResize() {
      this.width = window.innerWidth
    },
  },

  props: {
    guest_id: Number,
    name: String
  }
}
</script>

<style scoped>
.rounded-card {
  border-radius: 15px;
}
.ibarra {
  font-family: 'Ibarra Real Nova', serif;
  color: dimgray;
}
.txt-large {
  font-size: 40px;
}
.txt-med {
  font-size: 25px;
}
.txt-small {
  font-size: 20px;
}
.txt-x-small {
  font-size: 18px;
}
</style>