<template>
  <v-row>
    <v-col>
      <v-card style="width: 400px;height: 250px;" @click="goToChart" class="card-click">
        <v-row>
          <v-col style="padding-left: 50px; padding-top: 24px;">
            <v-progress-circular
                :value="(spent / budget) * 100"
                :size="200"
                :width="20"
                :color="color"
            >
              <v-icon
                :color="color"
                :size="100"
              >
                {{ icon }}</v-icon>
            </v-progress-circular>
          </v-col>
          <v-col>
            <v-row style="padding-top: 60px;">
              <v-col>
                <div v-if="budget-spent >= 0" class="remaining">
                  <b style="color: green">${{ Number((budget - spent)).toFixed(2) }}</b> remaining
                </div>
                <div v-else class="remaining">
                  <b style="color: red">${{ Number((spent - budget)).toFixed(2) }}</b> over budget
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <div class="spent">
                  <b>${{ Number(spent).toFixed(2) }}</b> spent
                </div>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "BudgetCategoryCard",

  props: {
    budget: Number,
    spent: Number,
    color: String,
    icon: String,
    category: String,
    transactions: Array,
    month: String,
    year: String
  },

  methods: {
    goToChart() {
      this.$router.push({ name: 'budget-chart', params: { category: this.category, color: this.color, budget: this.budget, spent: this.spent, transactions: this.transactions, month: this.month, year: this.year } })
    }
  }
}
</script>

<style scoped>
.remaining {
  font-size: 25px;
}
.card-click:hover {
  cursor: pointer;
}
</style>