<template>
  <Wrapper align="center">
    <v-row justify="center" style="max-width: 800px;">
      <v-col align="center">
        <v-expansion-panels focusable popout>
          <v-expansion-panel
            v-for="item in questions"
            :key="item"
          >
            <v-expansion-panel-header class="header">
              <div class="ibarra txt-med justify-left">
                {{ item.question }}
              </div>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <div style="margin-top: 40px;" class="ibarra txt-small justify">
                {{ item.answer }}
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header class="header">
              <div class="ibarra txt-med justify-left">
                HOW SHOULD WE CONTACT YOU IF WE HAVE MORE QUESTIONS?
              </div>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row>
                <v-col align="left" style="margin-top: 20px;" >
                  <v-btn align="left" class="submit ibarra txt-white bold" color="#9CAF88" @click="goToQuestions">Ask us a question!</v-btn>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>
  </Wrapper>
</template>

<script>
import Wrapper from "@/components/Wrapper";
export default {
  name: "QAView",

  data() {
    return {
      questions: [
        {
          question: "WHEN IS THE RSVP DEADLINE?",
          answer: "The RSVP deadline is September 2nd, 2023. We will be sending out formal invites with information on how to RSVP in June or July!\n"
        },
        {
          question: "CAN I BRING A GUEST/DATE?",
          answer: "Unfortunately, we can only accommodate guests that have been formally invited. If you have received a plus one, they will appear under your name when you RSVP."
        },
        {
          question: "IS THERE A DRESS CODE?",
          answer: "We ask that you wear cocktail or semi-formal attire. Our wedding day will be taking place entirely outside with the exception of the bathroom, bartender, and food, so please dress accordingly.  We ask that you refrain from wearing sage green as that is reserved for the wedding party."
        },
        {
          question: "WHAT IS A PHOTO MINUTE?",
          answer: "We’ve asked a professional photographer to capture this special moment so that you can sit back, relax and enjoy it with us. Our beautiful images will be available after the wedding. But in order for you all to get pictures as well we will be having a Photo Minute. At one point in the ceremony, we will ask you to take your phones out to take pictures, but besides that, please refrain from phone/camera usage."
        },
        {
          question: "CAN I POST PICTURES OF THE WEDDING ON SOCIAL MEDIA?",
          answer: "We love photos! We do ask that you refrain from taking photos during the ceremony, unless it is from our Photo Minute. For the rest of the wedding, we encourage guests to post pictures using our wedding hashtag #MilesAndMilesTogether so we can see them, too!"
        },
        {
          question: "WHAT HAPPENS AFTER THE CEREMONY?",
          answer: "The couple, their families, and the wedding party will be taking pictures on site for about an hour. Guests are welcome to enjoy cocktail hour. We are fortunate enough to have access to the Car and Carriage Museum from 5:30-6:30pm, as well as access to the Greenhouse and the outdoor grounds of the venue! So please feel free to see what The Frick has to offer!"
        },
        {
          question: "WHAT ALL IS TAKING PLACE OUTDOORS?",
          answer: "The ceremony and reception spaces are outdoors, but there is a room located inside with climate control during the reception."
        },
        {
          question: "WHERE SHOULD I PARK?",
          answer: "There are 75 parking spots on site, as well as 5 handicapped spaces. If that becomes full there is street parking along the street."
        },
        {
          question: "IS THE VENUE HANDICAP ACCESSIBLE?",
          answer: "Yes, both the ceremony and reception are handicap accessible. The bathrooms are as well."
        },
        {
          question: "WHAT TIME WILL DINNER BE SERVED?",
          answer: "TBD"
        },
        {
          question: "IS THE AFTER PARTY AN OFFICIAL EVENT?",
          answer: "No, the after party is not an official event. We currently do not have the space rented out. This is an optional event for anyone who wants to join us at a bar afterwards."
        }
      ]
    }
  },
  components: {
    Wrapper
  },

  methods: {
    goToQuestions() {
      this.$router.push({ name: 'questions' })
    }
  }
}
</script>

<style scoped>
.radio {
  transform: scale(1.5)
}
.float-bottom {
  position: absolute;
  bottom: 0px;
}
.ibarra {
  font-family: 'Ibarra Real Nova', serif;
  color: dimgray;
}
.home-img {
  width: 90%;
  height: auto;
  object-fit: cover;
  object-position: 43% 57%
}
.location-cards {
  width: 80%;
  margin-bottom: 40px;
}
.txt-large {
  font-size: 40px;
}
.txt-med {
  font-size: 20px;
  font-weight: bold;
}
.txt-small {
  font-size: 18px;
  margin-bottom: 50px;
}
.txt-x-small {
  font-size: 18px;
}
.bold {
  font-weight: bold;
}
.justify {
  text-align: justify;
  text-justify: inter-word;
}
.justify-left {
  text-align: left;
}
.txt-white {
  color: white;
}
.header {
  color: #478a00;
}
</style>