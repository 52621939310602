<template>
  <v-row>
    <v-col align="center">
      <v-row style="margin-top: 50px; margin-bottom: 30px;">
        <v-col style="margin-top: 10px;">
          <v-icon x-large @click="goToLocation('budget')">
            mdi-arrow-left
          </v-icon>
        </v-col>
        <v-col align="center">
          <div class="ibarra txt-large">
            Purchase Input
          </div>
        </v-col>
        <v-col>
        </v-col>
      </v-row>

      <v-row style="margin-top: -40px;">
        <v-col>
          <v-btn
              outlined
              v-for="(item, index) in quick_buttons_data"
              v-bind:key="index"
              style="margin: 5px;"
              :color="item.color"
              v-on:click="fillInData(index)"
          >
            {{ item.location }}
          </v-btn>
        </v-col>
      </v-row>

      <v-row style="max-width: 500px; padding-left: 50px; padding-right: 50px;">
        <v-col>

          <v-row>
            <v-col align="left">
              <div class="ibarra txt-med">
                <b v-if="name_error" style="color: red">*</b>Name:
              </div>
            </v-col>
            <v-col align="right">
              <v-text-field
                  outlined
                  v-model="name"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col align="left">
              <div class="ibarra txt-med">
                <b v-if="date_error" style="color: red">*</b>Date:
              </div>
            </v-col>
            <v-col align="right">
              <v-menu
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                :return-value.sync="date"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="date"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="date"
                  no-title
                  scrollable
                  color="blue"
                >
                  <v-spacer></v-spacer>
                  <v-btn
                    text
                    color="primary"
                    @click="menu = false"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.menu.save(date)"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
          </v-row>

          <v-row>
            <v-col align="left">
              <div class="ibarra txt-med">
                <b v-if="category_error" style="color: red">*</b>Category:
              </div>
            </v-col>
            <v-col align="right">
              <v-select
                  :items="category_items"
                  v-model="category"
                  outlined
              >
              </v-select>
            </v-col>
          </v-row>

          <v-row>
            <v-col align="left">
              <div class="ibarra txt-med">
                <b v-if="location_error" style="color: red">*</b>Location:
              </div>
            </v-col>
            <v-col align="right">
              <v-text-field
                  outlined
                  v-model="location"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col align="left">
              <div class="ibarra txt-med">
                <b v-if="price_error" style="color: red">*</b>Price:
              </div>
            </v-col>
            <v-col align="right">
              <v-text-field
                  id="price_input"
                  refs="price"
                  outlined
                  prefix="$"
                  v-model="price"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col align="left">
              <div class="ibarra txt-med">
                <b v-if="num_months_error" style="color: red">*</b>Months:
              </div>
            </v-col>
            <v-col align="right">
              <v-text-field
                  outlined
                  type="number"
                  v-model="num_months"
                  :disabled="disable_months"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col align="left">
              <div class="ibarra txt-med">
                Yearly:
              </div>
            </v-col>
            <v-col align="right" style="margin-top: -7px;">
              <v-switch v-model="vacation"></v-switch>
            </v-col>
          </v-row>

        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-btn
                style="height: 70px; width: 100px;"
                class="submit ibarra txt-white bold"
                color="green"
                @click="submitData"
            >
              <v-icon x-large>
                mdi-check-outline
              </v-icon>
            </v-btn>
        </v-col>
      </v-row>

    </v-col>
  </v-row>
</template>

<script>
import * as api from './LoadingAndSavingAPIs'

export default {
  name: "BudgetInput",

  watch: {
    vacation(val) {
      if (val) {
        this.num_months = 1
        this.disable_months = true
        this.category = ''
        this.category_items = api.getAllCategories(true)
      } else {
        this.disable_months = false
        this.category = ''
        this.category_items = api.getAllCategories(false)
      }
    }
  },

  data() {
    return {
      name: '',
      category: '',
      location: '',
      price: 0,
      date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      vacation: false,
      num_months: 1,
      menu: false,
      category_items: [],
      name_error: false,
      date_error: false,
      category_error: false,
      location_error: false,
      price_error: false,
      num_months_error: false,
      disable_months: false,
      quick_buttons_data: [
        // {
        //   location: "Aldi",
        //   color: "blue",
        //   category: "Grocery",
        //   name: "Groceries"
        // },
        // {
        //   location: "Costco",
        //   color: "green",
        //   category: "Grocery",
        //   name: "Groceries"
        // }
      ]
    }
  },

  mounted: function() {
    if (this.locked) {
      this.$router.push({ name: 'budget' })
    }
    this.category_items = api.getAllCategories()
    this.quick_buttons_data = api.loadQuickButtons()
  },

  props: {
    locked: {
      type: Boolean,
      default: true
    }
  },

  methods: {
    fillInData(index) {
      this.name = this.quick_buttons_data[index].name
      this.location = this.quick_buttons_data[index].location
      this.category = this.quick_buttons_data[index].category
      this.price = this.quick_buttons_data[index].price
      document.getElementById("price_input").focus()
    },
    submitData() {
      if (this.validateData()) {
        api.saveTransaction(this.name, this.date, this.category, this.location, this.price, this.num_months, this.vacation)
        this.name = ''
        this.date = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
        this.category = ''
        this.location = ''
        this.price = 0
        this.num_months = 1
      }
    },
    validateData() {
      let clean = true
      if (this.name === '') {
        clean = false
        this.name_error = true
      } else { this.name_error = false }
      if (this.date === '') {
        clean = false
        this.date_error = true
      } else { this.date_error = false }
      if (this.category === '') {
        clean = false
        this.category_error = true
      } else { this.category_error = false }
      if (this.location === '') {
        clean = false
        this.location_error = true
      } else { this.location_error = false }
      if (this.price <= 0) {
        clean = false
        this.price_error = true
      } else { this.price_error = false }
      if (this.num_months <= 0) {
        clean = false
        this.num_months_error = true
      }
      return clean
    },
    goToLocation(location) {
      this.$router.push({ name: location, params: { locked: false } })
    }
  }
}
</script>

<style scoped>
.txt-white {
  color: white;
}
</style>