import Vue from 'vue'
import VueRouter from 'vue-router'
import OurStoryView from '@/views/Wedding/OurStoryView'
import PhotosView from '@/views/Wedding/PhotosView'
import WeddingPartyView from '@/views/Wedding/WeddingPartyView'
import QAView from '@/views/Wedding/QAView'
import TravelView from '@/views/Wedding/TravelView'
import RegistryView from '@/views/Wedding/RegistryView'
import RSVPView from '@/views/Wedding/RSVPView'
import RSVPResults from '@/views/Wedding/RSVPResults'
import RSVPInfo from '@/views/Wedding/RSVPInfo'
import HanAndChloe from '@/views/Challenges/HanAndChloe'
import Day3 from '@/views/Challenges/Day3'
import Questions from '@/views/Wedding/Questions'
import RSVPCompleted from '@/views/Wedding/RSVPCompleted'
import RSVPSummary from '@/views/Wedding/RSVPSummary'
import HomeView from "@/views/Wedding/HomeView";
import Challenges from "@/views/Challenges/Challenges";
import BudgetView from "@/views/Budget/BudgetView";
// import BudgetData from "@/views/Budget/BudgetData";
import BudgetInput from "@/views/Budget/BudgetInput";
import BudgetGraph from "@/views/Budget/BudgetGraph";
import RSVPMeme from "@/views/Wedding/RSVPMeme";
import BudgetBuilder from "@/views/Budget/BudgetBuilder";
import SplashScreen from "@/views/Wedding/SplashScreen";
import RSVPCardTransition from "@/views/Wedding/RSVPCardTransition";
import RSVPText from "@/views/Wedding/RSVPText";
import House from "@/views/Budget/House";
import OverallBudgetView from "@/views/Budget/OverallBudgetView";


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'splash',
    component: SplashScreen,
    meta: { title: 'Home' }
  },
  {
    path: '/home',
    name: 'home',
    component: HomeView,
    meta: { title: 'Home' }
  },
  {
    path: '/our-story',
    name: 'our-story',
    component: OurStoryView,
    meta: { title: 'Our Story' }
  },
  {
    path: '/photos',
    name: 'photos',
    component: PhotosView,
    meta: { title: 'Photos' }
  },
  {
    path: '/wedding-party',
    name: 'wedding-party',
    component: WeddingPartyView,
    meta: { title: 'Wedding Party' }
  },
  {
    path: '/qa',
    name: 'qa',
    component: QAView,
    meta: { title: 'Q+A' }
  },
  {
    path: '/travel',
    name: 'travel',
    component: TravelView,
    meta: { title: 'Travel' }
  },
  {
    path: '/registry',
    name: 'registry',
    component: RegistryView,
    meta: { title: 'Registry' }
  },
  {
    path: '/rsvp',
    name: 'rsvp',
    component: RSVPView,
    props: true,
    meta: { title: 'RSVP' }
  },
  {
    path: '/rsvp',
    name: 'rsvp-results',
    component: RSVPResults,
    props: true,
    meta: { title: 'RSVP' }
  },
  {
    path: '/rsvp',
    name: 'rsvp-info',
    component: RSVPInfo,
    props: true,
    meta: { title: 'RSVP' }
  },
  {
    path: '/rsvp',
    name: 'rsvp-completed',
    component: RSVPCompleted,
    meta: { title: 'RSVP'}
  },
  {
    path: '/rsvp',
    name: 'rsvp-card-transition',
    component: RSVPCardTransition,
    props: true,
    meta: { title: 'RSVP' }
  },
  {
    path: '/selection',
    name: 'han-and-chloe',
    component: HanAndChloe,
    meta: { title: 'My Two Girls' }
  },
  {
    path: '/hannah',
    name: 'day3',
    component: Day3,
    props: true,
    meta: { title: 'Hannah' }
  },
  {
    path: '/questions',
    name: 'questions',
    component: Questions,
    meta: { title: 'Questions' }
  },
  {
    path: '/rsvp-summary',
    name: 'rsvp-summary',
    component: RSVPSummary,
    meta: { title: 'RSVP Summary' }
  },
  {
    path: '/challenges',
    name: 'challenges',
    component: Challenges,
    props: true,
    meta: { title: 'Challenges' }
  },
  {
    path: '/budget',
    name: 'budget',
    component: BudgetView,
    props: true,
    meta: { title: 'Budget' }
  },
  {
    path: '/budget-data',
    name: 'budget-data',
    component: OverallBudgetView,
    props: true,
    meta: { title: 'Budget' }
  },
  {
    path: '/budget-input',
    name: 'budget-input',
    component: BudgetInput,
    props: true,
    meta: { title: 'Budget Input' }
  },
  {
    path: '/budget-chart',
    name: 'budget-chart',
    component: BudgetGraph,
    props: true,
    meta: { title: 'Budget' }
  },
  {
    path: '/good-try',
    name: 'rsvp-meme',
    component: RSVPMeme,
    meta: { title: 'Good Try' }
  },
  {
    path: '/rsvp-text',
    name: 'rsvp-text',
    component: RSVPText,
    meta: { title: 'RSVP' }
  },
  {
    path: '/budget-builder',
    name: 'budget-builder',
    component: BudgetBuilder,
    props: true,
    meta: { title: 'Budget' }
  },
  {
    path: '/house',
    name: 'house',
    component: House,
    props: true,
    meta: { title: 'House' }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

// Cause the page title to update
router.afterEach((to) => {
  Vue.nextTick(() => {
    document.title = to.meta.title
  })
})

export default router
