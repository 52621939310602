<template>
  <v-row style="margin-top: 30px;">
    <v-col>
      <v-row>
        <v-col align="center">
          <div class="ibarra txt-large">
            RSVP
          </div>
        </v-col>
      </v-row>
      <form style="margin-top: 50px;">
        <v-row style="margin-bottom: 20px;">
          <v-col align="center">
            <div class="ibarra txt-large">
              Select your invite
            </div>
          </v-col>
        </v-row>
        <v-row v-if="loading" justify="center">
          <v-col align="center">
            <v-progress-circular
                :size="50"
                color="#9CAF88"
                indeterminate
            ></v-progress-circular>
          </v-col>
        </v-row>
        <v-row v-else justify="center">
          <v-col align="center" style="max-width: 700px;">
            <v-row v-for="index in Math.floor(Object.keys(this.guests).length/2)" v-bind:key="index">
              <v-col>
                <v-card
                    class="rounded-card grow invite"
                    color="#EFEFEC"
                    :id="'invite' + Object.keys(guests)[index + (index - 2)]"
                    elevation="8"
                    ripple
                    width="300"
                    height="200"
                    @click="transitionCard(Object.keys(guests)[index + (index - 2)], guests[Object.keys(guests)[index + (index - 2)]])"
                >
                  <v-row>
                    <v-col>
                      <v-row style="margin-right: 0px;">
                        <v-col align="right">
                          <v-img :src="require('@/assets/postage.png')" height="50" width="80" aspect-ratio="1"></v-img>
                        </v-col>
                      </v-row>
                      <v-row style="margin-top: -10px; width: 200px;">
                        <v-col>
                          <div v-if="guests[Object.keys(guests)[index + (index - 2)]].length <= 35" class="ibarra txt-small">
                            {{ guests[Object.keys(guests)[index + (index - 2)]] }}
                          </div>
                          <div v-else class="ibarra txt-x-small">
                            {{ guests[Object.keys(guests)[index + (index - 2)]] }}
                          </div>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-card>
              </v-col>
              <v-col>
                <v-card
                    class="rounded-card grow invite"
                    color="#EFEFEC"
                    :id="'invite' + Object.keys(guests)[index + (index - 1)]"
                    elevation="8"
                    ripple
                    width="300"
                    height="200"
                    @click="transitionCard(Object.keys(guests)[index + (index - 1)], guests[Object.keys(guests)[index + (index - 1)]])"
                >
                  <v-row>
                    <v-col>
                      <v-row style="margin-right: 0px;">
                        <v-col align="right">
                          <v-img :src="require('@/assets/postage.png')" height="50" width="80" aspect-ratio="1"></v-img>
                        </v-col>
                      </v-row>
                      <v-row style="margin-top: -10px; width: 200px;">
                        <v-col>
                          <div v-if="guests[Object.keys(guests)[index + (index - 1)]].length <= 35" class="ibarra txt-small">
                            {{ guests[Object.keys(guests)[index + (index - 1)]] }}
                          </div>
                          <div v-else class="ibarra txt-x-small">
                            {{ guests[Object.keys(guests)[index + (index - 1)]] }}
                          </div>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-card>
              </v-col>
            </v-row>
            <v-row v-if="Object.keys(this.guests).length % 2 === 1">
              <v-col>
                <v-card
                    class="rounded-card grow invite"
                    color="#EFEFEC"
                    :id="'invite' + Object.keys(guests)[Object.keys(guests).length - 1]"
                    elevation="8"
                    ripple
                    width="300"
                    height="200"
                    @click="transitionCard(Object.keys(guests)[Object.keys(guests).length - 1], guests[Object.keys(guests)[Object.keys(guests).length - 1]])"
                >
                  <v-row>
                    <v-col>
                      <v-row style="margin-right: 0px;">
                        <v-col align="right">
                          <v-img :src="require('@/assets/postage.png')" height="50" width="80" aspect-ratio="1"></v-img>
                        </v-col>
                      </v-row>
                      <v-row style="margin-top: -10px; width: 200px;">
                        <v-col>
                          <div v-if="guests[Object.keys(guests)[Object.keys(guests).length - 1]].length <= 35" class="ibarra txt-small">
                            {{ guests[Object.keys(guests)[Object.keys(guests).length - 1]] }}
                          </div>
                          <div v-else class="ibarra txt-x-small">
                            {{ guests[Object.keys(guests)[Object.keys(guests).length - 1]] }}
                          </div>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </form>
    </v-col>
  </v-row>
</template>

<script>
import { ref, getDatabase, child, get } from 'firebase/database'
const dbRef = ref(getDatabase())

export default {
  name: "RSVPResultsView",

  data() {
    return {
      guests: {},
      selected: '',
      loading: true,
      lock: false
    }
  },

  props: {
    search: String
  },

  computed: {
    cardWidth() {
      if (this.width >= 800) {
        return 800
      }
      return this.width - 30
    },
    cardHeight() {
      return this.cardWidth * 0.65045
    }
  },

  mounted() {
    this.getGuests()
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
    })
    this.onResize()
  },

  methods: {
    onResize() {
      this.width = window.innerWidth
    },
    transitionCard(index, name) {
      this.checkCompletedAndSubmitGuestName(index, name)
    },
    getGuests() {
      get(child(dbRef, 'guests/count')).then((snapshot) => {
        if (snapshot.exists()) {
          const ct = snapshot.val()
          let count = 0 // the count of the overall number of guest groups
          let count2 = 0 // the count of each set of guests within a guest group
          for (let x = 1; x <= ct; x++) {
            const num = x
            get(child(dbRef, `guests/${num}/void`)).then((snapshot) => {
              if (!(snapshot.exists() && snapshot.val())) {
                get(child(dbRef, `guests/${num}/name`)).then((snapshot) => {
                  if (snapshot.exists()) {
                    const data = snapshot.val()
                    if (this.stringContains(data, this.search)) {
                      this.guests[num] = data
                    }
                    get(child(dbRef, `guests/${num}/count`)).then((snapshot) => {
                      if (snapshot.exists()) {
                        const ct2 = snapshot.val()
                        for (let y = 1; y <= ct2; y++) {
                          const num2 = y
                          get(child(dbRef, `guests/${num}/extras/${num2}/name`)).then((snapshot) => {
                            if (snapshot.exists()) {
                              const data2 = snapshot.val()
                              if (this.stringContains(data2, this.search)) {
                                this.guests[num] = data
                              }
                              count2 += 1
                              if (count2 === ct2) {
                                count += 1
                                count2 = 0
                              }
                              if (count === ct) {
                                if (Object.keys(this.guests).length === 0) {
                                  this.$router.push({ name: 'rsvp', params: { error: "I'm sorry, but you are not showing up on the guest list. If you think there is an error, please notify the bride or the groom." } })
                                } else {
                                  this.loading = false
                                }
                              }
                            }
                          })
                        }
                      }
                    })
                  }
                })
              }
              else {
                count += 1
                if (count === ct) {
                  if (Object.keys(this.guests).length === 0) {
                    this.$router.push({ name: 'rsvp', params: { error: "I'm sorry, but you are not showing up on the guest list. If you think there is an error, please notify the bride or the groom." } })
                  } else {
                    this.loading = false
                  }
                }
              }
            })
          }
        }
      })
    },

    stringContains(str, subStr) {
      return str.toUpperCase().includes(subStr.toUpperCase())
    },

    submitGuestName(index, name) {
      this.$router.push({ name: 'rsvp-card-transition', params: { guest_id: parseInt(index), name: name } })
    },

    checkCompletedAndSubmitGuestName(index, name) {
      get(child(dbRef, `guests/${index}/completed`)).then((snapshot) => {
        const completed = snapshot.val()
        if (completed) {
          this.$router.push({ name: 'rsvp-completed' })
        } else {
          this.submitGuestName(index, name)
        }
      })
    },
  }
}
</script>

<style scoped>
.radio {
  transform: scale(1.5)
}
.ibarra {
  font-family: 'Ibarra Real Nova', serif;
  color: dimgray;
}
.home-img {
  width: 90%;
  height: auto;
  object-fit: cover;
  object-position: 43% 57%
}
.location-cards {
  width: 80%;
  margin-bottom: 40px;
}
.txt-large {
  font-size: 40px;
}
.txt-med {
  font-size: 25px;
}
.txt-small {
  font-size: 20px;
}
.txt-x-small {
  font-size: 18px;
}
.bold {
  font-weight: bold;
}
.justify {
  text-align: justify;
  text-justify: inter-word;
}
.txt-white {
  color: white;
}
.rounded-card {
  border-radius: 8px;
}
.grow { transition: all .2s ease-in-out; }
.grow:hover { transform: scale(1.05); }
</style>