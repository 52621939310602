<template>
  <Wrapper>
<!--    <v-row justify="center">-->
<!--      <v-col align="center" style="max-width: 700px">-->
<!--        <div class="ibarra txt-med">-->
<!--           Wedding party coming soon!-->
<!--        </div>-->
<!--      </v-col>-->
<!--    </v-row>-->
<!--    <v-row>-->
<!--      <v-col>-->
<!--        <v-switch-->
<!--            -->
<!--        >-->
<!--          -->
<!--        </v-switch>-->
<!--      </v-col>-->
<!--    </v-row>-->
    <v-row>
      <v-col>
        <v-row justify="center">
          <v-col align="center" style="max-width: 700px">
            <div class="ibarra txt-large">
              Maid of Honor
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col align="center">
            <v-divider style="max-width: 400px;"></v-divider>
          </v-col>
        </v-row>

        <WeddingPartyImage
            :image="'kiera.jpg'"
            :name="'Kiera'"
            :bio="'Friend of the bride.'"
        ></WeddingPartyImage>

        <v-row justify="center" style="margin-top: 30px;">
          <v-col align="center" style="max-width: 700px">
            <div class="ibarra txt-large">
              Bridesmaids
            </div>
          </v-col>
        </v-row>

        <v-row>
          <v-col align="center">
            <v-divider style="max-width: 400px;"></v-divider>
          </v-col>
        </v-row>

        <WeddingPartyImage
            :image="'hanna.jpg'"
            :name="'Hanna'"
            :bio="'Friend of the bride.'"
            style="margin-top: 30px;"
        ></WeddingPartyImage>

        <WeddingPartyImage
            :image="'sophia.jpg'"
            :name="'Sophia'"
            :bio="'Friend of the bride.'"
            style="margin-top: 30px;"
        ></WeddingPartyImage>

        <WeddingPartyImage
            :image="'laura.jpg'"
            :name="'Laura'"
            :bio="'Friend of the bride.'"
            style="margin-top: 30px;"
        ></WeddingPartyImage>

        <WeddingPartyImage
            :image="'emily.jpg'"
            :name="'Emily'"
            :bio="'Cousin of the bride.'"
        ></WeddingPartyImage>

        <WeddingPartyImage
            :image="'rachel.jpg'"
            :name="'Rachel'"
            :bio="'Sister of the groom.'"
            style="margin-top: 30px;"
        ></WeddingPartyImage>
      </v-col>
      <v-col>
        <v-row justify="center">
          <v-col align="center" style="max-width: 700px">
            <div class="ibarra txt-large">
              Best Man
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col align="center">
            <v-divider style="max-width: 400px;"></v-divider>
          </v-col>
        </v-row>

        <WeddingPartyImage
            :image="'alex.jpg'"
            :name="'Alex'"
            :bio="'Brother of the groom.'"
        ></WeddingPartyImage>

        <v-row justify="center" style="margin-top: 30px;">
          <v-col align="center" style="max-width: 700px">
            <div class="ibarra txt-large">
              Groomsmen
            </div>
          </v-col>
        </v-row>

        <v-row>
          <v-col align="center">
            <v-divider style="max-width: 400px;"></v-divider>
          </v-col>
        </v-row>

        <WeddingPartyImage
            :image="'jonathan.jpg'"
            :name="'Jonathan'"
            :bio="'Friend of the groom.'"
            style="margin-top: 30px;"
        ></WeddingPartyImage>

        <WeddingPartyImage
            :image="'robbie.jpg'"
            :name="'Robbie'"
            :bio="'Friend of the groom.'"
            style="margin-top: 30px;"
        ></WeddingPartyImage>

        <WeddingPartyImage
            :image="'conor.jpg'"
            :name="'Conor'"
            :bio="'Friend of the groom.'"
        ></WeddingPartyImage>

        <WeddingPartyImage
            :image="'dylan.jpg'"
            :name="'Dylan'"
            :bio="'Brother of the bride.'"
            style="margin-top: 30px;"
        ></WeddingPartyImage>

        <WeddingPartyImage
            :image="'ethan.jpg'"
            :name="'Ethan'"
            :bio="'Brother of the bride.'"
            style="margin-top: 30px;"
        ></WeddingPartyImage>
      </v-col>
    </v-row>

    <v-row>
      <v-col align="center">
        <v-divider></v-divider>
      </v-col>
    </v-row>

    <v-row justify="center">
      <v-col align="center" style="max-width: 700px">
        <div class="ibarra txt-large">
          Flower Girl
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col align="center">
        <v-divider style="max-width: 400px;"></v-divider>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <WeddingPartyImage
          :image="'tinsley.jpg'"
          :name="'Tinsley'"
          :bio="'Cousin of the groom.'"
        ></WeddingPartyImage>
      </v-col>
    </v-row>



  </Wrapper>
</template>

<script>
import Wrapper from "@/components/Wrapper";
import WeddingPartyImage from "@/components/WeddingPartyImage";
export default {
  name: "WeddingPartyView",
  data() {
    return {
      tab: 0,
      bride: true
    }
  },
  components: {
    Wrapper,
    WeddingPartyImage
  }
}
</script>

<style scoped>
.ibarra {
  font-family: 'Ibarra Real Nova', serif;
  color: dimgray;
}
.home-img {
  width: 90%;
  height: auto;
  object-fit: cover;
  object-position: 43% 57%
}
.location-cards {
  width: 80%;
  margin-bottom: 40px;
}
.txt-large {
  font-size: 40px;
}
.txt-med {
  font-size: 25px;
}
.txt-small {
  font-size: 20px;
}
.txt-x-small {
  font-size: 18px;
}
.bold {
  font-weight: bold;
}
.justify {
  text-align: justify;
  text-justify: inter-word;
}
</style>