<template>
  <v-row justify="center" align="center">
    <v-col align="center" justify="center">
      <v-row>
        <v-col cols="12" sm="1" style="margin-top: 20px;">
          <v-icon x-large @click="goToLocation('budget-data')">
            mdi-arrow-left
          </v-icon>
        </v-col>
        <v-col align="left" style="padding-left: 50px;">
          <div style="font-size: 40pt">
            Home Buying Scenarios
          </div>
        </v-col>
        <v-col>
          <v-btn
              style="margin-top: 20px; color: white"
              @click="resetPrices()"
              color="blue"
          >
            Reset
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-row>
            <v-col style="font-size: 24pt;">
              Estimated Savings Target
            </v-col>
          </v-row>
          <v-row>
            <v-col style="font-weight: bold; font-size: 30pt">
              ${{savings_target}}
            </v-col>
          </v-row>
        </v-col>
        <v-col>
          <v-row>
            <v-col style="font-size: 24pt;">
              Estimated Months Until Purchase
            </v-col>
          </v-row>
          <v-row>
            <v-col style="font-weight: bold; font-size: 30pt">
              {{ months_to_go }}
            </v-col>
          </v-row>
        </v-col>
        <v-col>
          <v-row>
            <v-col style="font-size: 24pt;">
              Total Savings at {{ savings_months }} months
            </v-col>
          </v-row>
          <v-row>
            <v-col style="font-weight: bold; font-size: 30pt">
              ${{ total_savings }}
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row style="width: 500px">
        <v-col>
          <v-slider
            v-model="house_price"
            :min="150"
            :max="900"
            :step="10"
            label="House Price:"
            hide-details
            class="ma-4"
          >
            <template v-slot:append>
              <v-text-field
                  v-model="house_price"
                  type="number"
                  style="width: 70px; margin-top: -20px;"
                  density="compact"
                  hide-details
                  variant="outlined"
                  prefix="$"
                  suffix="k"
              ></v-text-field>
            </template>
          </v-slider>
        </v-col>
      </v-row>
      <v-row style="width: 500px">
        <v-col>
          <v-slider
            v-model="rent_price_1"
            :min="1000"
            :max="3000"
            :step="50"
            label="Rent Price 1:"
            hide-details
            class="ma-4"
          >
            <template v-slot:append>
              <v-text-field
                  v-model="rent_price_1"
                  type="number"
                  style="width: 70px; margin-top: -20px;"
                  density="compact"
                  hide-details
                  variant="outlined"
                  prefix="$"
              ></v-text-field>
            </template>
          </v-slider>
        </v-col>
      </v-row>
      <v-row style="width: 500px">
        <v-col>
          <v-slider
            v-model="rent_price_2"
            :min="1000"
            :max="3000"
            :step="50"
            label="Rent Price 2:"
            hide-details
            class="ma-4"
          >
            <template v-slot:append>
              <v-text-field
                  v-model="rent_price_2"
                  type="number"
                  style="width: 70px; margin-top: -20px;"
                  density="compact"
                  hide-details
                  variant="outlined"
                  prefix="$"
              ></v-text-field>
            </template>
          </v-slider>
        </v-col>
      </v-row>
      <v-row style="width: 500px">
        <v-col>
          <v-slider
            v-model="months_rent_1"
            :min="0"
            :max="24"
            :step="1"
            label="Months Staying in Rental 1:"
            hide-details
            class="ma-4"
          >
            <template v-slot:append>
              <v-text-field
                  v-model="months_rent_1"
                  type="number"
                  style="width: 70px; margin-top: -20px;"
                  density="compact"
                  hide-details
                  variant="outlined"
                  suffix="mo."
              ></v-text-field>
            </template>
          </v-slider>
        </v-col>
      </v-row>
      <v-row style="width: 500px">
        <v-col>
          <v-slider
            v-model="income"
            :min="5000"
            :max="12000"
            :step="100"
            label="Monthly Income:"
            hide-details
            class="ma-4"
          >
            <template v-slot:append>
              <v-text-field
                  v-model="income"
                  type="number"
                  style="width: 80px; margin-top: -20px;"
                  density="compact"
                  hide-details
                  variant="outlined"
                  prefix="$"
              ></v-text-field>
            </template>
          </v-slider>
        </v-col>
      </v-row>
      <v-row style="width: 500px">
        <v-col>
          <v-slider
            v-model="expenses"
            :min="0"
            :max="10000"
            :step="100"
            label="Monthly Expenses (Not Rent):"
            hide-details
            class="ma-4"
          >
            <template v-slot:append>
              <v-text-field
                  v-model="expenses"
                  type="number"
                  style="width: 80px; margin-top: -20px;"
                  density="compact"
                  hide-details
                  variant="outlined"
                  prefix="$"
              ></v-text-field>
            </template>
          </v-slider>
        </v-col>
      </v-row>
      <v-row style="width: 500px">
        <v-col>
          <v-slider
            v-model="current_money"
            :min="20000"
            :max="90000"
            :step="1000"
            label="Current House Savings:"
            hide-details
            class="ma-4"
          >
            <template v-slot:append>
              <v-text-field
                  v-model="current_money"
                  type="number"
                  style="width: 80px; margin-top: -20px;"
                  density="compact"
                  hide-details
                  variant="outlined"
                  prefix="$"
              ></v-text-field>
            </template>
          </v-slider>
        </v-col>
      </v-row>
<!--      <v-row style="width: 500px">-->
<!--        <v-col>-->
<!--          <v-slider-->
<!--            v-model="savings_interest"-->
<!--            :min="0"-->
<!--            :max="15"-->
<!--            :step="0.5"-->
<!--            label="Savings Interest Rate:"-->
<!--            hide-details-->
<!--            class="ma-4"-->
<!--          >-->
<!--            <template v-slot:append>-->
<!--              <v-text-field-->
<!--                  v-model="savings_interest"-->
<!--                  type="number"-->
<!--                  style="width: 80px; margin-top: -20px;"-->
<!--                  density="compact"-->
<!--                  hide-details-->
<!--                  variant="outlined"-->
<!--                  suffix="%"-->
<!--              ></v-text-field>-->
<!--            </template>-->
<!--          </v-slider>-->
<!--        </v-col>-->
<!--      </v-row>-->
      <v-row style="width: 500px">
        <v-col>
          <v-slider
            v-model="target_mortgage"
            :min="2000"
            :max="5000"
            :step="100"
            label="Target Mortgage:"
            hide-details
            class="ma-4"
          >
            <template v-slot:append>
              <v-text-field
                  v-model="target_mortgage"
                  type="number"
                  style="width: 80px; margin-top: -20px;"
                  density="compact"
                  hide-details
                  variant="outlined"
                  prefix="$"
              ></v-text-field>
            </template>
          </v-slider>
        </v-col>
      </v-row>
      <v-row style="width: 500px">
        <v-col>
          <v-slider
            v-model="mortgage_interest"
            :min="0"
            :max="15"
            :step="0.5"
            label="Mortgage Interest Rate:"
            hide-details
            class="ma-4"
          >
            <template v-slot:append>
              <v-text-field
                  v-model="mortgage_interest"
                  type="number"
                  style="width: 80px; margin-top: -20px;"
                  density="compact"
                  hide-details
                  variant="outlined"
                  suffix="%"
              ></v-text-field>
            </template>
          </v-slider>
        </v-col>
      </v-row>
      <v-row style="width: 500px">
        <v-col>
          <v-slider
            v-model="savings_months"
            :min="0"
            :max="24"
            :step="1"
            label="Savings at End of x months:"
            hide-details
            class="ma-4"
          >
            <template v-slot:append>
              <v-text-field
                  v-model="savings_months"
                  type="number"
                  style="width: 80px; margin-top: -20px;"
                  density="compact"
                  hide-details
                  variant="outlined"
                  suffix="mo."
              ></v-text-field>
            </template>
          </v-slider>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>


<script>
export default {
  name: "HouseBudget  ",

  data() {
    return {
      house_price: 400,
      rent_price_1: 1250,
      rent_price_2: 2500,
      months_rent_1: 2,
      income: 8500,
      expenses: 3200,
      current_money: 35000,
      target_mortgage: 3200,
      savings_interest: 4.5,
      mortgage_interest: 7,
      savings_target: 0,
      months_to_go: 0,
      total_savings: 0,
      savings_months: 12
    }
  },

  mounted: function() { // 2588 w/ dog and 2 parking spots
    // if (this.locked) {
    //   this.$router.push({name: 'budget'})
    // }
    this.calculatePayments()
  },

  watch: {
    house_price() {
      this.calculatePayments()
    },
    rent_price_1() {
      this.calculatePayments()
    },
    rent_price_2() {
      this.calculatePayments()
    },
    months_rent_1() {
      this.calculatePayments()
    },
    income() {
      this.calculatePayments()
    },
    expenses() {
      this.calculatePayments()
    },
    current_money() {
      this.calculatePayments()
    },
    target_mortgage() {
      this.calculatePayments()
    },
    savings_interest() {
      this.calculatePayments()
    },
    mortgage_interest() {
      this.calculatePayments()
    },
    savings_months() {
      this.calculatePayments()
    }
  },

  props: {
    locked: {
      type: Boolean,
      default: true
    }
  },

  methods: {

    goToLocation(location) {
      this.$router.push({name: location, params: {locked: false}})
    },

    resetPrices() {
      this.house_price = 400
      this.rent_price_1 = 1250
      this.rent_price_2 = 2500
      this.months_rent_1 = 2
      this.income = 8500
      this.expenses = 3200
      this.current_money = 35000
      this.target_mortgage = 3200
      this.savings_interest = 4.5
      this.mortgage_interest = 7
    },

    calculatePayments() {
      // M = P * [ I(1 + I)^N ] / [ (1 + I)^N − 1]
      let numerator = (this.mortgage_interest / 1200) * ((1 + (this.mortgage_interest / 1200)) ** 360)
      let denominator = ((1 + (this.mortgage_interest / 1200)) ** 360) - 1

      var taxes = 900

      let monthly = this.target_mortgage - taxes

      let factor = numerator / denominator

      let principal = monthly / factor

      let closing_costs = principal * 0.04

      this.savings_target = Number((this.house_price * 1000) - principal + closing_costs).toFixed(2)



      let savings_per_month_rent_1 = this.income - this.expenses - this.rent_price_1
      let savings_per_month_rent_2 = this.income - this.expenses - this.rent_price_2

      let target = this.savings_target - this.current_money

      let months = 0

      while (target > 0) {
        if (months <= this.months_rent_1) {
          target -= savings_per_month_rent_1
        } else {
          target -= savings_per_month_rent_2
        }
        months += 1
      }

      this.months_to_go = months

      var months_of_rent_1 = 0
      var months_of_rent_2 = 0

      if (this.months_rent_1 >= this.savings_months) {
        months_of_rent_1 = this.savings_months
        months_of_rent_2 = 0
      } else {
        months_of_rent_1 = this.months_rent_1
        months_of_rent_2 = this.savings_months - months_of_rent_1
      }

      var savings = (months_of_rent_1 * savings_per_month_rent_1) + (months_of_rent_2 * savings_per_month_rent_2) + this.current_money

      if (this.savings_months >= months) {
        savings -= this.savings_target
      }
      this.total_savings = Number(savings).toFixed(2)
    }
  }
}
</script>

<style scoped>

</style>