<template>
  <v-row>
    <v-col align="center">
      <v-row style="max-width: 800px; padding-right: 30px; padding-left: 30px;">
        <v-col align="center">
          <div class="ibarra txt-x-large bold">
            good try...
          </div>
        </v-col>
      </v-row>
      <v-spacer style="height: 50px;"></v-spacer>
      <v-row style="max-width: 800px; padding-right: 30px; padding-left: 30px;">
        <v-col align="center">
          <div class="ibarra txt-large">
            I'm sorry, but you're going to have to try harder than that.
            I am a software engineer after all. I know better than to let that happen.
          </div>
        </v-col>
      </v-row>
      <v-row style="max-width: 800px; padding-right: 30px; padding-left: 30px;">
        <v-col align="center">
          <div class="ibarra txt-large">
            P.S. If no one sees this page, I am going to be really disappointed.
          </div>
        </v-col>
      </v-row>
      <v-spacer style="height: 70px;"></v-spacer>
      <v-row>
        <v-col align="center">
          <v-btn class="submit ibarra txt-white bold" color="#9CAF88" @click="returnHome">Return Home</v-btn>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "RSVPMeme",

  methods: {
    returnHome() {
      this.$router.push({ name: 'home' })
    }
  }
}
</script>

<style scoped>
.radio {
  transform: scale(1.5)
}
.float-bottom {
  position: absolute;
  bottom: 0px;
}
.ibarra {
  font-family: 'Ibarra Real Nova', serif;
  color: dimgray;
}
.home-img {
  width: 90%;
  height: auto;
  object-fit: cover;
  object-position: 43% 57%
}
.location-cards {
  width: 80%;
  margin-bottom: 40px;
}
.txt-large {
  font-size: 40px;
}
.txt-x-large {
  font-size: 50px;
}
.txt-med {
  font-size: 25px;
}
.txt-small {
  font-size: 20px;
}
.txt-x-small {
  font-size: 18px;
}
.bold {
  font-weight: bold;
}
.justify {
  text-align: justify;
  text-justify: inter-word;
}
.txt-white {
  color: white;
}
.justify {
  text-align: justify;
  text-justify: inter-word;
}
</style>