<template>
  <v-row style="margin-top: 30px;" v-if="!rsvp_locked">
    <v-col>
      <v-row>
        <v-col>
          <div style="top: 0; left: 0; margin-left: -10px; margin-top: -42px;">
            <v-img v-if="width < 650" :width="(.31 * width) + 145" aspect-ratio="2.5" :src="require('@/assets/eucalyptus.png')"></v-img>
            <v-img v-else :width="(.31 * width) + 110" aspect-ratio="4" :src="require('@/assets/eucalyptuscropped.png')"></v-img>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col align="left" style="padding-top: 27px; padding-left: 30px;">
          <v-icon x-large @click="goToHome">
            mdi-home
          </v-icon>
        </v-col>
        <v-col align="center" class="ibarra txt-large">
          RSVP
        </v-col>
        <v-col align="right" style="padding-top: 27px; padding-right: 30px;">
          <v-icon x-large @click="goToRSVPSummary">
            mdi-chart-box
          </v-icon>
        </v-col>
      </v-row>
      <form style="margin-top: 50px; margin-left: 30px; margin-right: 30px;" @submit="submitGuestName">
        <v-row justify="center">
          <v-col align="center" style="max-width: 700px;">
            <v-text-field
                label="Enter guest/party name"
                v-model="name"
                class="ibarra"
            ></v-text-field>
            <div class="error--text">{{ display_error }}</div>
          </v-col>
        </v-row>
        <v-row>
          <v-col align="center">
            <v-btn class="submit ibarra txt-white bold" color="#9CAF88" @click="submitGuestName">Submit</v-btn>
          </v-col>
        </v-row>
      </form>
    </v-col>
  </v-row>
</template>

<script>
import { onValue, getDatabase, ref } from 'firebase/database'

const db = getDatabase()

export default {
  name: "RSVPView",

  data() {
    return {
      name: '',
      width: 0,
      rsvp_locked: true,
      display_error: ''
    }
  },

  props: {
    error: {
      type: String,
      required: false
    }
  },

  mounted: function() {
    this.display_error = this.error
    this.checkRSVPLock1()
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
    })
    this.onResize()
  },

  methods: {
    checkRSVPLock1() {
      const rsvpLocked = ref(db, 'rsvp_lock_status')
      onValue(rsvpLocked, (snapshot) => {
        const data = snapshot.val()
        if (data == '1') {
          this.$router.push({ name: 'rsvp-meme' })
        }
        if (data == '3') {
          this.$router.push({ name: 'rsvp-text' })
        }
        this.rsvp_locked = data == '1'
      })
    },
    onResize() {
      this.width = window.innerWidth
    },
    submitGuestName(e) {
      if (this.name !== '') {
        if (this.name.length < 3) {
          e.preventDefault()
          this.display_error = 'Please type in at least 3 letters to search.'
        } else {
          this.$router.push({ name: 'rsvp-results', params: { search: this.name } })
        }
      }
    },
    goToRSVPSummary() {
      this.$router.push({ name: 'rsvp-summary' })
    },
    goToHome() {
      this.$router.push({ name: 'home' })
    }
  }
}
</script>

<style scoped>
.submit {
  color: white;
}
.ibarra {
  font-family: 'Ibarra Real Nova', serif;
  color: dimgray;
}
.home-img {
  width: 90%;
  height: auto;
  object-fit: cover;
  object-position: 43% 57%
}
.location-cards {
  width: 80%;
  margin-bottom: 40px;
}
.txt-large {
  font-size: 50px;
}
.txt-med {
  font-size: 30px;
}
.txt-small {
  font-size: 20px;
}
.txt-x-small {
  font-size: 18px;
}
.bold {
  font-weight: bold;
}
.txt-white {
  color: white;
}
.icon:hover {
  cursor: pointer;
}
</style>