import { render, staticRenderFns } from "./Wrapper.vue?vue&type=template&id=06fa8766&scoped=true&"
import script from "./Wrapper.vue?vue&type=script&lang=js&"
export * from "./Wrapper.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "06fa8766",
  null
  
)

export default component.exports