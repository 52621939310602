<template>
  <Wrapper>
    <v-row>
      <v-col align="center">
        <v-row style="max-width: 700px; margin-right: 30px; margin-left: 30px; margin-top: 50px;">
          <v-col align="left">
            <v-row>
              <v-col>
                <div class="ibarra txt-small bold">
                  Name <i style="color: red">{{ name_error }}</i>
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                    v-model="name"
                    outlined
                    class="ibarra"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row style="margin-top: 30px;">
              <v-col>
                <div class="ibarra txt-small bold">
                  Email <i style="color: red">{{ email_error }}</i>
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                    v-model="email"
                    outlined
                    class="ibarra"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row style="margin-top: 30px;">
              <v-col>
                <div class="ibarra txt-small bold">
                  Question <i style="color: red">{{ question_error }}</i>
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-textarea
                    v-model="question"
                    counter
                    outlined
                    maxlength="200"
                    style="max-width: 700px;"
                    class="ibarra"
                >
                </v-textarea>
              </v-col>
            </v-row>
            <v-row>
              <v-col align="center">
                <v-btn class="submit ibarra txt-white bold" color="#9CAF88" @click="submit">Submit</v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-snackbar
          v-model="snackbar"
        >
          Successfully submitted!

          <template v-slot:action="{ attrs }">
            <v-btn
              color="#9CAF88"
              text
              v-bind="attrs"
              @click="snackbar = false"
            >
              Close
            </v-btn>
          </template>
        </v-snackbar>
      </v-col>
    </v-row>
  </Wrapper>
</template>

<script>
import Wrapper from "@/components/Wrapper";

import {ref, getDatabase, set, get, child} from 'firebase/database'

const db = getDatabase()
const dbRef = ref(getDatabase())

export default {
  name: "QuestionsView",

  data() {
    return {
      name: '',
      email: '',
      question: '',
      name_error: '',
      email_error: '',
      question_error: '',
      snackbar: false
    }
  },

  components: {
    Wrapper
  },

  methods: {
    submit() {
      let err = false
      this.name_error = ''
      this.email_error = ''
      this.question_error = ''
      if (this.name === '') {
        this.name_error = '*Required'
        err = true
      }
      let re = /\S+@\S+\.\S+/
      if (!re.test(this.email)) {
        this.email_error = 'Must be an email.'
        err = true
      }
      if (this.email === '') {
        this.email_error = '*Required'
        err = true
      }
      if (this.question === '') {
        this.question_error = '*Required'
        err = true
      }
      if (err) {
        return
      }
      get(child(dbRef, 'questions/count')).then((snapshot) => {
        if (snapshot.exists()) {
          const new_ct = snapshot.val() + 1
          set(ref(db, 'questions/count'), new_ct)
          let date = new Date()
          set(ref(db, 'questions/' + new_ct), {
            name: this.name,
            email: this.email,
            question: this.question,
            time: date.toString()
          })
          this.name = ''
          this.email = ''
          this.question = ''
          this.snackbar = true
        }
      })
    }
  }
}
</script>

<style scoped>
.ibarra {
  font-family: 'Ibarra Real Nova', serif;
  color: dimgray;
}
.home-img {
  width: 90%;
  height: auto;
  object-fit: cover;
  object-position: 43% 57%
}
.location-cards {
  width: 80%;
  margin-bottom: 40px;
}
.txt-large {
  font-size: 40px;
}
.txt-med {
  font-size: 30px;
}
.txt-small {
  font-size: 20px;
}
.txt-x-small {
  font-size: 18px;
}
.bold {
  font-weight: bold;
}
.txt-white {
  color: white;
}
</style>