<template>
  <v-row justify="center" align="center">
    <v-col align="center" justify="center">
      <v-row>
        <v-col cols="12" sm="1" style="margin-top: 20px;">
          <v-icon x-large @click="goToLocation('budget-data')">
            mdi-arrow-left
          </v-icon>
        </v-col>
        <v-col align="left" style="padding-left: 50px;">
          <div style="font-size: 40pt">
            Budget Builder
          </div>
        </v-col>
      </v-row>
      <v-row v-if="isMobile" style="padding-right: 30px; padding-left: 30px;">
        <v-col>
          <v-row>
            <v-col>
              <v-select
                v-model="values"
                :items="items"
                attach
                chips
                label="Category"
                multiple
              ></v-select>
            </v-col>
          </v-row>
          <v-row style="margin-top: -40px;">
            <v-col>
              <v-checkbox
                v-model="combine"
                label="Combine"
                color="black"
                value="combine"
                hide-details
              ></v-checkbox>
            </v-col>
          </v-row>
          <v-row style="margin-top: 40px;">
            <v-col>
              <v-slider
                v-model="months"
                thumb-label="always"
                :thumb-size="40"
                :min="2"
                :max="12"
                ticks="always"
              ></v-slider>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <div v-if="no_data">
                <v-chip color="grey" class="txt-white" style="font-size: 16pt">
                  Select categories to load data
                </v-chip>
              </div>
              <div v-else :style="`height: 300px; width: ${ width - 40 }px;`">
                <LineChart :data="data" :options="options"/>
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row v-else>
        <v-col style="margin-left: 20px; margin-right: 20px;">
          <v-card min-height="590px">
            <v-navigation-drawer
              absolute
              permanent
              left
              width="300"
              bottom
            >
              <template v-slot:prepend>
                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title style="font-size: 20pt;">Settings</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>

              <v-divider></v-divider>

              <v-list dense>
                <v-list-item>
                  <v-row>
                    <v-col>
                      <v-row>
                        <v-col>
                          <div>
                            Category
                          </div>
                        </v-col>
                      </v-row>
                      <v-row style="margin-top: -10px;">
                        <v-col style="margin-top: 0px">
                          <div v-for="(budget, index) in budget_categories" v-bind:key="index" style="margin-top: -20px; display: flex; ">
                            <v-checkbox
                              v-model="category_checks[index]"
                              :label="capitalizeFirstLetter(budget['name'])"
                              :color="budget['color']"
                              :value="budget['name']"
                              hide-details
                            ></v-checkbox>
                          </div>
                        </v-col>
                      </v-row>
                      <v-row style="margin-top: -20px;">
                        <v-col>
                          <v-checkbox
                            v-model="combine"
                            label="Combine"
                            color="black"
                            value="combine"
                            hide-details
                          ></v-checkbox>
                         </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-list-item>
                <v-divider style="margin-top: 20px; margin-bottom: 20px;"></v-divider>
                <v-list-item>
                  <v-row>
                    <v-col>
                      <v-row>
                        <v-col>
                          Months
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col>
                          <v-slider
                            v-model="months"
                            thumb-label="always"
                            :thumb-size="24"
                            :min="2"
                            :max="12"
                            ticks="always"
                          ></v-slider>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-list-item>
              </v-list>
            </v-navigation-drawer>
            <v-row>
              <v-col>
                <div v-if="no_data" style="margin-top: 240px; margin-left: 250px;">
                  <v-chip color="grey" class="txt-white" style="font-size: 16pt">
                    Select categories on the left to load data
                  </v-chip>
                </div>
                <div v-else :style="`height: 500px; width: ${ width - 400 }px; margin-left: 300px;`">
                  <LineChart :data="data" :options="options"/>
                </div>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import {ref, getDatabase, get, child } from 'firebase/database'
import * as api from "./LoadingAndSavingAPIs"

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js'
import { Line as LineChart } from 'vue-chartjs'
import {capitalizeFirstLetter} from "./LoadingAndSavingAPIs";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
)

const dbRef = ref(getDatabase())

export default {
  name: "BudgetBuilder",

  data () {
    return {
      items: [],
      values: [],
      width: 0,
      no_data: true,
      combine: false,
      months: 3,
      data: {
        labels: this.updateLabels(3), // Make sure this starts out at the same value as this.months
        datasets: [
          {
            label: '',
            backgroundColor: '',
            data: []
          }
        ]
      },
      options: {
        scales: {
          y: {
            beginAtZero: true
          }
        }
      },
      budget_categories: [],
      transactions: {
        0: [],
        1: [],
        2: [],
        3: [],
        4: [],
        5: [],
        6: [],
        7: [],
        8: [],
        9: [],
        10: [],
        11: []
      },
      category_checks: []
    }
  },

  components: {
    LineChart
  },

  watch: {
    category_checks() {
      this.updateData()
    },
    values() {
      this.updateData()
    },
    months() {
      this.updateData()
    },
    combine() {
      this.updateData()
    }
  },

  computed: {
    /**
     * Returns whether a device is mobile
     */
    isMobile() {
      return this.width < 962
    }
  },

  mounted: function() {
    if (this.locked) {
      this.$router.push({name: 'budget'})
    }
    this.budget_categories = api.getBudgets()
    this.items = api.getAllCategories()
    this.category_checks = Array(this.budget_categories.length).fill(null)
    this.collectHistoricalData()
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
    })
    this.onResize()
  },

  props: {
    locked: {
      type: Boolean,
      default: true
    }
  },

  methods: {
    categorySpent(categories) {
      let transactions = {
        0: 0,
        1: 0,
        2: 0,
        3: 0,
        4: 0,
        5: 0,
        6: 0,
        7: 0,
        8: 0,
        9: 0,
        10: 0,
        11: 0
      }
      var new_categories = categories.map(function(e) {
        if (e == null) {
          return e
        }
        return capitalizeFirstLetter(e)
      })
      for (let month = 0; month < 12; month++) {
        for (let index in this.transactions[month]) {
          if (new_categories.includes(this.transactions[month][index].category)) {
            transactions[month] += parseFloat(this.transactions[month][index].price)
          }
        }
      }
      return transactions
    },
    onResize() {
      this.width = window.innerWidth
    },
    goToLocation(location) {
      this.$router.push({ name: location, params: { locked: false } })
    },
    updateData() {
      this.data = {
        labels: this.updateLabels(this.months),
        datasets: this.updateDatasets(this.budget_categories, this.isMobile ? this.values : this.category_checks, this.combine == "combine")
      }
      this.options = {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          y: {
            beginAtZero: true
          }
        }
      }
    },
    updateLabels(count) {
      const months = ["January","February","March","April","May","June","July","August","September","October","November","December"];
      var values = [];

      for (var i = 0; i < count; ++i) {
        const date = new Date()
        let month = date.getMonth(date.setMonth(date.getMonth() - (count - i - 1)))
        let name = months[month]
        values.push(name)
      }
      return values
    },
    updateDatasets(categories, selected_categories, combined) {
      var datasets = []
      var is_data = false
      if (!combined) {
        for (let index in categories) {
          if (selected_categories.includes(this.isMobile ? capitalizeFirstLetter(categories[index].name) : categories[index].name)) {
            is_data = true
            datasets.push({
              label: capitalizeFirstLetter(categories[index].name),
              borderColor: categories[index].color,
              data: this.getData(this.categorySpent([categories[index].name]), this.months)
            })
          }
        }
        this.no_data = !is_data
      } else {
        datasets.push({
          label: "Combined Totals",
          borderColor: "black",
          data: this.getData(this.categorySpent(selected_categories), this.months)
        })
      }
      return datasets
    },
    getData(spent, months) {
      var data = []
      for (let month = 1; month <= months; month++) {
        const date = new Date()
        let correct_month = date.getMonth(date.setMonth(date.getMonth() - (months - month)))
        data.push(spent[correct_month])
      }
      return data
    },
    capitalizeFirstLetter(string) {
      return api.capitalizeFirstLetter(string)
    },
    collectHistoricalData() {
      for (let month = 1; month <= 12; month++) {
        const date1 = new Date()
        const date2 = new Date()
        let correct_month = date1.getMonth(date1.setMonth(date1.getMonth() - (month - 1)))
        let correct_year = date2.getFullYear(date2.setMonth(date2.getMonth() - (month - 1)))
        for (let day = 1; day <= 31; day++) {
          get(child(dbRef, `budget/transactions/${correct_year.toString()}/${(correct_month + 1).toString()}/${day}/count`)).then((snapshot) => {
            if (snapshot.exists()) {
              for (let transaction = 1; transaction <= snapshot.val(); transaction++) {
                get(child(dbRef, `budget/transactions/${correct_year.toString()}/${(correct_month + 1).toString()}/${day}/${transaction}`)).then((snapshot) => {
                  if (snapshot.exists()) {
                    let trans = snapshot.val()
                    if (!trans.deleted && !trans.returned) {
                      trans.number = transaction
                      this.transactions[correct_month].push(trans)
                    }
                  }
                })
              }
            }
          })
        }
      }
    }
  }
}
</script>

<style scoped>
.txt-white {
  color: white;
}
</style>