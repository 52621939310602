<template>
  <Wrapper>
    <v-row>
      <v-col align="center">
        <v-img lazy-src="https://picsum.photos/10/6?image=45" src="@/assets/mitchandhannah1.jpg" class="home-img">
          <template v-slot:placeholder>
            <v-row
              class="fill-height ma-0"
              align="center"
              justify="center"
            >
              <v-progress-circular
                indeterminate
                color="grey lighten-5"
              ></v-progress-circular>
            </v-row>
          </template>
        </v-img>
      </v-col>
    </v-row>
    <v-row style="margin-top: 40px; margin-bottom: 20px">
      <v-col align="center">
        <v-row justify="center">
          <v-col :style="!isMobile ? 'border-right: 1px solid dimgray;' : ''" cols="12" sm="4">
            <div class="ibarra txt-large">
              OCTOBER 7,<br>2023
            </div>
          </v-col>
          <v-col :style="!isMobile ? 'border-left: 1px solid dimgray;' : 'border-top: 1px solid dimgray;'" cols="12" sm="4">
            <div class="ibarra txt-large">
              PITTSBURGH,<br>PA
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-row style="margin-bottom: 40px;">
      <v-col align="center">
        <v-btn
            @click="rsvp()"
            x-large
            color="grey"
            :disabled="rsvp_locked"
        >
          <div class="ibarra txt-small bold" style="color: white">
            RSVP <v-icon v-if="rsvp_locked">mdi-lock</v-icon>
          </div>
        </v-btn>
      </v-col>
    </v-row>
    <v-row style="margin-top: 100px;">
      <v-col align="center" class="ibarra txt-med bold">
        WEDDING WEEKEND TIMELINE
      </v-col>
    </v-row>
    <Timeline :title="'Saturday, October 7th'" :timeline="wedding_day_timeline"></Timeline>
    <Timeline :title="'Sunday, October 8th'" :timeline="day_after_timeline"></Timeline>
    <v-row style="margin-top: 50px;">
      <v-col align="center">
        <div class="ibarra txt-small">
          <a href="https://www.instagram.com/explore/tags/milesandmilestogether/"
             style="text-decoration: none; color: dimgray" target="_blank">#MILESANDMILESTOGETHER</a>
        </div>
      </v-col>
    </v-row>

  </Wrapper>
</template>

<script>
import Wrapper from "@/components/Wrapper";
import Timeline from "@/components/Timeline";
import { onValue, getDatabase, ref } from 'firebase/database'

const db = getDatabase()

  export default {
    name: 'HomeView',

    data() {
      return {
        width: 0,
        rsvp_locked: true,
        wedding_day_timeline: [
          {
            time: "5:00 PM - 5:30 PM",
            title: "CEREMONY",
            location: "The Frick Pittsburgh",
            address: "7227 Reynolds Street, Pittsburgh, PA 15208",
            address_link: "https://www.google.com/maps/place/The+Frick/@40.4474811,-79.903945,17z/data=!3m1!4b1!4m5!3m4!1s0x8834ede8b258fb03:0xf7ba8ff6e267bcb2!8m2!3d40.4474811!4d-79.9017563",
            extra: "Please note we will be having an unplugged ceremony with a Photo Minute Opportunity - please see Q+A for more information.",
            color: "grey"
          },
          {
            time: "6:30 PM - 10:00 PM",
            title: "RECEPTION",
            location: "The Frick Pittsburgh",
            address: "7227 Reynolds Street, Pittsburgh, PA 15208",
            address_link: "https://www.google.com/maps/place/The+Frick/@40.4474811,-79.903945,17z/data=!3m1!4b1!4m5!3m4!1s0x8834ede8b258fb03:0xf7ba8ff6e267bcb2!8m2!3d40.4474811!4d-79.9017563",
            extra: "",
            color: "grey"
          },
          {
            time: "10:30 PM - 2:00 AM",
            title: "AFTERPARTY",
            location: "HOWL AT THE MOON",
            address: "125 7th St, Pittsburgh, PA 15222",
            address_link: "https://www.google.com/maps/place/Howl+at+the+Moon+Pittsburgh/@40.4437587,-80.0028956,17z/data=!3m2!4b1!5s0x8834f157d31c3d27:0x1d83e840881e12b0!4m6!3m5!1s0x8834f157d30720df:0x9a82c6b41eecd3a7!8m2!3d40.4437587!4d-80.0003207!16s%2Fg%2F11b7c4_ttv?entry=ttu",
            extra: "The Wedding Party will be continuing the celebration at a bar afterwards and anyone who is interested is welcome to join.",
            color: "grey"
          }
        ],
        day_after_timeline: [
          {
            time: "9:00 AM - 1:00 PM",
            title: "MORNING AFTER BRUNCH",
            location: "Bride's Parent's House",
            address: "310 Blue Run Road, Cheswick, PA 15024",
            address_link: "https://www.google.com/maps/place/310+Blue+Run+Rd,+Cheswick,+PA+15024/@40.5766498,-79.874638,17z/data=!3m1!4b1!4m5!3m4!1s0x883493cae2c0bd09:0x65283e95c08d7128!8m2!3d40.5766457!4d-79.8724493",
            extra: "The bride’s family will host a post-wedding brunch on October 8th, and you and your entire family are cordially invited. Come and grab a bite before hitting the road home. All are welcome!",
            color: "grey"
          }
        ]
      }
    },

    components: {
      Wrapper,
      Timeline
    },

    mounted: function() {
      this.checkRSVPLock()
      this.$nextTick(() => {
        window.addEventListener('resize', this.onResize)
      })
      this.onResize()
      },

    computed: {
      /**
       * Returns whether a device is mobile
       */
      isMobile() {
        return this.width < 600
      }
    },

    methods: {
      checkRSVPLock() {
        const rsvpLocked = ref(db, 'rsvp_lock_status')
        onValue(rsvpLocked, (snapshot) => {
          const data = snapshot.val()
          this.rsvp_locked = data == '1'
        })
      },
      rsvp() {
        this.$router.push( {name: 'rsvp' })
      },
      onResize() {
        this.width = window.innerWidth
      }
    }
  }
</script>

<style>
.ibarra {
  font-family: 'Ibarra Real Nova', serif;
  color: dimgray;
}
.home-img {
  width: 90%;
  height: auto;
  object-fit: cover;
  object-position: 43% 57%
}
.location-cards {
  width: 80%;
  margin-bottom: 40px;
}
.txt-large {
  font-size: 40px;
}
.txt-med {
  font-size: 30px;
}
.txt-small {
  font-size: 20px;
}
.txt-x-small {
  font-size: 18px;
}
.bold {
  font-weight: bold;
}
</style>