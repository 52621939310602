import { getDatabase, ref, onValue, set, get, child } from "firebase/database"
import { initializeApp } from 'firebase/app'

const firebaseConfig = {
  apiKey: "AIzaSyAxIlavv4dePmQzgrib5NgrZu0mYCOrWo4",
  authDomain: "wedding-website-4a01e.firebaseapp.com",
  projectId: "wedding-website-4a01e",
  storageBucket: "wedding-website-4a01e.appspot.com",
  messagingSenderId: "879310956944",
  appId: "1:879310956944:web:7e6e22a02a934483b76b15"
};

//Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getDatabase(app)
const dbRef = ref(getDatabase());

/**
 * Obtains all the monthly budget numbers
 * @param {Boolean} vacation Whether the budgets pertain to a yearly expense or not
 * @returns {Array} The list of budget categories with their associated name, budget, icon, and color
 */
export function getBudgets(vacation = false) {
    let budget_text = "monthly_budgets/"
    if (vacation) {
        budget_text = budget_text + "vacation/"
    }
    var budgets = []
    onValue(ref(db, `budget/${budget_text}/count`), (snapshot) => {
        if (snapshot.exists()) {
            let count = snapshot.val()
            for (let budgetNum = 1; budgetNum <= count; budgetNum++) {
                onValue(ref(db, `budget/${budget_text}/${budgetNum}`), (snapshot) => {
                    if (snapshot.exists()) {
                        // let budget = snapshot.val()
                        // budget.name = capitalizeFirstLetter(budget.name)
                        budgets.push(snapshot.val())
                    }
                })
            }
        }
    })

    return budgets
}

/**
 *
 * @param year
 * @param month
 * @param vacation
 * @returns Transactions in the form of [[AllGroceryTransactions], [AllGasTransactions], ... ]
 */
export function getAllTransactions(year, month, vacation = false) {

    let transaction_string = "transactions"
    if (vacation) {
        transaction_string = transaction_string + "/vacation"
    }
    let transactions = {}

    for (let day = 1; day <= 31; day++) {
        onValue(ref(db, `budget/${transaction_string}/${getYearAndMonthString(year, month)}/${day}/count`), (snapshot) => {
            if (snapshot.exists()) {
                for (let transaction = 1; transaction <= snapshot.val(); transaction++) {
                    onValue(ref(db, `budget/${transaction_string}/${getYearAndMonthString(year, month)}/${day}/${transaction}`), (snapshot) => {
                        if (snapshot.exists()) {
                            let single_transaction = snapshot.val()
                            if (!single_transaction.deleted) {
                                single_transaction.number = transaction

                                // Add to individual category transaction list
                                if (transactions[capitalizeFirstLetter(single_transaction.category)] == undefined) {
                                    transactions[capitalizeFirstLetter(single_transaction.category)] = [single_transaction]
                                } else {
                                    transactions[capitalizeFirstLetter(single_transaction.category)].push(single_transaction)
                                }

                                // Add to overall list
                                if (transactions["All"] == undefined) {
                                    transactions["All"] = [single_transaction]
                                } else {
                                    transactions["All"].push(single_transaction)
                                }
                            }
                        }
                    })
                }
            }
        })
    }
    return transactions
}

/**
 * Obtains all the monthly category values
 * @param {Boolean} vacation Whether the budgets pertain to a yearly expense or not
 * @returns {Array} The list of budget categories
 */
export function getAllCategories(vacation = false) {
    let budget_text = "monthly_budgets"
    if (vacation) {
        budget_text = budget_text + "/vacation"
    }
    let categories = []
    onValue(ref(db, `budget/${budget_text}/count`), (snapshot) => {
        if (snapshot.exists()) {
            let count = snapshot.val()
            for (let budgetNum = 1; budgetNum <= count; budgetNum++) {
                onValue(ref(db, `budget/${budget_text}/${budgetNum}`), (snapshot) => {
                    if (snapshot.exists()) {
                        const budget = snapshot.val()
                        categories.push(capitalizeFirstLetter(budget.name))
                    }
                })
            }
        }
    })
    return categories
}

export function loadQuickButtons() {
    let quick_buttons = []
    onValue(ref(db, 'budget/quick_buttons/count'), (snapshot) => {
        if (snapshot.exists()) {
            let count = snapshot.val()
            for (let buttonIndex = 1; buttonIndex <= count; buttonIndex++) {
                onValue(ref(db, `budget/quick_buttons/${buttonIndex}`), (snapshot) => {
                    if (snapshot.exists()) {
                        quick_buttons.push(snapshot.val())
                    }
                })
            }
        }
    })
    return quick_buttons
}

/**
 * Saves a budget transaction given the user inputted data
 * @param {String} name
 * @param {String} date
 * @param {String} category
 * @param {String} location
 * @param {Number} price
 * @param {Number} months
 * @param {Boolean} vacation
 * @return {Boolean} Whether the operation was successful
 */
export function saveTransaction(name, date, category, location, price, months, vacation) {
    let total_incremented_price = 0

    if (vacation) {
        months = 1
    }

    for (let month = 0; month < months; month++) {
        let date_string = getDateString(date, month)
        if (vacation) {
            date_string = "vacation/" + date_string
        }
        let each_price = 0
        // Figure out the price for the month
        if (month == months - 1) {
            each_price = (price - total_incremented_price).toFixed(2)
        } else {
            each_price = (price/months).toFixed(2)
            total_incremented_price += parseFloat(each_price)
        }

        get(child(dbRef, `budget/transactions/${date_string}/count`)).then((snapshot) => {
            let new_index
            if(snapshot.exists()) { // This date has existing transactions
                new_index = parseInt(snapshot.val()) + 1
            } else { // This transaction will be the first one of this date
                new_index = 1
            }
            // Set the transaction
            set(ref(db, `budget/transactions/${date_string}/${new_index}`), {
                name: name,
                date: convertDateString(getDateString(date, month)),
                category: category,
                location: location,
                price: each_price,
                payment: (month + 1) + "/" + months
            })

            // Set the new number of transactions for the specified date
            set(ref(db, `budget/transactions/${date_string}/count`), new_index)
        })
    }
}

/**
 * Returns the year and month in a db readable format
 * @param {String} year The year to pull data from
 * @param {String} month The month to pull data from
 * @return {String} The year and month concatenated together by a '/'
 */
export function getYearAndMonthString(year, month) {
  return year + "/" + month
}

/**
 * Capitalizes the first letters of the category words and removes the dash
 * @param String The category value in question
 * @return {String} The user friendly category
 */
export function capitalizeFirstLetter(string) {
  const rep = string.replace("-", " ")
  const words = rep.split(" ");

  for (let i = 0; i < words.length; i++) {
      words[i] = words[i][0].toUpperCase() + words[i].substr(1);
      if (words[i] == "Misc") {
          words[i] = "Misc."
      }
  }

  return words.join(" ");
}

/**
 * Takes a date and increments a user defined number of months and returns it in the proper form to be saved
 * @param {String} date The initial date to be saved
 * @param {Number} incrementMonths The number of months to increment the current date by
 * @return {String} The correct date string for submitting a transaction in the form year/month/day
 */
function getDateString(date, incrementMonths = 0) {
    const stringArray = date.split("-")
    let year = parseInt(stringArray[0])
    let month = parseInt(stringArray[1]) + incrementMonths
    let day = parseInt(stringArray[2])

    while (month > 12) {
    month -=12
    year += 1
    }

    const dateString = year + "/" + month + "/" + day
    return dateString
}

/**
 * Takes in a system date and converts it to a database date
 * @param {String} oldString The system string for the specified date
 * @return {String} The date that is to be saved to the database
 */
function convertDateString(oldString) {
    const stringArray = oldString.split("/")
    if (stringArray[1].length == 1) {
    stringArray[1] = "0" + stringArray[1]
    }
    if (stringArray[2].length == 1) {
    stringArray[2] = "0" + stringArray[2]
    }
    return stringArray[0] + "-" + stringArray[1] + "-" + stringArray[2]
}