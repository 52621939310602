<template>
  <v-row>
    <v-col>
      <div style="position: absolute; top: 0; left: 0; margin-left: -10px;">
        <v-img v-if="width < 650" :width="(.31 * width) + 145" aspect-ratio="2.5" :src="require('@/assets/eucalyptus.png')"></v-img>
        <v-img v-else :width="(.31 * width) + 110" aspect-ratio="4" :src="require('@/assets/eucalyptuscropped.png')"></v-img>
      </div>
      <v-row style="margin-top: 40px;">
        <v-col>
          <v-row>
            <v-col align="center" class="ibarra txt-large">
              HANNAH & MITCHELL
            </v-col>
          </v-row>
          <v-row>
            <v-col align="center" class="ibarra txt-small">
              <div>
                OCTOBER 7, 2023 • PITTSBURGH, PA
              </div>
              <div>
                {{ getDaysToGo() }} DAYS TO GO!
              </div>
            </v-col>
          </v-row>
          <v-spacer style="height: 50px"/>
        </v-col>
      </v-row>
      <v-row v-if="!isMobile">
        <v-col>
          <v-tabs
              color="#9CAF88"
              show-arrows
              v-model="tab"
              fixed-tabs
          >
            <v-tab @click="goToLocation('home')">
              Home
            </v-tab>
            <v-tab @click="goToLocation('our-story')">
              Our Story
            </v-tab>
            <v-tab @click="goToLocation('photos')">
              Photos
            </v-tab>
            <v-tab @click="goToLocation('wedding-party')">
              Wedding Party
            </v-tab>
            <v-tab @click="goToLocation('qa')">
              Q + A
            </v-tab>
            <v-tab @click="goToLocation('travel')">
              Travel
            </v-tab>
            <v-tab @click="goToLocation('registry')">
              Registry
            </v-tab>
            <v-tab @click="goToLocation('rsvp')" :disabled="rsvp_locked">
              RSVP <v-icon v-if="rsvp_locked">mdi-lock</v-icon>
            </v-tab>
          </v-tabs>
        </v-col>
      </v-row>
      <v-row v-if="isMobile" style="margin-top: -50px; margin-bottom: -70px;">
        <v-col align="center">
          <v-tabs
              centered
          >
            <v-row>
              <v-col align="center">
                <v-menu
                    offset-y
                    :nudge-width="1000"
                    bottom
                    transition="slide-y-transition"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                        v-bind="attrs"
                        v-on="on"
                        x-large
                    >
                      mdi-menu
                    </v-icon>
                  </template>
                  <v-list>
                    <v-list-item @click="goToLocation('home')">
                      <v-row>
                        <v-col align="center" :class="currentPath('home') ? 'mobile-tab-selected' : 'mobile-tab-not-selected'">
                          Home
                        </v-col>
                      </v-row>
                    </v-list-item>
                    <v-divider></v-divider>
                    <v-list-item @click="goToLocation('our-story')">
                      <v-row>
                        <v-col align="center" :class="currentPath('our-story') ? 'mobile-tab-selected' : 'mobile-tab-not-selected'">
                          Our Story
                        </v-col>
                      </v-row>
                    </v-list-item>
                    <v-divider></v-divider>
                    <v-list-item @click="goToLocation('photos')">
                      <v-row>
                        <v-col align="center" :class="currentPath('photos') ? 'mobile-tab-selected' : 'mobile-tab-not-selected'">
                          Photos
                        </v-col>
                      </v-row>
                    </v-list-item>
                    <v-divider></v-divider>
                    <v-list-item @click="goToLocation('wedding-party')">
                      <v-row>
                        <v-col align="center" :class="currentPath('wedding-party') ? 'mobile-tab-selected' : 'mobile-tab-not-selected'">
                          Wedding Party
                        </v-col>
                      </v-row>
                    </v-list-item>
                    <v-divider></v-divider>
                    <v-list-item @click="goToLocation('qa')">
                      <v-row>
                        <v-col align="center" :class="currentPath('qa') ? 'mobile-tab-selected' : 'mobile-tab-not-selected'">
                          Q + A
                        </v-col>
                      </v-row>
                    </v-list-item>
                    <v-divider></v-divider>
                    <v-list-item @click="goToLocation('travel')">
                      <v-row>
                        <v-col align="center" :class="currentPath('travel') ? 'mobile-tab-selected' : 'mobile-tab-not-selected'">
                          Travel
                        </v-col>
                      </v-row>
                    </v-list-item>
                    <v-divider></v-divider>
                    <v-list-item @click="goToLocation('registry')">
                      <v-row>
                        <v-col align="center" :class="currentPath('registry') ? 'mobile-tab-selected' : 'mobile-tab-not-selected'">
                          Registry
                        </v-col>
                      </v-row>
                    </v-list-item>
                    <v-divider></v-divider>
                    <v-list-item @click="goToLocation('rsvp')" :disabled="rsvp_locked">
                      <v-row>
                        <v-col align="center" :class="rsvp_locked ? 'mobile-tab-disabled' : (currentPath('rsvp') ? 'mobile-tab-selected' : 'mobile-tab-not-selected')">
                          RSVP <v-icon v-if="rsvp_locked" :class="rsvp_locked ? 'mobile-tab-disabled' : ''">mdi-lock</v-icon>
                        </v-col>
                      </v-row>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-col>
            </v-row>

          </v-tabs>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { initializeApp } from 'firebase/app'
import { onValue, getDatabase, ref } from 'firebase/database'

// Import the functions you need from the SDKs you need
//import firebase from "firebase";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAxIlavv4dePmQzgrib5NgrZu0mYCOrWo4",
  authDomain: "wedding-website-4a01e.firebaseapp.com",
  projectId: "wedding-website-4a01e",
  storageBucket: "wedding-website-4a01e.appspot.com",
  messagingSenderId: "879310956944",
  appId: "1:879310956944:web:7e6e22a02a934483b76b15"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getDatabase(app)

export default {
  name: "HeaderView",

  data() {
    return {
      selected_tab: 0,
      tab: 0,
      home_tab: true,
      our_story_tab: false,
      photos_tab: false,
      wedding_party_tab: false,
      qa_tab: false,
      travel_tab: false,
      registry_tab: false,
      rsvp_tab: false,
      drawer: false,
      group: null,
      width: 0,
      rsvp_locked: true
    }
  },

  mounted: function() {
    this.checkRSVPLock()
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
    })
    this.onResize()
    this.selectCorrectTab()
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.onResize)
  },

  watch: {
    group() {
      this.drawer = false
    }
  },

  computed: {
    /**
     * Returns whether a device is mobile
     */
    isMobile() {
      return this.width < 962
    }
  },

  methods: {
    checkRSVPLock() {
      const rsvpLocked = ref(db, 'rsvp_lock_status')
      onValue(rsvpLocked, (snapshot) => {
        const data = snapshot.val()
        this.rsvp_locked = data == '1'
      })
    },
    onResize() {
      this.width = window.innerWidth
    },
    selectCorrectTab() {
      if (this.currentPath('home')) {
        this.tab = 0
      } else if (this.currentPath('our-story')) {
        this.tab = 1
      } else if (this.currentPath('photos')) {
        this.tab = 2
      } else if (this.currentPath('wedding-party')) {
        this.tab = 3
      } else if (this.currentPath('qa') || this.currentPath('questions')) {
        this.tab = 4
      } else if (this.currentPath('travel')) {
        this.tab = 5
      } else if (this.currentPath('registry')) {
        this.tab = 6
      } else if (this.currentPath('rsvp')) {
        this.tab = 7
      } else {
        this.tab = 0
      }
    },
    getDaysToGo() {
      let currentDate = new Date()
      let weddingDate = new Date('10/8/2023')

      let difference = weddingDate.getTime() - currentDate.getTime()

      return Math.ceil(difference / (1000 * 3600 * 24)) - 1
    },
    currentPath(route) {
      return this.$router.currentRoute.name === route
    },
    navigate(path) {
      this.$router.push({name: path})
    },
    resetTabs() {
      this.home_tab = false
      this.our_story_tab = false
      this.photos_tab = false
      this.wedding_party_tab = false
      this.qa_tab = false
      this.travel_tab = false
      this.registry_tab = false
      this.rsvp_tab = false
    },
    goToLocation(location) {
      this.$router.push({ name: location })
    }
  }
}
</script>

<style scoped>
.ibarra {
  font-family: 'Ibarra Real Nova', serif;
  color: dimgray;
}
.txt-small {
  font-size: 20px;
}
.txt-large {
  font-size: 56px;
}
.mobile-tab-disabled {
  color: lightgray;
}
.tabs {
  text-decoration: none;
  color: dimgray;
  margin-left: 30px;
  margin-right: 30px;
}
.tab-selected {
  text-decoration: underline 3px dimgrey;
  text-underline-offset: .25em;
}
.tabs:hover {
  text-decoration: underline 3px darkgray;
  text-underline-offset: .25em;
}
.underline {
  text-decoration: underline 3px;
  text-underline-offset: .25em;
}
.mobile-tabs {
  text-decoration: none;
  color: dimgray;
}
.mobile-tab-selected {
  color: #9CAF88;
}
.mobile-tab-not-selected {
  color: black;
}
.bold {
  font-weight: bold;
}
</style>