<template>
<v-row>
  <v-col>
    <v-row style="margin-top: 20px;">
      <v-col align="center">
        <div class="ibarra txt-med">
          {{ title }}
        </div>
      </v-col>
    </v-row>

    <v-row style="margin-bottom: -36px; margin-top: -15px;">
      <v-col align="center">
        <v-divider style="max-width: 300px;"></v-divider>
<!--        <v-divider style="max-width: 425px;"></v-divider>-->
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-timeline>
          <v-timeline-item
              v-for="(timeline_item,index) in timeline" v-bind:key="index"
              :color="timeline_item.color"
          >
            <template v-if="index % 2 === 0" v-slot:opposite>
              <span>
                <v-row>
                  <v-col>
                    <div class="ibarra txt-small bold">
                      {{ timeline_item.time }}
                    </div>
                  </v-col>
                </v-row>
              </span>
            </template>
            <template v-else v-slot:opposite>
              <span
                class="ibarra txt-small"
              >
                 <v-row>
                  <v-col align="left">
                    <div class="ibarra txt-small">
                      {{ timeline_item.title }}
                    </div>
                    <div class="ibarra txt-small">
                      {{ timeline_item.location }}
                    </div>
                    <div class="ibarra txt-small">
                      <a :href="timeline_item.address_link"
                         style="text-decoration: none; color: dimgray" target="_blank">{{ timeline_item.address }}</a>
                    </div>
                    <div class="ibarra txt-x-small" style="margin-top: 8px; font-style: italic">
                      {{ timeline_item.extra }}
                    </div>
                  </v-col>
                </v-row>
              </span>
            </template>
            <v-row v-if="index % 2 === 0">
              <v-col align="left" style="max-width: 550px;">
                <div class="ibarra txt-small">
                  {{ timeline_item.title }}
                </div>
                <div class="ibarra txt-small">
                  {{ timeline_item.location }}
                </div>
                <div class="ibarra txt-small">
                  <a :href="timeline_item.address_link"
                     style="text-decoration: none; color: dimgray" target="_blank">{{ timeline_item.address }}</a>
                </div>
                <div class="ibarra txt-x-small" style="margin-top: 8px; font-style: italic">
                  {{ timeline_item.extra }}
                </div>
              </v-col>
            </v-row>
            <v-row v-else>
              <v-col align="right" style="margin-top: 32px">
                <div class="ibarra txt-small bold">
                  {{ timeline_item.time }}
                </div>
              </v-col>
            </v-row>
          </v-timeline-item>
        </v-timeline>
      </v-col>
    </v-row>
  </v-col>
</v-row>
</template>

<script>
export default {
  name: "TimelineView",

  props: {
    title: String,
    timeline: Array // Array of form [{ time: "", title: "", location: "", address: "", extra: "", color: "" }]
  },
}
</script>

<style scoped>

</style>