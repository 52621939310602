<template>
  <v-row style="margin-top: 30px;">
    <v-col align="center">
<!--      Challenges coming 9/12...-->
      <v-row>
        <v-col align="center">
          <v-icon x-large @click="goToLocation('day3')">
            mdi-arrow-left
          </v-icon>
        </v-col>
        <v-col class="ibarra txt-large" align="center">
          Challenges
        </v-col>
        <v-col>
        </v-col>
      </v-row>
      <v-row>
        <v-col align="center">
          <div class="ibarra txt-small">
            {{ date }}
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-tabs
              color="#9CAF88"
              show-arrows
              v-model="tab"
              fixed-tabs
          >
            <v-tab>
              Hannah
            </v-tab>
            <v-tab>
              Mitch
            </v-tab>
          </v-tabs>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-tabs-items v-model="tab">
            <v-tab-item v-for="num in 2" v-bind:key="num">
              <v-row style="max-width: 700px; padding-left: 50px; padding-right: 50px;">
                <v-col>
                  <v-row>
                    <v-col align="left">
                      <div class="ibarra txt-small bold">
                        Skin Care
                      </div>
                    </v-col>
                  </v-row>
                  <v-row style="margin-left: 30px;">
                    <v-col align="left" class="ibarra txt-x-small">
                      Morning
                      <v-btn
                          v-if="morning_submitted[num-1]"
                          style="margin-left: 15px;"
                          class="submit ibarra txt-white bold"
                          color="blue"
                          @click="editMorning(num-1)"
                      >
                        <v-icon>
                          mdi-pencil
                        </v-icon>
                      </v-btn>
                      <v-btn
                          v-if="!morning_submitted[num-1]"
                          style="margin-left: 15px;"
                          class="submit ibarra txt-white bold"
                          color="#9CAF88"
                          @click="submitMorning(num-1)"
                      >
                        <v-icon>
                          mdi-check
                        </v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                  <v-row style="margin-left: 30px;">
                    <v-col align="left">
                      <v-radio-group
                        v-model="morning[num-1]"
                        row
                        class="ibarra"
                        v-if="!morning_submitted[num-1]"
                      >
                        <v-radio
                          label="Completed"
                          value="completed"
                        ></v-radio>
                        <v-radio
                          label="Not Completed"
                          value="not-completed"
                        ></v-radio>
                      </v-radio-group>
                      <div v-if="morning_submitted[num-1]">
                        <div v-if="morning[num-1] === 'not-completed'" class="ibarra bold">
                          <v-chip color="red" style="color: white">
                            Not today.
                          </v-chip>
                        </div>
                        <div v-else class="ibarra bold">
                          <v-chip color="green" style="color: white">
                            I did it today!
                          </v-chip>
                        </div>
                      </div>
                    </v-col>
                  </v-row>
                  <v-row style="margin-left: 30px; margin-top: 30px;">
                    <v-col align="left" class="ibarra txt-x-small">
                      Evening
                      <v-btn
                          v-if="evening_submitted[num-1]"
                          style="margin-left: 15px;"
                          class="submit ibarra txt-white bold"
                          color="blue"
                          @click="editEvening(num-1)"
                      >
                        <v-icon>
                          mdi-pencil
                        </v-icon>
                      </v-btn>
                      <v-btn
                          v-if="!evening_submitted[num-1]"
                          style="margin-left: 15px;"
                          class="submit ibarra txt-white bold"
                          color="#9CAF88"
                          @click="submitEvening(num-1)"
                      >
                        <v-icon>
                          mdi-check
                        </v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                  <v-row style="margin-left: 30px;">
                    <v-col align="left">
                      <v-radio-group
                        v-model="evening[num-1]"
                        row
                        class="ibarra"
                        v-if="!evening_submitted[num-1]"
                      >
                        <v-radio
                          label="Completed"
                          value="completed"
                        ></v-radio>
                        <v-radio
                          label="Not Completed"
                          value="not-completed"
                        ></v-radio>
                      </v-radio-group>
                      <div v-if="evening_submitted[num-1]">
                        <div v-if="evening[num-1] === 'not-completed'" class="ibarra bold">
                          <v-chip color="red" style="color: white">
                            Not today.
                          </v-chip>
                        </div>
                        <div v-else class="ibarra bold">
                          <v-chip color="green" style="color: white">
                            I did it today!
                          </v-chip>
                        </div>
                      </div>
                    </v-col>
                  </v-row>
                  <v-row style="margin-top: 50px;">
                    <v-col align="left" class="ibarra txt-small bold">
                      Working Out
                      <v-btn
                          v-if="gym_submitted[num-1]"
                          style="margin-left: 15px;"
                          class="submit ibarra txt-white bold"
                          color="blue"
                          @click="editGym(num-1)"
                      >
                        <v-icon>
                          mdi-pencil
                        </v-icon>
                      </v-btn>
                      <v-btn
                          v-if="!gym_submitted[num-1]"
                          style="margin-left: 15px;"
                          class="submit ibarra txt-white bold"
                          color="#9CAF88"
                          @click="submitGym(num-1)"
                      >
                        <v-icon>
                          mdi-check
                        </v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                  <v-row style="margin-left: 30px;">
                    <v-col align="left">
                      <v-radio-group
                        v-model="gym[num-1]"
                        row
                        class="ibarra"
                        v-if="!gym_submitted[num-1]"
                      >
                        <v-radio
                          label="Did an activity"
                          value="completed"
                        ></v-radio>
                        <v-radio
                          label="Not Today"
                          value="not-completed"
                        ></v-radio>
                      </v-radio-group>
                      <div v-if="gym_submitted[num-1]">
                        <div v-if="gym[num-1] === 'not-completed'" class="ibarra bold">
                          <v-chip color="red" style="color: white">
                            Not today.
                          </v-chip>
                        </div>
                        <div v-else class="ibarra bold">
                          <v-chip color="green" style="color: white">
                            I did it today!
                          </v-chip>
                        </div>
                      </div>
                    </v-col>
                  </v-row>
                  <v-row v-if="num === 2" style="margin-top: 50px;">
                    <v-col align="left">
                      <div class="ibarra txt-small bold">
                        Veggies / Fruit
                      </div>
                    </v-col>
                  </v-row>
                  <v-row v-if="num === 2" style="margin-left: 30px;">
                    <v-col align="left" class="ibarra txt-x-small">
                      Serving 1
                      <v-btn
                          v-if="serving1_submitted[num-1]"
                          style="margin-left: 15px;"
                          class="submit ibarra txt-white bold"
                          color="blue"
                          @click="editServing1(num-1)"
                      >
                        <v-icon>
                          mdi-pencil
                        </v-icon>
                      </v-btn>
                      <v-btn
                          v-if="!serving1_submitted[num-1]"
                          style="margin-left: 15px;"
                          class="submit ibarra txt-white bold"
                          color="#9CAF88"
                          @click="submitServing1(num-1)"
                      >
                        <v-icon>
                          mdi-check
                        </v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                  <v-row v-if="num === 2" style="margin-left: 30px;">
                    <v-col align="left">
                      <v-radio-group
                        v-model="serving1[num-1]"
                        row
                        class="ibarra"
                        v-if="!serving1_submitted[num-1]"
                      >
                        <v-radio
                          label="Eaten"
                          value="completed"
                        ></v-radio>
                        <v-radio
                          label="Not Eaten"
                          value="not-completed"
                        ></v-radio>
                      </v-radio-group>
                      <div v-if="serving1_submitted[num-1]">
                        <div v-if="serving1[num-1] === 'not-completed'" class="ibarra bold">
                          <v-chip color="red" style="color: white">
                            Not today.
                          </v-chip>
                        </div>
                        <div v-else class="ibarra bold">
                          <v-chip color="green" style="color: white">
                            I did it today!
                          </v-chip>
                        </div>
                      </div>
                    </v-col>
                  </v-row>
                  <v-row v-if="num === 2" style="margin-left: 30px; margin-top: 30px;">
                    <v-col align="left" class="ibarra txt-x-small">
                      Serving 2
                      <v-btn
                          v-if="serving2_submitted[num-1]"
                          style="margin-left: 15px;"
                          class="submit ibarra txt-white bold"
                          color="blue"
                          @click="editServing2(num-1)"
                      >
                        <v-icon>
                          mdi-pencil
                        </v-icon>
                      </v-btn>
                      <v-btn
                          v-if="!serving2_submitted[num-1]"
                          style="margin-left: 15px;"
                          class="submit ibarra txt-white bold"
                          color="#9CAF88"
                          @click="submitServing2(num-1)"
                      >
                        <v-icon>
                          mdi-check
                        </v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                  <v-row v-if="num === 2" style="margin-left: 30px;">
                    <v-col align="left">
                      <v-radio-group
                        v-model="serving2[num-1]"
                        row
                        class="ibarra"
                        v-if="!serving2_submitted[num-1]"
                      >
                        <v-radio
                          label="Eaten"
                          value="completed"
                        ></v-radio>
                        <v-radio
                          label="Not Eaten"
                          value="not-completed"
                        ></v-radio>
                      </v-radio-group>
                      <div v-if="serving2_submitted[num-1]">
                        <div v-if="serving2[num-1] === 'not-completed'" class="ibarra bold">
                          <v-chip color="red" style="color: white">
                            Not today.
                          </v-chip>
                        </div>
                        <div v-else class="ibarra bold">
                          <v-chip color="green" style="color: white">
                            I did it today!
                          </v-chip>
                        </div>
                      </div>
                    </v-col>
                  </v-row>
                  <v-row style="margin-bottom: 30px; margin-top: 90px;">
                    <v-col cols="12" sm="7">
                      <v-card height="600">
                        <v-row>
                          <v-col>
                            <v-card-title class="ibarra bold">
                              Skincare Tracker
                            </v-card-title>
                            <v-card-title style="font-size: 14pt; margin-top: -20px;" class="ibarra bold">
                              Goal: 14/14
                            </v-card-title>
                            <v-row>
                              <v-col>
                                <v-timeline align-top dense>
                                  <v-timeline-item :color="morning_skincare_color[num-1][0]"></v-timeline-item>
                                  <v-timeline-item :color="morning_skincare_color[num-1][1]"></v-timeline-item>
                                  <v-timeline-item :color="morning_skincare_color[num-1][2]"></v-timeline-item>
                                  <v-timeline-item :color="morning_skincare_color[num-1][3]"></v-timeline-item>
                                  <v-timeline-item :color="morning_skincare_color[num-1][4]"></v-timeline-item>
                                  <v-timeline-item :color="morning_skincare_color[num-1][5]"></v-timeline-item>
                                  <v-timeline-item :color="morning_skincare_color[num-1][6]"></v-timeline-item>
                                </v-timeline>
                              </v-col>
                              <v-col>
                                <v-timeline align-top dense reverse>
                                  <v-timeline-item :color="evening_skincare_color[num-1][0]">
                                    <div style="margin-top: 8px; margin-left: -60px;">Sunday</div>
                                  </v-timeline-item>
                                  <v-timeline-item :color="evening_skincare_color[num-1][1]">
                                    <div style="margin-top: 8px; margin-left: -60px;">Monday</div>
                                  </v-timeline-item>
                                  <v-timeline-item :color="evening_skincare_color[num-1][2]">
                                    <div style="margin-top: 8px; margin-left: -60px;">Tuesday</div>
                                  </v-timeline-item>
                                  <v-timeline-item :color="evening_skincare_color[num-1][3]">
                                    <div style="margin-top: 8px; margin-left: -60px;">Wednesday</div>
                                  </v-timeline-item>
                                  <v-timeline-item :color="evening_skincare_color[num-1][4]">
                                    <div style="margin-top: 8px; margin-left: -60px;">Thursday</div>
                                  </v-timeline-item>
                                  <v-timeline-item :color="evening_skincare_color[num-1][5]">
                                    <div style="margin-top: 8px; margin-left: -60px;">Friday</div>
                                  </v-timeline-item>
                                  <v-timeline-item :color="evening_skincare_color[num-1][6]">
                                    <div style="margin-top: 8px; margin-left: -60px;">Saturday</div>
                                  </v-timeline-item>
                                </v-timeline>
                              </v-col>
                            </v-row>
                          </v-col>
                        </v-row>
                      </v-card>
                    </v-col>
                    <v-col cols="12" sm="5">
                      <v-card height="600">
                        <v-row>
                          <v-col>
                            <v-card-title class="ibarra bold">
                              Workout Tracker
                            </v-card-title>
                            <v-card-title style="font-size: 14pt; margin-top: -20px;" class="ibarra bold">
                              Goal: 4/7
                            </v-card-title>
                            <v-row>
                              <v-col>
                                <v-timeline align-top dense>
                                  <v-timeline-item :color="workout_color[num-1][0]"><div style="margin-top: 8px">Sunday</div></v-timeline-item>
                                  <v-timeline-item :color="workout_color[num-1][1]"><div style="margin-top: 8px">Monday</div></v-timeline-item>
                                  <v-timeline-item :color="workout_color[num-1][2]"><div style="margin-top: 8px">Tuesday</div></v-timeline-item>
                                  <v-timeline-item :color="workout_color[num-1][3]"><div style="margin-top: 8px">Wednesday</div></v-timeline-item>
                                  <v-timeline-item :color="workout_color[num-1][4]"><div style="margin-top: 8px">Thursday</div></v-timeline-item>
                                  <v-timeline-item :color="workout_color[num-1][5]"><div style="margin-top: 8px">Friday</div></v-timeline-item>
                                  <v-timeline-item :color="workout_color[num-1][6]"><div style="margin-top: 8px">Saturday</div></v-timeline-item>
                                </v-timeline>
                              </v-col>
                            </v-row>
                          </v-col>
                        </v-row>
                      </v-card>
                    </v-col>
                  </v-row>
                  <v-row v-if="num === 2" style="margin-bottom: 30px; margin-top: 30px;">
                    <v-col cols="12" sm="7">
                      <v-card height="600">
                        <v-row>
                          <v-col>
                            <v-card-title class="ibarra bold">
                              Veggie / Fruit Tracker
                            </v-card-title>
                            <v-card-title style="font-size: 14pt; margin-top: -20px;" class="ibarra bold">
                              Goal: 14/14
                            </v-card-title>
                            <v-row>
                              <v-col>
                                <v-timeline align-top dense>
                                  <v-timeline-item :color="serving1_color[num-1][0]"></v-timeline-item>
                                  <v-timeline-item :color="serving1_color[num-1][1]"></v-timeline-item>
                                  <v-timeline-item :color="serving1_color[num-1][2]"></v-timeline-item>
                                  <v-timeline-item :color="serving1_color[num-1][3]"></v-timeline-item>
                                  <v-timeline-item :color="serving1_color[num-1][4]"></v-timeline-item>
                                  <v-timeline-item :color="serving1_color[num-1][5]"></v-timeline-item>
                                  <v-timeline-item :color="serving1_color[num-1][6]"></v-timeline-item>
                                </v-timeline>
                              </v-col>
                              <v-col>
                                <v-timeline align-top dense reverse>
                                  <v-timeline-item :color="serving2_color[num-1][0]">
                                    <div style="margin-top: 8px; margin-left: -60px;">Sunday</div>
                                  </v-timeline-item>
                                  <v-timeline-item :color="serving2_color[num-1][1]">
                                    <div style="margin-top: 8px; margin-left: -60px;">Monday</div>
                                  </v-timeline-item>
                                  <v-timeline-item :color="serving2_color[num-1][2]">
                                    <div style="margin-top: 8px; margin-left: -60px;">Tuesday</div>
                                  </v-timeline-item>
                                  <v-timeline-item :color="serving2_color[num-1][3]">
                                    <div style="margin-top: 8px; margin-left: -60px;">Wednesday</div>
                                  </v-timeline-item>
                                  <v-timeline-item :color="serving2_color[num-1][4]">
                                    <div style="margin-top: 8px; margin-left: -60px;">Thursday</div>
                                  </v-timeline-item>
                                  <v-timeline-item :color="serving2_color[num-1][5]">
                                    <div style="margin-top: 8px; margin-left: -60px;">Friday</div>
                                  </v-timeline-item>
                                  <v-timeline-item :color="serving2_color[num-1][6]">
                                    <div style="margin-top: 8px; margin-left: -60px;">Saturday</div>
                                  </v-timeline-item>
                                </v-timeline>
                              </v-col>
                            </v-row>
                          </v-col>
                        </v-row>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-tab-item>
          </v-tabs-items>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { initializeApp } from 'firebase/app'
import {ref, getDatabase, set, onValue, get, child } from 'firebase/database'

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAxIlavv4dePmQzgrib5NgrZu0mYCOrWo4",
  authDomain: "wedding-website-4a01e.firebaseapp.com",
  projectId: "wedding-website-4a01e",
  storageBucket: "wedding-website-4a01e.appspot.com",
  messagingSenderId: "879310956944",
  appId: "1:879310956944:web:7e6e22a02a934483b76b15"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getDatabase(app)
const dbRef = ref(getDatabase())

export default {
  name: "ChallengesView",
  data() {
    return {
      tab: 0,
      date: '',
      morning: [],
      evening: [],
      gym: [],
      serving1: [],
      serving2: [],
      morning_submitted: [false, false],
      evening_submitted: [false, false],
      gym_submitted: [false, false],
      serving1_submitted: [false, false],
      serving2_submitted: [false, false],
      morning_skincare_color: [
          ["grey", "grey", "grey", "grey", "grey", "grey", "grey"],
          ["grey", "grey", "grey", "grey", "grey", "grey", "grey"]
      ],
      evening_skincare_color: [
          ["grey", "grey", "grey", "grey", "grey", "grey", "grey"],
          ["grey", "grey", "grey", "grey", "grey", "grey", "grey"]
      ],
      workout_color: [
          ["grey", "grey", "grey", "grey", "grey", "grey", "grey"],
          ["grey", "grey", "grey", "grey", "grey", "grey", "grey"]
      ],
      serving1_color: [
          ["grey", "grey", "grey", "grey", "grey", "grey", "grey"],
          ["grey", "grey", "grey", "grey", "grey", "grey", "grey"]
      ],
      serving2_color: [
          ["grey", "grey", "grey", "grey", "grey", "grey", "grey"],
          ["grey", "grey", "grey", "grey", "grey", "grey", "grey"]
      ],
    }
  },
  mounted: function() {
    if (this.locked) {
      this.$router.push({ name: 'home' })
    }
    this.date = this.getDateString(false)
    for (let x = 0; x <= 1; x++) {
      this.morningSubmittedToday(x)
      this.eveningSubmittedToday(x)
      this.gymSubmittedToday(x)
      this.serving1SubmittedToday(x)
      this.serving2SubmittedToday(x)
      this.refreshTrackers(x)
    }
  },
  props: {
    locked: {
      type: Boolean,
      default: true
    }
  },

  methods: {
    goToLocation(location) {
      this.$router.push({ name: location, params: { lock: false } })
    },
    getDateString(yearFirst, day_offset = 0) {
      let today = this.addDays(new Date(), day_offset)
      // today = this.subtractHours(today, hour_buffer)
      if (yearFirst) {
        return today.getFullYear() + "/" + (today.getMonth()+1) + "/" + today.getDate()
      }
      return (today.getMonth()+1) + "/" + today.getDate() + "/" + today.getFullYear()
    },
    addDays(date, days) {
      let result = new Date(date)
      result.setDate(result.getDate() + days)
      return result
    },
    subtractHours(date, hours) {
      let result = new Date(date)
      result.setDate(result.getHours() - hours)
      return result
    },
    morningSubmittedToday(num) {
      let name = num === 0 ? "Hannah" : "Mitch"
      const submitted = ref(db, 'daily_challenges/' + name + '/' + this.getDateString(true) + '/morning')
      onValue(submitted, (snapshot) => {
        const data = snapshot.val()
        if (data != null) {
          this.morning_submitted[num] = true
          let temp = this.morning_submitted
          this.morning_submitted = []
          this.morning_submitted = temp
          this.morning[num] = data
          let temp2 = this.morning
          this.morning = []
          this.morning = temp2
        } else {
          this.morning_submitted[num] = false
          let temp = this.morning_submitted
          this.morning_submitted = []
          this.morning_submitted = temp
        }
      })
    },
    eveningSubmittedToday(num) {
      let name = num === 0 ? "Hannah" : "Mitch"
      const submitted = ref(db, 'daily_challenges/' + name + '/' + this.getDateString(true) + '/evening')
      onValue(submitted, (snapshot) => {
        const data = snapshot.val()
        if (data != null) {
          this.evening_submitted[num] = true
          let temp = this.evening_submitted
          this.evening_submitted = []
          this.evening_submitted = temp
          this.evening[num] = data
          let temp2 = this.evening
          this.evening = []
          this.evening = temp2
        } else {
          this.evening_submitted[num] = false
          let temp = this.evening_submitted
          this.evening_submitted = []
          this.evening_submitted = temp
        }
      })
    },
    gymSubmittedToday(num) {
      let name = num === 0 ? "Hannah" : "Mitch"
      const submitted = ref(db, 'daily_challenges/' + name + '/' + this.getDateString(true) + '/gym')
      onValue(submitted, (snapshot) => {
        const data = snapshot.val()
        if (data != null) {
          this.gym_submitted[num] = true
          let temp = this.gym_submitted
          this.gym_submitted = []
          this.gym_submitted = temp
          this.gym[num] = data
          let temp2 = this.gym
          this.gym = []
          this.gym = temp2
        } else {
          this.gym_submitted[num] = false
          let temp = this.gym_submitted
          this.gym_submitted = []
          this.gym_submitted = temp
        }
      })
    },
    serving1SubmittedToday(num) {
      let name = num === 0 ? "Hannah" : "Mitch"
      const submitted = ref(db, 'daily_challenges/' + name + '/' + this.getDateString(true) + '/serving1')
      onValue(submitted, (snapshot) => {
        const data = snapshot.val()
        if (data != null) {
          this.serving1_submitted[num] = true
          let temp = this.serving1_submitted
          this.serving1_submitted = []
          this.serving1_submitted = temp
          this.serving1[num] = data
          let temp2 = this.serving1
          this.serving1 = []
          this.serving1 = temp2
        } else {
          this.serving1_submitted[num] = false
          let temp = this.serving1_submitted
          this.serving1_submitted = []
          this.serving1_submitted = temp
        }
      })
    },
    serving2SubmittedToday(num) {
      let name = num === 0 ? "Hannah" : "Mitch"
      const submitted = ref(db, 'daily_challenges/' + name + '/' + this.getDateString(true) + '/serving2')
      onValue(submitted, (snapshot) => {
        const data = snapshot.val()
        if (data != null) {
          this.serving2_submitted[num] = true
          let temp = this.serving2_submitted
          this.serving2_submitted = []
          this.serving2_submitted = temp
          this.serving2[num] = data
          let temp2 = this.serving2
          this.serving2 = []
          this.serving2 = temp2
        } else {
          this.serving2_submitted[num] = false
          let temp = this.serving2_submitted
          this.serving2_submitted = []
          this.serving2_submitted = temp
        }
      })
    },
    submitMorning(num) {
      let name = num === 0 ? "Hannah" : "Mitch"
      if (this.morning[num] != undefined) {
        set(ref(db, 'daily_challenges/' + name + "/" + this.getDateString(true) + "/morning"), this.morning[num])
        this.morning_submitted[num] = true
        let temp = this.morning_submitted
        this.morning_submitted = []
        this.morning_submitted = temp
        this.refreshTrackers(num)
      }
    },
    submitEvening(num) {
      let name = num === 0 ? "Hannah" : "Mitch"
      if (this.evening[num] != undefined) {
        set(ref(db, 'daily_challenges/' + name + "/" + this.getDateString(true) + "/evening"), this.evening[num])
        this.evening_submitted[num] = true
        let temp = this.evening_submitted
        this.evening_submitted = []
        this.evening_submitted = temp
      }
      this.refreshTrackers(num)
    },
    submitGym(num) {
      let name = num === 0 ? "Hannah" : "Mitch"
      if (this.gym[num] != undefined) {
        set(ref(db, 'daily_challenges/' + name + "/" + this.getDateString(true) + "/gym"), this.gym[num])
        this.gym_submitted[num] = true
        let temp = this.gym_submitted
        this.gym_submitted = []
        this.gym_submitted = temp
      }
      this.refreshTrackers(num)
    },
    submitServing1(num) {
      let name = num === 0 ? "Hannah" : "Mitch"
      if (this.serving1[num] != undefined) {
        set(ref(db, 'daily_challenges/' + name + "/" + this.getDateString(true) + "/serving1"), this.serving1[num])
        this.serving1_submitted[num] = true
        let temp = this.serving1_submitted
        this.serving1_submitted = []
        this.serving1_submitted = temp
      }
      this.refreshTrackers(num)
    },
    submitServing2(num) {
      let name = num === 0 ? "Hannah" : "Mitch"
      if (this.serving2[num] != undefined) {
        set(ref(db, 'daily_challenges/' + name + "/" + this.getDateString(true) + "/serving2"), this.serving2[num])
        this.serving2_submitted[num] = true
        let temp = this.serving2_submitted
        this.serving2_submitted = []
        this.serving2_submitted = temp
      }
      this.refreshTrackers(num)
    },
    editMorning(num) {
      this.morning_submitted[num] = false
      let temp = this.morning_submitted
      this.morning_submitted = []
      this.morning_submitted = temp
    },
    editEvening(num) {
      this.evening_submitted[num] = false
      let temp = this.evening_submitted
      this.evening_submitted = []
      this.evening_submitted = temp
    },
    editGym(num) {
      this.gym_submitted[num] = false
      let temp = this.gym_submitted
      this.gym_submitted = []
      this.gym_submitted = temp
    },
    editServing1(num) {
      this.serving1_submitted[num] = false
      let temp = this.serving1_submitted
      this.serving1_submitted = []
      this.serving1_submitted = temp
    },
    editServing2(num) {
      this.serving2_submitted[num] = false
      let temp = this.serving2_submitted
      this.serving2_submitted = []
      this.serving2_submitted = temp
    },
    refreshTrackers(num) {
      let name = num === 0 ? "Hannah" : "Mitch"
      let date = new Date()
      let day_of_week = date.getDay()
      for (let x = 0; x <= 6; x++) {
        let new_date = this.getDateString(true, x - day_of_week)
        get(child(dbRef, `daily_challenges/${name}/${new_date}/morning`)).then((snapshot) => {
          if (snapshot.exists()) {
            if (snapshot.val() === "completed") {
              this.morning_skincare_color[num][x] = "green"
            } else {
              this.morning_skincare_color[num][x] = "red"
            }
          } else {
            if (x < day_of_week) {
              this.morning_skincare_color[num][x] = "red"
            }
          }
          let temp = this.morning_skincare_color
          this.morning_skincare_color = []
          this.morning_skincare_color = temp
        })
        get(child(dbRef, `daily_challenges/${name}/${new_date}/evening`)).then((snapshot) => {
          if (snapshot.exists()) {
            if (snapshot.val() === "completed") {
              this.evening_skincare_color[num][x] = "green"
            } else {
              this.evening_skincare_color[num][x] = "red"
            }
          } else {
            if (x < day_of_week) {
              this.evening_skincare_color[num][x] = "red"
            }
          }
          let temp = this.evening_skincare_color
          this.evening_skincare_color = []
          this.evening_skincare_color = temp
        })
        get(child(dbRef, `daily_challenges/${name}/${new_date}/gym`)).then((snapshot) => {
          if (snapshot.exists()) {
            if (snapshot.val() === "completed") {
              this.workout_color[num][x] = "green"
            } else {
              this.workout_color[num][x] = "red"
            }
          } else {
            if (x < day_of_week) {
              this.workout_color[num][x] = "red"
            }
          }
          let temp = this.workout_color
          this.workout_color = []
          this.workout_color = temp
        })
        get(child(dbRef, `daily_challenges/${name}/${new_date}/serving1`)).then((snapshot) => {
          if (snapshot.exists()) {
            if (snapshot.val() === "completed") {
              this.serving1_color[num][x] = "green"
            } else {
              this.serving1_color[num][x] = "red"
            }
          } else {
            if (x < day_of_week) {
              this.serving1_color[num][x] = "red"
            }
          }
          let temp = this.serving1_color
          this.serving1_color = []
          this.serving1_color = temp
        })
        get(child(dbRef, `daily_challenges/${name}/${new_date}/serving2`)).then((snapshot) => {
          if (snapshot.exists()) {
            if (snapshot.val() === "completed") {
              this.serving2_color[num][x] = "green"
            } else {
              this.serving2_color[num][x] = "red"
            }
          } else {
            if (x < day_of_week) {
              this.serving2_color[num][x] = "red"
            }
          }
          let temp = this.serving2_color
          this.serving2_color = []
          this.serving2_color = temp
        })
      }
    }
  }
}
</script>

<style scoped>
.txt-white {
  color: white;
}
</style>