<template>
  <v-row justify="center" align="center">
    <v-col align="center" justify="center">
      <v-row>
        <v-col cols="12" sm="1" style="margin-top: 20px;">
          <v-icon x-large @click="goToLocation('budget')">
            mdi-arrow-left
          </v-icon>
        </v-col>
        <v-col align="left" style="padding-left: 50px;">
          <div style="font-size: 40pt">
            Budget
          </div>
        </v-col>
        <v-col style="margin-top: 20px;">
          <v-btn
              style="width: 100px; height: 50px;"
              class="submit ibarra txt-white bold"
              color="blue"
              @click="goToLocation('budget-builder')"
            >
            <v-icon>mdi-clipboard-text-clock</v-icon>
            </v-btn>
        </v-col>
        <v-col style="margin-top: 20px;">
          <v-btn
              style="width: 100px; height: 50px;"
              class="submit ibarra txt-white bold"
              color="orange"
              @click="goToLocation('house')"
            >
            <v-icon>mdi-home</v-icon>
            </v-btn>
        </v-col>
        <v-col cols="12" sm="2" style="margin-top: 20px;">
          <v-select
              :items="year_items"
              v-model="year"
              outlined
              label="Year"
              style="max-width: 100px;"
          >
          </v-select>
        </v-col>
        <v-col cols="12" sm="4" style="margin-top: 20px;">
          <v-select
              :items="month_items"
              item-text="name"
              item-value="val"
              v-model="month"
              outlined
              label="Month"
              style="max-width: 200px;"
          >
          </v-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-divider style="margin-left: 40px; margin-right: 40px; margin-top: -10px; margin-bottom: -10px;"></v-divider>
        </v-col>
      </v-row>
      <v-row>
        <v-col v-for="budget in budgets" v-bind:key="budget['name']" style="margin-top: 50px;">
          <BudgetCategoryCard
              :budget="budget['budget']"
              :spent="categorySpent(capitalizeFirstLetter(budget['name']))"
              :color="budget['color']"
              :icon="budget['icon']"
              :category="budget['name']"
              :transactions="categoryTransactions(capitalizeFirstLetter(budget['name']))"
              :month="month"
              :year="year"
          ></BudgetCategoryCard>
        </v-col>
        <v-col style="margin-top: 50px;">
          <BudgetCategoryCard
              :budget="all_budget"
              :spent="all_spent"
              :color="'blue-grey'"
              :icon="'mdi-star'"
              :category="'all'"
              :transactions="all_transactions"
              :month="month"
              :year="year"
          ></BudgetCategoryCard>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import BudgetCategoryCard from "@/components/BudgetCategoryCard";

import * as api from "@/views/Budget/LoadingAndSavingAPIs";
import {child, get, getDatabase, ref} from "firebase/database";
import { initializeApp } from 'firebase/app'

const firebaseConfig = {
  apiKey: "AIzaSyAxIlavv4dePmQzgrib5NgrZu0mYCOrWo4",
  authDomain: "wedding-website-4a01e.firebaseapp.com",
  projectId: "wedding-website-4a01e",
  storageBucket: "wedding-website-4a01e.appspot.com",
  messagingSenderId: "879310956944",
  appId: "1:879310956944:web:7e6e22a02a934483b76b15"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getDatabase(app)
const dbRef = ref(db);

export default {
  name: "OverallBudgetView",

  components: {
    BudgetCategoryCard
  },

  data() {
    return {
      month: '',
      month_items: [
        {
          name: 'January',
          val: '1',
        },
        {
          name: 'February',
          val: '2',
        },
        {
          name: 'March',
          val: '3',
        },
        {
          name: 'April',
          val: '4',
        },
          {
          name: 'May',
          val: '5',
        },
          {
          name: 'June',
          val: '6',
        },
          {
          name: 'July',
          val: '7',
        },
          {
          name: 'August',
          val: '8',
        },
          {
          name: 'September',
          val: '9',
        },
          {
          name: 'October',
          val: '10',
        },
        {
          name: 'November',
          val: '11',
        },
        {
          name: 'December',
          val: '12',
        },
        {
          name: 'Yearly Expenses',
          val: '13',
        },
      ],
      year: '',
      year_items: [
          '2022',
          '2023',
          '2024',
          '2025',
          '2026',
          '2027',
          '2028',
          '2029',
          '2030'
      ],
      budgets: [],
      all_transactions: [],
      initial_year: false,
      initial_month: false
    }
  },

  watch: {
    year() {
      if (this.initial_year) {
        this.refreshData()
      } else {
        this.initial_year = true
      }
    },
    month() {
      if (this.initial_month) {
        this.refreshData()
      } else {
        this.initial_month = true
      }
    }
  },

  mounted: function() {
    if (this.locked) {
      this.$router.push({name: 'budget'})
    }
    this.month = this.month_prop
    this.year = this.year_prop
    this.getDates()
    this.refreshData()
  },

  computed: {
    all_budget() {
      let total = 0
      for (let index in this.budgets) {
        total += this.budgets[index].budget
      }
      return total
    },
    all_spent() {
      let total = 0
      for (let index in this.all_transactions) {
        if (this.all_transactions[index].returned) {
          continue
        }
        total += parseFloat(this.all_transactions[index].price)
      }
      return total
    },
  },

  props: {
    locked: {
      type: Boolean,
      default: true
    },
    month_prop: {
      type: String,
      default: ''
    },
    year_prop: {
      type: String,
      default: ''
    }
  },

  methods: {
    goToLocation(location) {
      this.$router.push({ name: location, params: { locked: false } })
    },
    getDates() {
      let today = new Date()
      if (this.year == '') {
        this.year = today.getFullYear().toString()
      }
      if (this.month == '') {
        this.month = (today.getMonth() + 1).toString()
      }
    },
    refreshData() {
      let vacation = this.month == 13
      this.clearValues()
      if (vacation) {
        this.getAllTransactionsVacation(this.year)
      } else {
        this.getAllTransactions(this.year, this.month)
      }
      this.budgets = api.getBudgets(vacation)
    },
    clearValues() {
      this.budgets = []
      this.all_transactions = []
    },
    capitalizeFirstLetter(string) {
      return api.capitalizeFirstLetter(string)
    },
    categorySpent(category) {
      let total = 0
      for (let index in this.all_transactions) {
        if (this.all_transactions[index].returned) {
          continue
        }
        if (this.all_transactions[index].category == category) {
          total += parseFloat(this.all_transactions[index].price)
        }
      }
      return total
    },
    categoryTransactions(category) {
      let transactions = []
      for (let index in this.all_transactions) {
        if (this.all_transactions[index].category == category) {
          transactions.push(this.all_transactions[index])
        }
      }
      return transactions
    },
    getAllTransactions(year, month) {
      for (let day = 1; day <= 31; day++) {
        get(child(dbRef, `budget/transactions/${api.getYearAndMonthString(year, month)}/${day}/count`)).then((snapshot) => {
          if (snapshot.exists()) {
            for (let transaction = 1; transaction <= snapshot.val(); transaction++) {
              get(child(dbRef, `budget/transactions/${api.getYearAndMonthString(year, month)}/${day}/${transaction}`)).then((snapshot) => {
                if (snapshot.exists()) {
                  let single_transaction = snapshot.val()
                  if (!single_transaction.deleted) {
                    single_transaction.number = transaction

                    // Add to list
                    this.all_transactions.push(single_transaction)
                  }
                }
              })
            }
          }
        })
      }
    },
    getAllTransactionsVacation(year) {
      for (let month = 1; month <= 12; month++) {
        for (let day = 1; day <= 31; day++){
          get(child(dbRef, `budget/transactions/vacation/${year}/${month.toString()}/${day}/count`)).then((snapshot) => {
            if (snapshot.exists()) {
              for (let transaction = 1; transaction <= snapshot.val(); transaction++) {
                get(child(dbRef, `budget/transactions/vacation/${year}/${month.toString()}/${day}/${transaction}`)).then((snapshot) => {
                  if (snapshot.exists()) {
                    let single_transaction = snapshot.val()
                    if (!single_transaction.deleted) {
                      single_transaction.number = transaction

                      // Add to list
                      this.all_transactions.push(single_transaction)
                    }
                  }
                })
              }
            }
          })
        }
      }
    }
  }
}
</script>

<style scoped>
.txt-white {
  color: white;
}
</style>