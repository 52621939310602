<template>
<!--  <v-row style="padding: 30px;">-->
<!--    <v-col align="center">-->
<!--      <v-row>-->
<!--        <v-col>-->
<!--          <div class="ibarra txt-large">-->
<!--            Hannah and Chloe-->
<!--          </div>-->
<!--        </v-col>-->
<!--      </v-row>-->
<!--      <v-divider></v-divider>-->
<!--      <v-row style="margin-top: 15px;">-->
<!--        <v-col>-->
<!--          <img src="@/assets/hanandchloe.jpg" class="home-img">-->
<!--        </v-col>-->
<!--      </v-row>-->
<!--    </v-col>-->
<!--  </v-row>-->
  <v-row style="margin-left: 100px; margin-right: 100px; margin-top: 50px;">
    <v-col>
      <v-row>
        <v-col align="center">
          <div style="font-size: 30pt;">
            Pick a card! (Carefully)
          </div>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col align="center">
          <v-card class="select" @click="getDate(1)">
            <v-row justify="center">
              <v-col align="center">
                <div style="font-size: 100pt;" id="option1">
                  {{ option1 }}
                </div>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
        <v-col align="center">
          <v-card class="select" @click="getDate(2)">
            <v-row justify="center">
              <v-col align="center">
                <div style="font-size: 100pt;" id="option2">
                  {{ option2 }}
                </div>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col align="center">
          <v-card class="select" @click="getDate(3)">
            <v-row justify="center">
              <v-col align="center">
                <div style="font-size: 100pt;" id="option3">
                  {{ option3 }}
                </div>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { initializeApp } from 'firebase/app'
import { ref, getDatabase, set } from 'firebase/database'

// Import the functions you need from the SDKs you need
//import firebase from "firebase";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAxIlavv4dePmQzgrib5NgrZu0mYCOrWo4",
  authDomain: "wedding-website-4a01e.firebaseapp.com",
  projectId: "wedding-website-4a01e",
  storageBucket: "wedding-website-4a01e.appspot.com",
  messagingSenderId: "879310956944",
  appId: "1:879310956944:web:7e6e22a02a934483b76b15"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getDatabase(app)
export default {
  name: "HanAndChloe",

  data() {
    return {
      disabled: false,
      option1: "1",
      option2: "2",
      option3: "3"
    }
  },


  methods: {
    getDate(option) {
      if (this.disabled) {
        return
      }

      if (option === 1) {
        this.option1 = "Ohio State! (Weekend of October 29th)"
        document.getElementById("option1").style.fontSize = "45pt"
      } else if (option === 2) {
        this.option2 = "Minnesota! (Weekend of October 22nd)"
        document.getElementById("option2").style.fontSize = "45pt"
      } else {
        this.option3 = "Northwestern! (Weekend of October 1st)"
        document.getElementById("option3").style.fontSize = "45pt"
      }

      set(ref(db, 'han_choice/' + Date.now()), {
        selection: option
      })
      this.disabled = true
    }
  }
}
</script>

<style scoped>
.home-img {
  max-width: 350px;
}
.ibarra {
  font-family: 'Ibarra Real Nova', serif;
  color: dimgray;
}
.txt-large {
  font-size: 40px;
}
.select {
  width: 500px;
  height: 300px;
}
.select:hover {
  cursor: pointer;
  color: dimgray;
  background-color: lightgray;
}
</style>