<template>
  <v-row>
    <v-col>
      <v-form v-if="locked_val" @submit.prevent="validatePassword">
        <v-row style="padding-left: 30px; padding-right: 30px; padding-top: 30px;">
          <v-col align="center">
            <v-text-field
              style="max-width: 500px;"
              v-model="password"
              label="Password"
              outlined
              type="password"
              autofocus
              autocomplete="current-password"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col align="center">
            <v-btn
                class="ibarra txt-white bold"
                color="#9CAF88"
                type="submit"
            >
              Submit
            </v-btn>
          </v-col>
        </v-row>
      </v-form>

      <div v-if="!locked_val">
        <v-row style="margin-top: 50px;">
          <v-col align="center">
            <div class="ibarra txt-x-large">
              Budget
            </div>
          </v-col>
        </v-row>

        <v-row style="margin-top: 50px;">
          <v-col align="center">
            <v-btn
                style="height: 150px; width: 200px;"
                class="submit ibarra txt-white bold"
                color="green"
                @click="goToLocation('budget-input')"
            >
              <v-icon x-large>
                mdi-clipboard-arrow-up-outline
              </v-icon>
            </v-btn>
          </v-col>
        </v-row>

        <v-row style="margin-top: 50px;">
          <v-col align="center">
            <v-btn
                style="height: 150px; width: 200px;"
                class="submit ibarra txt-white bold"
                color="blue"
                @click="goToLocation('budget-data')"
            >
              <v-icon x-large>
                mdi-chart-areaspline
              </v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </div>

    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "BudgetView",

  data() {
    return {
      locked_val: true,
      password: ''
    }
  },

  props: {
    locked: {
      type: Boolean,
      default: true
    }
  },

  watch: {
    password() {
      this.validatePassword(false)
    }
  },

  mounted: function() {
    this.locked_val = this.locked
  },

  methods: {
    validatePassword(del = true) {
      if (this.hash(this.password) === '33c68c85') {
        this.locked_val = false
      } else if (del) {
        this.password = ''
      }
    },
    /**
     * Creates a hashed string from plaintext password input
     */
    hash(b) {
      for (var a = 0, c = b.length; c--;) {
        a += b.charCodeAt(c)
        a += a << 10
        a ^= a >> 6
        a += a << 3
        a ^= a >> 11
      }
      return ((a + (a << 15) & 4294967295) >>> 0).toString(16)
    },
    goToLocation(location) {
      this.$router.push({ name: location , params: { locked: false } })
    }
  }
}
</script>

<style scoped>
.ibarra {
  font-family: 'Ibarra Real Nova', serif;
  color: dimgray;
}
.home-img {
  width: 90%;
  height: auto;
  object-fit: cover;
  object-position: 43% 57%
}
.location-cards {
  width: 80%;
  margin-bottom: 40px;
}
.txt-large {
  font-size: 40px;
}
.txt-x-large {
  font-size: 100px;
}
.txt-med {
  font-size: 30px;
}
.txt-small {
  font-size: 20px;
}
.txt-x-small {
  font-size: 18px;
}
.bold {
  font-weight: bold;
}
.justify {
  text-align: justify;
  text-justify: inter-word;
}
.circle {
  width: 500px;
  height: 500px;
  line-height: 500px;
  border-radius: 50%;
  font-size: 50px;
  color: #fff;
  text-align: center;
  background: #000
}
.banner {
  background-color: #9CAF88;
  height: 100px;
}
.banner-mobile {
  background-color: #9CAF88;
  height: 300px;
}
img:hover {
  cursor: pointer;
}
.txt-white {
  color: white;
}
</style>