<template>
  <Wrapper>
    <v-row justify="center">
      <v-col align="center" style="max-width: 850px">
        <v-row>
          <v-col>
            <div class="ibarra txt-med justify">
              We have reserved a hotel block in downtown Pittsburgh for your convenience.
              We have 25 rooms reserved, but we can request more if/when needed.
              If you have any questions, feel free to reach out to the bride or the groom.
            </div>
            <div class="ibarra txt-med justify bold" style="margin-top:30px">
              The deadline for reserving a room (set by the hotel, not us) is September 6th.
            </div>
            <div style="margin-top: 10px;">
              <v-btn class="submit ibarra txt-white bold" color="#9CAF88" @click="goToURL('https://book.passkey.com/go/FlandersMiles')">
                Hotel Block
              </v-btn>
            </div>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <div class="ibarra txt-med justify bold">
              <v-icon style="font-size: 26px; padding-bottom: 8px; padding-right: 5px;">
                mdi-car
              </v-icon>
              Hotel Parking
            </div>
            <div class="ibarra txt-small justify" style="padding-bottom: 20px;">
              The hotel does not have any self service parking.
              They offer valet parking, but there is also nearby self service parking.
              For more information, expand the image below.
            </div>
            <v-img @click="parking_large = !parking_large" class="button" :width="parking_large ? '' : 100" src="@/assets/parking.jpg"></v-img>
          </v-col>
        </v-row>

        <v-row style="margin-top: 50px;">
          <v-col>
            <v-img src="@/assets/pittsburgh.png">
              <template v-slot:placeholder>
                <v-row
                  class="fill-height ma-0"
                  align="center"
                  justify="center"
                >
                  <v-progress-circular
                    indeterminate
                    color="grey lighten-5"
                  ></v-progress-circular>
                </v-row>
              </template>
            </v-img>
          </v-col>
        </v-row>

        <v-row style="margin-top: 10px;">
          <v-col>
            <div class="ibarra bold txt-large">
              Welcome to Pittsburgh!
            </div>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <div class="ibarra txt-med justify">
              For those of you who are visiting for the first time, we are glad to have you here.
              For those that are returning to a city that they once called home, welcome back!
              Either way, we are so happy to have you here!
              Feel free to look through some of our favorite activities to do and places to eat at in Pittsburgh, and try some out for yourself!
            </div>
          </v-col>
        </v-row>

        <v-row style="margin-top: 20px;">
          <v-col>
            <div class="ibarra txt-med-large bold">
              The Couple's Favorites
            </div>
          </v-col>
        </v-row>

        <v-row style="max-width: 708px;">
          <v-col>
            <v-row>
              <v-col>
                <v-btn height="330" width="330" @click="goToURL('https://www.dcnr.pa.gov/StateParks/FindAPark/PointStatePark/Pages/default.aspx')">
                  <v-img max-width="330" class="rounded img-hover" src="@/assets/thepoint.jpg">
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                      >
                        <v-progress-circular
                          indeterminate
                          color="grey lighten-5"
                        ></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                </v-btn>
              </v-col>
            </v-row>
            <v-row style="margin-top: -25px">
              <v-col>
                <div class="ibarra txt-small bold">
                  The Point
                </div>
              </v-col>
            </v-row>
          </v-col>

          <v-col>
            <v-row>
              <v-col>
                <v-btn height="330" width="330" @click="goToURL('https://www.stationsquare.com/')">
                  <v-img max-width="330" class="rounded img-hover" src="@/assets/stationsquare.jpg">
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                      >
                        <v-progress-circular
                          indeterminate
                          color="grey lighten-5"
                        ></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                </v-btn>
              </v-col>
            </v-row>
            <v-row style="margin-top: -25px">
              <v-col>
                <div class="ibarra txt-small bold">
                  Station Square
                </div>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row style="max-width: 708px;">
          <v-col>
            <v-row>
              <v-col>
                <v-btn height="330" width="330" @click="goToURL('https://pamelasdiner.com/')">
                  <v-img max-width="330" class="rounded img-hover" src="@/assets/pamelas.jpg">
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                      >
                        <v-progress-circular
                          indeterminate
                          color="grey lighten-5"
                        ></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                </v-btn>
              </v-col>
            </v-row>
            <v-row style="margin-top: -25px">
              <v-col>
                <div class="ibarra txt-small bold">
                  Pamela's Diner
                </div>
              </v-col>
            </v-row>
          </v-col>

          <v-col>
            <v-row>
              <v-col>
                <v-btn height="330" width="330" @click="goToURL('https://www.alleghenycounty.us/parks/hartwood/index.aspx')">
                  <v-img max-width="330" class="rounded img-hover" src="@/assets/hartwoodacres2.jpg">
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                      >
                        <v-progress-circular
                          indeterminate
                          color="grey lighten-5"
                        ></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                </v-btn>
              </v-col>
            </v-row>
            <v-row style="margin-top: -25px">
              <v-col>
                <div class="ibarra txt-small bold">
                  Hartwood Acres
                </div>
              </v-col>
            </v-row>
          </v-col>
        </v-row>

        <v-row style="margin-top: 20px;">
          <v-col>
            <div class="ibarra txt-med-large bold">
              The Burgh Classics
            </div>
          </v-col>
        </v-row>

        <v-row style="max-width: 708px;">
          <v-col>
            <v-row>
              <v-col>
                <v-btn height="330" width="330" @click="goToURL('https://primantibros.com/')">
                  <v-img max-width="330" class="rounded img-hover" src="@/assets/primantibrothers.jpg">
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                      >
                        <v-progress-circular
                          indeterminate
                          color="grey lighten-5"
                        ></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                </v-btn>
              </v-col>
            </v-row>
            <v-row style="margin-top: -25px">
              <v-col>
                <div class="ibarra txt-small bold">
                  Primanti Brothers
                </div>
              </v-col>
            </v-row>
          </v-col>

          <v-col>
            <v-row>
              <v-col>
                <v-btn height="330" width="330" @click="goToURL('https://www.visitpittsburgh.com/neighborhoods/mt-washington/')">
                  <v-img max-width="330" class="rounded img-hover" src="@/assets/mountwashington.jpg">
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                      >
                        <v-progress-circular
                          indeterminate
                          color="grey lighten-5"
                        ></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                </v-btn>
              </v-col>
            </v-row>
            <v-row style="margin-top: -25px">
              <v-col>
                <div class="ibarra txt-small bold">
                  Mount Washington
                </div>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row style="max-width: 708px;">
          <v-col>
            <v-row>
              <v-col>
                <v-btn height="330" width="330" @click="goToURL('https://www.phipps.conservatory.org/')">
                  <v-img max-width="330" class="rounded img-hover" src="@/assets/phippsconservatory.jpg">
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                      >
                        <v-progress-circular
                          indeterminate
                          color="grey lighten-5"
                        ></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                </v-btn>
              </v-col>
            </v-row>
            <v-row style="margin-top: -25px">
              <v-col>
                <div class="ibarra txt-small bold">
                  Phipps Conservatory
                </div>
              </v-col>
            </v-row>
          </v-col>

          <v-col>
            <v-row>
              <v-col>
                <v-btn height="330" width="330" @click="goToURL('https://www.visitpittsburgh.com/neighborhoods/strip-district/')">
                  <v-img max-width="330" class="rounded img-hover" src="@/assets/stripdistrict.jpg">
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                      >
                        <v-progress-circular
                          indeterminate
                          color="grey lighten-5"
                        ></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                </v-btn>
              </v-col>
            </v-row>
            <v-row style="margin-top: -25px">
              <v-col>
                <div class="ibarra txt-small bold">
                  Strip District
                </div>
              </v-col>
            </v-row>
          </v-col>
        </v-row>

      </v-col>
    </v-row>
  </Wrapper>
</template>

<script>
import Wrapper from "@/components/Wrapper";

export default {
  name: "TravelView",
  data() {
    return {
      parking_large: false
    }
  },
  components: {
    Wrapper
  },
  methods: {
    goToURL(url) {
      window.open(url, "_blank")
    }
  }
}
</script>

<style scoped>
.ibarra {
  font-family: 'Ibarra Real Nova', serif;
  color: dimgray;
}
.txt-white {
  color: white;
}
.home-img {
  width: 90%;
  height: auto;
  object-fit: cover;
  object-position: 43% 57%
}
.location-cards {
  width: 80%;
  margin-bottom: 40px;
}
.img-hover:hover {
  filter:brightness(0.75);
  transition:.2s
}
.img-hover {
  filter: brightness(1.0);
  transition: .2s;
}
.txt-large {
  font-size: 40px;
}
.txt-med-large {
  font-size: 32px;
}
.txt-med {
  font-size: 25px;
}
.txt-small {
  font-size: 20px;
}
.txt-x-small {
  font-size: 18px;
}
.bold {
  font-weight: bold;
}
.justify {
  text-align: justify;
  text-justify: inter-word;
}
.button:hover {
  cursor: pointer;
}
.button {
  border-style: solid;
  border-radius: 5px;
}
</style>