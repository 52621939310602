<template>
  <v-row align="center" justify="center">
    <v-col align="center" justify="center">


      <v-row v-if="!locked & !isMobile" class="banner" align="center">
        <v-col justify="center" cols="12" sm="1">
          <img src="@/assets/ring.png" style="width: 50px; padding-top: 20px;" @click="goToLocation('home')">
        </v-col>
        <v-col cols="12" sm="3" justify="left">
          <div class="ibarra txt-large" style="padding-top: 10px; color: white">
            Dear Hannah,
          </div>
        </v-col>
        <v-col align="right" style="margin-right: 30px; margin-top: 10px;">
          <v-btn @click="goToChallenges">
            Challenges
          </v-btn>
        </v-col>
      </v-row>
      <v-row v-if="!locked & isMobile" class="banner-mobile" align="center">
        <v-col justify="center" cols="12" sm="1" style="margin-left: 5px;">
          <img src="@/assets/ring.png" style="width: 50px; padding-top: 20px;" @click="goToLocation('home')">
        </v-col>
        <v-col cols="12" sm="3" style="margin-left: 5px;">
          <div class="ibarra txt-large" style="padding-top: 10px; color: white">
            Dear Hannah,
          </div>
        </v-col>
        <v-col align="center" style="margin-top: 10px; margin-left: 15px;">
          <v-btn @click="goToChallenges">
            Challenges
          </v-btn>
        </v-col>
      </v-row>

      <v-row v-if="locked" style="padding: 30px">
        <v-col align="center">
          <v-row>
            <v-col>
              <v-text-field
                  style="max-width: 500px;"
                  v-model="password"
                  label="Password"
                  outlined
                  type="password"
                  @keydown.enter="validatePassword"
                  autofocus
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-btn class="submit ibarra txt-white bold" color="#9CAF88" @click="validatePassword">Submit</v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>


      <v-row v-if="!locked" align="center" justify="center" style="max-width:1100px;padding: 30px">
        <v-col align="center" justify="center">

          <v-row>
            <v-col style="max-width: 1100px; padding-bottom: 20px;" align="center" justify="center">
              <v-card style="min-width: 300px;">
                <v-row align="center" justify="center">
                  <v-col align="center" justify="center">
                    <div class="ibarra txt-x-large" style="color: red">
                      {{ daysToGo }}
                    </div>
                  </v-col>
                  <v-col style="padding-right: 40px;">
                    <div class="ibarra txt-sm-med">
                      DAYS UNTIL WE SEE EACH OTHER IN PERSON AGAIN!
                    </div>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
            <v-col style="max-width: 1100px" align="center" justify="center">
              <v-card>
                <v-row align="center" justify="center">
                  <v-col align="center" justify="center">
                    <div class="ibarra txt-x-large" style="color: seagreen">
                      {{ daysToMoveIn }}
                    </div>
                  </v-col>
                  <v-col style="padding-right: 40px;">
                    <div class="ibarra txt-sm-med">
                      DAYS UNTIL HANNAH MOVES IN!
                    </div>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
          </v-row>

          <v-divider style="margin-top: 60px;"></v-divider>

<!--          <v-row v-if="next" style="max-width: 1100px; margin-top: 50px;">-->
          <v-row style="max-width: 1100px; margin-top: 50px;">
            <v-col style="padding-bottom: 20px;">
              <v-card style="min-width: 300px;">
                <v-row style="padding: 10px;">
                  <v-col class="justify">
                    <div class="ibarra bold txt-med" style="padding-left: 15px; padding-right: 15px;">
                      Mitch's Message
                    </div>
                    <br>
                    <div class="ibarra txt-small" style="padding-left: 15px; padding-right: 15px;">
                      {{ mitch_message }}
                    </div>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
            <v-col>
              <v-card>
                <v-row style="padding: 10px;">
                  <v-col class="justify">
                    <div class="ibarra bold txt-med" style="padding-left: 15px; padding-right: 15px;">
                      Mitch's Activity
                    </div>
                    <br>
                    <div class="ibarra txt-small" style="padding-left: 15px; padding-right: 15px;">
                      {{ mitch_challenge }}
                    </div>
<!--                    <br>-->
<!--                    <div class="ibarra txt-small" style="padding-left: 15px; padding-right: 15px;">-->
<!--                      Also, your backup activity is to make sure your lineup is set before tomorrow lol.-->
<!--                    </div>-->
<!--                    <br>-->
<!--                    <div class="ibarra txt-small" style="padding-left: 15px; padding-right: 15px;">-->
<!--                      <a-->
<!--                          href="https://open.spotify.com/track/3yII7UwgLF6K5zW3xad3MP?si=13Pq_WI7RZKMbA4wDkJTrA&context=spotify%3Asearch%3A22"-->
<!--                          style="text-decoration: none" target="_blank"-->
<!--                      >-->
<!--                        Song</a>-->
<!--                    </div>-->
<!--                    <br>-->
<!--                    <br>-->
<!--                    <div class="ibarra txt-small" style="padding-left: 15px; padding-right: 15px;">-->
<!--                      When you're done listening, play this for some fun.-->
<!--                    </div>-->
<!--                    <br>-->
<!--                    <div class="ibarra txt-small" style="padding-left: 15px; padding-right: 15px;">-->
<!--                      <a-->
<!--                          href="https://www.sporcle.com/games/Rackie/taylor_swift_albums"-->
<!--                          style="text-decoration: none" target="_blank"-->
<!--                      >-->
<!--                        Fun</a>-->
<!--                    </div>-->
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
          </v-row>

          <v-divider style="margin-top: 60px;"></v-divider>

          <v-row style="max-width: 1100px; margin-top: 50px;">
            <v-col style="padding-bottom: 20px;">
              <v-card style="min-width: 300px;">
                <v-row style="padding: 10px;">
                  <v-col class="justify">
                    <div class="ibarra bold txt-med" style="padding-left: 15px; padding-right: 15px;">
                      Hannah's Message
                    </div>
                    <br>
                    <div v-if="message_submitted" class="ibarra txt-small" style="padding-left: 15px; padding-right: 15px;">
                      {{ han_message }}
                    </div>
                    <v-textarea
                        v-if="!message_submitted"
                        counter
                        maxlength="500"
                        class="ibarra"
                        v-model="han_message"
                        outlined
                        style="margin-left: 15px; margin-right: 15px;"
                    ></v-textarea>
                    <v-btn v-if="!message_submitted" style="margin-left: 15px;" class="submit ibarra txt-white bold" color="#9CAF88" @click="submitMessage">Submit</v-btn>
                    <v-btn v-if="message_submitted" style="margin-left: 15px; margin-top: 30px;" class="submit ibarra txt-white bold" color="#9CAF88" @click="editMessage">
                      <v-icon>
                        mdi-pencil
                      </v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
            <v-col>
              <v-card>
                <v-row style="padding: 10px;">
                  <v-col class="justify">
                    <div class="ibarra bold txt-med" style="padding-left: 15px; padding-right: 15px;">
                      Challenge of the Day
                    </div>
                    <br>
                    <div v-if="challenge_submitted" class="ibarra txt-small" style="padding-left: 15px; padding-right: 15px;">
                      {{ han_challenge }}
                    </div>
                    <v-textarea
                        v-if="!challenge_submitted"
                        counter
                        maxlength="500"
                        class="ibarra"
                        v-model="han_challenge"
                        outlined
                        style="margin-left: 15px; margin-right: 15px;"
                    ></v-textarea>
                    <v-btn v-if="!challenge_submitted" style="margin-left: 15px;" class="submit ibarra txt-white bold" color="#9CAF88" @click="submitChallenge">Submit</v-btn>
                    <v-btn v-if="challenge_submitted" style="margin-left: 15px; margin-top: 30px;" class="submit ibarra txt-white bold" color="#9CAF88" @click="editChallenge">
                      <v-icon>
                        mdi-pencil
                      </v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
          </v-row>

          <v-divider style="margin-top: 60px;"></v-divider>

          <v-row style="max-width: 1100px; margin-top: 50px;">
            <v-col>
              <v-card>
                <v-row style="padding: 10px;">
                  <v-col class="justify">
                    <div class="ibarra bold txt-med" style="padding-left: 15px; padding-right: 15px;">
                      RSVP Settings (Be careful, these settings are instant)
                      <v-btn
                          class="submit ibarra txt-white bold"
                          style="float: right"
                          color="#9CAF88"
                          @click="goToRSVPSummary"
                      >
                        RSVP Summary
                      </v-btn>
                    </div>
                    <br>
                    <div style="padding-left: 15px; padding-right: 15px;">
                      <v-radio-group class="ibarra bold" v-model="rsvp_lock_status">
                        <v-radio color="#9CAF88" label="Locked (RSVPs are not being accepted)" value="1"></v-radio>
                        <v-spacer style="height: 15px;"></v-spacer>
                        <v-radio color="#9CAF88" label="Unlocked (RSVPs are being accepted)" value="2"></v-radio>
                        <v-spacer style="height: 15px;"></v-spacer>
                        <v-radio color="#9CAF88" label="Text (Must text to RSVP)" value="3"></v-radio>
                      </v-radio-group>
                    </div>
<!--                    <div style="padding-left: 15px; padding-right: 15px;">-->
<!--                      <v-switch color="#9CAF88" v-model="rsvp_unlocked"></v-switch>-->
<!--                      <h4 v-if="rsvp_unlocked" class="ibarra">RSVPs are currently open!</h4>-->
<!--                      <h4 v-else class="ibarra">RSVPs are currently closed.</h4>-->
<!--                    </div>-->
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
          </v-row>

        </v-col>
      </v-row>
      <div>
        <v-snackbar
          v-model="snackbar"
          :color="snackbar_color"
        >
          {{ snackbar_text }}

          <template v-slot:action="{ attrs }">
            <v-btn
              text
              v-bind="attrs"
              @click="snackbar = false"
            >
              Close
            </v-btn>
          </template>
        </v-snackbar>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import {ref, getDatabase, set, onValue, get, child } from 'firebase/database'

const db = getDatabase()
const dbRef = ref(getDatabase())

export default {
  name: "Day3View",

  data() {
    return {
      snackbar: false,
      snackbar_text: '',
      snackbar_color: '',
      locked: true,
      password: '',
      next: false,
      han_message: '',
      han_challenge: '',
      message_submitted: false,
      challenge_submitted: false,
      mitch_message: '',
      mitch_challenge: '',
      rsvp_lock_status: 1,
      next_date: '',
      movein_date: '',
    }
  },
  props: {
    lock: {
      type: Boolean,
      default: true
    },
  },

  mounted() {
    this.checkRSVPLock()
    this.getDaysToGo()
    this.getDaysToMoveIn()
    this.next = this.nextDay()
    this.messageSubmittedToday()
    this.challengeSubmittedToday()
    this.getMitchMessages()
    this.locked = this.lock
  },

  computed: {
    /**
     * Returns whether a device is mobile
     */
    isMobile() {
      return window.innerWidth < 962
    },
    daysToGo() {
      let currentDate = new Date()
      let weddingDate = new Date(this.next_date)

      let difference = weddingDate.getTime() - currentDate.getTime()

      return Math.ceil(difference / (1000 * 3600 * 24))
    },
    daysToMoveIn() {
      let currentDate = new Date()
      let moveInDate = new Date(this.movein_date)

      let difference = moveInDate.getTime() - currentDate.getTime()

      return Math.ceil(difference / (1000 * 3600 * 24))
    }
  },

  watch: {
    rsvp_lock_status() {
      set(ref(db, 'rsvp_lock_status'), this.rsvp_lock_status)
    }
  },

  methods: {
    goToRSVPSummary() {
      this.$router.push({ name: 'rsvp-summary' })
    },
    checkRSVPLock() {
      const rsvpstatus = ref(db, 'rsvp_lock_status');
      onValue(rsvpstatus, (snapshot) => {
        const data = snapshot.val()
        this.rsvp_lock_status = data
      })
    },
    getDateString() {
      const today = new Date()
      return today.getFullYear() + "/" + (today.getMonth()+1) + "/" + today.getDate()
    },
    getDaysToGo() {
      get(child(dbRef, 'next_date')).then((snapshot) => {
        if (snapshot.exists()) {
          const date = snapshot.val()
          this.next_date = date
        }
      })
    },
    getDaysToMoveIn() {
      get(child(dbRef, 'movein_date')).then((snapshot) => {
        if (snapshot.exists()) {
          const date = snapshot.val()
          this.movein_date = date
        }
      })
    },
    validatePassword() {
      if (this.hash(this.password) === '65e29bcd') {
        this.locked = false
      } else {
        this.password = ''
      }
    },
    /**
     * Creates a hashed string from plaintext password input
     */
    hash(b) {
      for (var a = 0, c = b.length; c--;) {
        a += b.charCodeAt(c)
        a += a << 10
        a ^= a >> 6
        a += a << 3
        a ^= a >> 11
      }
      return ((a + (a << 15) & 4294967295) >>> 0).toString(16)
    },
    nextDay() {
      let startTime = '16:00:00'

      let currentDate = new Date()

      let startDate = new Date(currentDate.getTime())
      startDate.setHours(startTime.split(":")[0])
      startDate.setMinutes(startTime.split(":")[1])
      startDate.setSeconds(startTime.split(":")[2])
      startDate.setDate(10)

      return startDate < currentDate
    },
    goToLocation(location) {
      this.$router.push({ name: location, params: { show: false } })
    },
    goToChallenges() {
      this.$router.push({ name: 'challenges' , params: { locked: false } })
    },
    messageSubmittedToday() {
      get(child(dbRef, 'han_messages/' + this.getDateString())).then((snapshot) => {
        if (snapshot.exists()) {
          const data = snapshot.val()
          this.message_submitted = true
          this.han_message = data
        } else {
          this.message_submitted = false
          this.han_message = ""
        }
      })
    },
    challengeSubmittedToday() {
      get(child(dbRef, 'han_challenges/' + this.getDateString())).then((snapshot) => {
        if (snapshot.exists()) {
          const data = snapshot.val()
          this.challenge_submitted = true
          this.han_challenge = data
        } else {
          this.challenge_submitted = false
          this.han_challenge = ""
        }
      })
    },
    submitMessage() {
      set(ref(db, 'han_messages/' + this.getDateString()), this.han_message)
      get(child(dbRef, 'han_messages/' + this.getDateString())).then((snapshot) => {
        if (snapshot.exists()) {
          this.snackbar = true
          this.snackbar_color = 'green'
          this.snackbar_text = 'Message submitted successfully!'
          this.message_submitted = true
        } else {
          this.snackbar = true
          this.snackbar_color = 'red'
          this.snackbar_text = 'Something went wrong. Try submitting message again.'
          this.message_submitted = false
        }
      })
    },
    editMessage() {
      this.message_submitted = false
    },
    submitChallenge() {
      set(ref(db, 'han_challenges/' + this.getDateString()), this.han_challenge)
      get(child(dbRef, 'han_challenges/' + this.getDateString())).then((snapshot) => {
        if (snapshot.exists()) {
          this.snackbar = true
          this.snackbar_color = 'green'
          this.snackbar_text = 'Challenge submitted successfully!'
          this.challenge_submitted = true
        } else {
          this.snackbar = true
          this.snackbar_color = 'red'
          this.snackbar_text = 'Something went wrong. Try submitting challenge again.'
          this.challenge_submitted = false
        }
      })
    },
    editChallenge() {
      this.challenge_submitted = false
    },
    getMitchMessages() {
      const mes = ref(db, 'mitch_messages/' + this.getDateString())
      onValue(mes, (snapshot) => {
        const data = snapshot.val()
        if (data != null) {
          this.mitch_message = data
        } else {
          this.mitch_message = ""
        }
      })
      const chal = ref(db, 'mitch_challenges/' + this.getDateString())
      onValue(chal, (snapshot) => {
        const data2 = snapshot.val()
        if (data2 != null) {
          this.mitch_challenge = data2
        } else {
          this.mitch_challenge = ""
        }
      })
    }
  }
}
</script>

<style scoped>
.ibarra {
  font-family: 'Ibarra Real Nova', serif;
  color: dimgray;
}
.home-img {
  width: 90%;
  height: auto;
  object-fit: cover;
  object-position: 43% 57%
}
.location-cards {
  width: 80%;
  margin-bottom: 40px;
}
.txt-large {
  font-size: 40px;
}
.txt-x-large {
  font-size: 100px;
}
.txt-med {
  font-size: 30px;
}
.txt-small {
  font-size: 20px;
}
.txt-sm-med {
  font-size: 25px;
}
.txt-x-small {
  font-size: 18px;
}
.bold {
  font-weight: bold;
}
.justify {
  text-align: justify;
  text-justify: inter-word;
}
.circle {
  width: 500px;
  height: 500px;
  line-height: 500px;
  border-radius: 50%;
  font-size: 50px;
  color: #fff;
  text-align: center;
  background: #000
}
.banner {
  background-color: #9CAF88;
  height: 100px;
}
.banner-mobile {
  background-color: #9CAF88;
  height: 300px;
}
img:hover {
  cursor: pointer;
}
.txt-white {
  color: white;
}
</style>